import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { Shape } from 'bizcharts';
import useFiltersContext from '../context/DashboardContext';
import useAuthContext from '../../../contexts/AuthContext';
import useLanguageContext from '../../../contexts/LanguageContext';
import indicatorsElementsReducer from '../indicatorsElementsReducer';
import GaugeBody from './GaugePerformanceBody';

const { Title } = Typography;

const Gauge = ({ title, purpose }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const [isLoading, setIsLoading] = useState(false);
  const {
    timeScale,
    filters: { provider, device, subsidiaries, genre, owner }
  } = useFiltersContext();
  const [indicatorElements, dispatch] = useReducer(
    indicatorsElementsReducer,
    {}
  );
  const { resource, endPoint } = purpose || {};

  Shape.registerShape('point', 'pointer', {
    drawShape(cfg, group) {
      let point = cfg.points[0];
      // eslint-disable-next-line react/no-this-in-sfc
      point = this.parsePoint(point);
      // eslint-disable-next-line react/no-this-in-sfc
      const center = this.parsePoint({
        x: 0,
        y: 0
      });
      group.addShape('line', {
        attrs: {
          x1: center.x,
          y1: center.y,
          x2: point.x,
          y2: point.y,
          stroke: cfg.color,
          lineWidth: 3,
          lineCap: 'round'
        }
      });
      return group.addShape('circle', {
        attrs: {
          x: center.x,
          y: center.y,
          r: 5,
          stroke: cfg.color,
          lineWidth: 3,
          fill: '#fff'
        }
      });
    }
  });

  let CancelToken;
  let cancel;

  const formatData = useCallback(async () => {
    let results;

    CancelToken = axios.CancelToken;

    let providerUrl;
    if (provider) providerUrl = `&provider=${provider}`;

    let deviceUrl;
    if (device.length) deviceUrl = `&devices=${device}`;

    let genreUrl;
    if (genre.length) genreUrl = `&genre=${genre}`;

    let ownerUrl;
    if (owner) ownerUrl = `&owner=${owner}`;

    let subsidiariesUrl;
    if (subsidiaries.length) subsidiariesUrl = `&subsidiaries=${subsidiaries}`;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        results = await dispatchAPI('GET', {
          url: `/dashboards/${resource}/${endPoint}?start_date=${
            timeScale[0]
          }&end_date=${timeScale[1]}&${providerUrl || ''}${ownerUrl ||
            ''}${deviceUrl || ''}${genreUrl || ''}${subsidiariesUrl || ''}`,
          cancelToken: new CancelToken((c) => {
            cancel = c;
          })
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        if (axios.isCancel(e)) {
          return {};
        }
      }
      setIsLoading(false);
      return (results || {}).data;
    };
    dispatch({
      type: endPoint,
      payload: { data: (await fetchData()) || {}, t }
    });
  }, [
    endPoint,
    timeScale,
    device,
    genre,
    owner,
    subsidiaries,
    provider,
    locale
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (resource && endPoint) formatData();
    if (cancel)
      return () => {
        cancel();
      };
  }, [formatData]);

  return (
    <>
      <Title level={4}>{title}</Title>
      <GaugeBody indicatorElements={indicatorElements} isLoading={isLoading} />
    </>
  );
};

Gauge.propTypes = {
  title: PropTypes.string.isRequired,
  purpose: PropTypes.shape({
    endPoint: PropTypes.string,
    resource: PropTypes.string
  })
};

Gauge.defaultProps = {
  purpose: null
};

export default Gauge;
