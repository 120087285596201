import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';

const { Column } = Table;

const ContractsTab = ({ companyId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const [contracts, setContracts] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const handlePageChange = (page) => {
    setPagination(page);
  };

  const getContracts = useCallback(async () => {
    setLoading(true);
    const filterObject = {
      $or: [
        {
          suppliers: {
            $in: [companyId]
          }
        },
        {
          leaser: companyId
        }
      ]
    };

    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts?filter=${JSON.stringify(
          filterObject
        )}&!amendment_number`
      });
      setContracts(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    getContracts();
  }, [getContracts]);

  return (
    <Skeleton
      loading={isLoading}
      active
      title={0}
      paragraph={{
        rows: 8,
        width: ['100%', '100%', '100%', '100%', '100%', '100%', '100%', '100%']
      }}
    >
      <Table
        dataSource={contracts.map(({ _id, ...contract }) => ({
          ...contract,
          key: _id
        }))}
        onChange={handlePageChange}
        rowClassName="rowStyle"
        onRow={({ key }) => ({
          onClick: () => history.push(`/contracts/show/${key}`)
        })}
        pagination={pagination}
      >
        <Column
          title={t('contracts.list.column.number')}
          key="number"
          render={({ number, amendment_number }) => (
            <Typography>
              {number}
              {amendment_number && `-${amendment_number}`}
            </Typography>
          )}
        />
        <Column
          title={t('contracts.list.column.duration')}
          key="duration"
          render={(contract) => <Typography>{contract.duration}</Typography>}
        />
      </Table>
    </Skeleton>
  );
};

ContractsTab.propTypes = {
  companyId: PropTypes.string.isRequired
};

export default ContractsTab;
