import React, { useEffect, useState } from 'react';
import { Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import { PageHeaderCustom, ContentCustom } from '../../components';
import ReportsTable from './ReportsTable';
import TicketInformation from './TicketInformation';
import TicketAssets from './TicketAssets';

const { Title } = Typography;

const ShowTicket = () => {
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const { t } = useTranslation();
  const [ticket, setTicket] = useState({});
  const [badgeCount, setBadgeCount] = useState(0);
  const [assetsEnumValues, setAssetsEnums] = useState([]);
  const [assets, setAssets] = useState(null);

  const getTicket = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/tickets/${id}?populate=assets,files,audit_report,erasure_report,destruction_report`
    });
    setTicket({
      ...data,
      erasure_report: data.erasure_report.map((report) => ({
        ...report,
        type: 'ERASURE'
      })),
      destruction_report: data.destruction_report.map((report) => ({
        ...report,
        type: 'DESTRUCTION'
      }))
    });
    let fileCount = 0;
    if (data.files && data.files.length) {
      fileCount += data.files.length;
    }
    if (data.audit_report) fileCount += 1;
    setBadgeCount(fileCount);
    const tmp = [];
    // eslint-disable-next-line no-underscore-dangle
    data.assets.forEach((asset) => tmp.push({ ...asset, key: asset._id }));
    setAssets(tmp);
    setIsTableLoading(false);
  };

  const getAssetsEnums = async () => {
    const result = await dispatchAPI('GET', { url: '/assets/enums' });
    setAssetsEnums(result.data);
  };

  useEffect(() => {
    setIsTableLoading(true);
    (async () => {
      await getTicket();
      await getAssetsEnums();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { erasure_report, destruction_report } = ticket;

  return (
    <>
      <PageHeaderCustom title={t('tickets.showTicket.title')} />
      {isTableLoading ? (
        <Spin
          style={{
            textAlign: 'center',
            borderRadius: '4px',
            marginBottom: '20px',
            padding: '30px 50px',
            margin: '20px 0',
            width: '100%',
            height: '100%'
          }}
        />
      ) : (
        <div>
          <TicketInformation
            id={id}
            ticket={ticket}
            badgeCount={badgeCount}
            getTicket={getTicket}
          />
          <TicketAssets
            isTableLoading={isTableLoading}
            assets={assets}
            assetsEnumValues={assetsEnumValues}
          />
          {(erasure_report || []).length ||
          (destruction_report || []).length ? (
            <ContentCustom marginTop={8}>
              <Title level={4}>{t('tickets.showTicket.reportsTitle')}</Title>
              <ReportsTable
                reports={[
                  ...(erasure_report || []),
                  ...(destruction_report || [])
                ]}
              />
            </ContentCustom>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};

export default ShowTicket;
