/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import useLanguageContext from '../../contexts/LanguageContext';
import useAuthContext from '../../contexts/AuthContext';
import { useStateWithSessionStorage } from '../../utils';
import { PageHeaderCustom, ContentCustom } from '../../components';
import { DashboardContextProvider } from './context/DashboardContext';
import TimeScaling from '../../components/timeScaling/TimeScaling';
import ITDashboard from './ITDashboard';
import TelecomDashboard from './TelecomDashboard';
import useHandleResize from '../../utils/handleResize';
import RSEDashboard from './RSEDashboard';

const { TabPane } = Tabs;

const Home = () => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useStateWithSessionStorage(
    'activeKey',
    'IT'
  );
  const { dispatchAPI, user } = useAuthContext();
  const { locale } = useLanguageContext();
  const [tabList, setTabList] = useState([]);
  const { width } = useHandleResize();
  const [clientConfig, setClientConfig] = useState('');
  const [modules, setModules] = useState([]);

  useEffect(() => {
    const getCompany = async () => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/companies/${user.company}`
        });
        setClientConfig(data.client_config);
      } catch (e) {
        console.error(e);
      }
    };
    getCompany();
  }, []);
  useEffect(() => {
    const getModules = async () => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/client-configs/${clientConfig}/modules`
        });
        setModules(data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };
    clientConfig && getModules();
  }, [clientConfig]);
  useEffect(() => {
    setTabList([
      {
        key: 'RSE',
        tab: t('home.title.RSE'),
        disabled: !(modules && modules.includes('RSE'))
      },
      {
        key: 'IT',
        tab: t('home.title.it'),
        disabled: !(modules && modules.includes('IT'))
      },
      {
        key: 'Telecom',
        tab: t('home.title.telecom'),
        disabled: !(modules && modules.includes('TELECOM'))
      },
      {
        key: 'Software',
        tab: t('home.title.software'),
        disabled: !(modules && modules.includes('SOFTWARE'))
      },
      {
        key: 'Cloud',
        tab: t('home.title.cloud'),
        disabled: !(modules && modules.includes('CLOUD'))
      },
      {
        key: 'Printing',
        tab: t('home.title.printing'),
        disabled: !(modules && modules.includes('PRINTING'))
      },
      {
        key: 'Cars',
        tab: t('home.title.cars'),
        disabled: !(modules && modules.includes('CARS'))
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, modules]);

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  const contentList = {
    IT: <ITDashboard />,
    Telecom: <TelecomDashboard />,
    RSE: <RSEDashboard />
  };

  return (
    <DashboardContextProvider>
      <PageHeaderCustom
        title={t('home.title.dashboard')}
        extra={
          <Tabs
            size="large"
            tabBarStyle={{ marginTop: width > 1350 ? -12 : 8 }}
            activeKey={activeKey}
            animated={false}
            onChange={(key) => {
              (() => {
                onTabChange(key);
              })();
            }}
            className="home-tabs"
          >
            {tabList.map(({ key, tab, disabled }) => (
              <TabPane tab={tab} key={key} disabled={disabled} />
            ))}
          </Tabs>
        }
      >
        <TimeScaling activeKey={activeKey} />
      </PageHeaderCustom>
      <ContentCustom marginTop={172}>{contentList[activeKey]}</ContentCustom>
    </DashboardContextProvider>
  );
};

export default Home;
