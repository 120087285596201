import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Drawer, Icon, Timeline, Upload, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useHandleResize from '../../utils/handleResize';

const Index = ({
  badgeCount,
  resourceName,
  resourceId,
  resourceFiles,
  topPosition,
  getResource,
  withUpload
}) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const { dispatchAPI, token } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { width } = useHandleResize();

  const downloadResource = async (id, name) => {
    const result = await dispatchAPI('GET', {
      responseType: 'blob',
      url: `files/${id}`
    });
    const url = URL.createObjectURL(result.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  const afterUpload = async (status) => {
    if (status === 'done') {
      message.success(t('invoices.upload.success'));
      await getResource();
    }
    if (status === 'error') message.error(t('invoices.upload.error'));
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        style={{
          position: 'absolute',
          padding: 5,
          right: -2,
          top: topPosition || '40%',
          borderRight: 'none',
          height: '42px',
          borderRadius: '50%',
          width: '42px'
        }}
      >
        <Badge
          offset={[-25, -15]}
          style={{ zIndex: 1 }}
          count={badgeCount}
          showZero
        >
          <Icon type="file" style={{ fontSize: 16 }} />
        </Badge>
      </Button>
      <Drawer
        onClose={() => setVisible(false)}
        placement="right"
        zIndex={2}
        width={width < 576 ? 250 : 400}
        mask={false}
        getContainer={false}
        visible={visible}
        style={{ position: 'absolute' }}
        title={
          withUpload ? (
            <>
              {`${t('contracts.showContract.attachedFiles.title')} `}
              <Upload
                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                name={resourceName}
                action={`${baseUrl}/${resourceName}/${resourceId}/upload`}
                showUploadList={false}
                onChange={(e) => afterUpload(e.file.status)}
                headers={{
                  Authorization: `Bearer ${token}`
                }}
              >
                <Button>
                  <div style={{ width: 300 }}>
                    <Icon type="upload" style={{ marginRight: 5 }} />
                    {t('contracts.showContract.uploadDocument')}
                  </div>
                </Button>
              </Upload>
            </>
          ) : (
            t('contracts.showContract.attachedFiles.title')
          )
        }
      >
        <Timeline>
          {resourceFiles &&
            resourceFiles.map(({ metadata, _id }) => (
              <Timeline.Item key={_id} color="green">
                <Button
                  onClick={() => downloadResource(_id, metadata.originalName)}
                  style={{
                    whiteSpace: 'initial',
                    textAlign: 'left'
                  }}
                  type="link"
                >
                  {metadata.originalName}
                </Button>
              </Timeline.Item>
            ))}
        </Timeline>
      </Drawer>
    </>
  );
};

Index.propTypes = {
  badgeCount: PropTypes.number.isRequired,
  resourceId: PropTypes.string.isRequired,
  resourceFiles: PropTypes.arrayOf(PropTypes.shape({})),
  resourceName: PropTypes.string.isRequired,
  getResource: PropTypes.func.isRequired,
  topPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withUpload: PropTypes.bool
};

Index.defaultProps = {
  resourceFiles: [],
  topPosition: null,
  withUpload: false
};

export default Index;
