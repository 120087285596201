import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import { Typography, Spin, message, Modal } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useDashboardContext from '../context/DashboardContext';
import {
  headersVolumeAssetsReturned,
  headersAffectation,
  headersAssetStock,
  headersInactiveLines,
  headersTotalCredits
} from '../exportHeaders';

const CsvButton = ({ assetData, setAssetData, purpose, unit }) => {
  const [headers, setHeaders] = useState([]);
  const { resource, endPoint } = purpose || {};
  const endPointData = `${endPoint}_data`;
  const { dispatchAPI } = useAuthContext();
  const {
    timeScale,
    filters: { provider, device, subsidiaries, genre, owner }
  } = useDashboardContext();
  const { t } = useTranslation();

  
  let providerUrl;
  if (provider) providerUrl = `&provider=${provider}`;

  let deviceUrl;
  if (device.length) deviceUrl = `&devices=${device}`;

  let genreUrl;
  if (genre.length) genreUrl = `&genre=${genre}`;

  let ownerUrl;
  if (owner) ownerUrl = `&owner=${owner}`;

  let subsidiariesUrl;
  if (subsidiaries.length)
    subsidiariesUrl = `&subsidiaries=${subsidiaries}`;

  const fetchCSVData = async () => {
    try {
      let results = [];
      const { data } = await dispatchAPI('GET', {
        url: `/dashboards/${resource}/${endPointData}?start_date=${
          timeScale[0]
        }&end_date=${timeScale[1]}&unit=${unit}${providerUrl ||
          ''}${ownerUrl || ''}${deviceUrl || ''}${genreUrl ||
          ''}${subsidiariesUrl || ''}`
      });
      switch (endPointData) {
        case 'volume_asset_to_be_returned_data':
          (data || []).forEach(({ entry_date, delivery_date, ...asset }) => {
            const tmp = {
              ...asset,
              owner: asset.owner[0].name,
              entry_date: entry_date ? moment(entry_date).format('L') : '',
              delivery_date: delivery_date
                ? moment(delivery_date).format('L')
                : ''
            };
            results.push(tmp);
          });
          setHeaders(headersVolumeAssetsReturned);
          break;

        case 'affectation_data':
          results = data;
          setHeaders(headersAffectation);
          break;
        case 'assets_stock_data':
          (data || []).forEach(({ entry_date, delivery_date, ...asset }) => {
            const tmp = {
              ...asset,
              entry_date: entry_date ? moment(entry_date).format('L') : '',
              delivery_date: delivery_date
                ? moment(delivery_date).format('L')
                : ''
            };
            results.push(tmp);
          });
          setHeaders(headersAssetStock);
          break;
        case 'inactive_lines_data':
          results = data;
          setHeaders(headersInactiveLines);
          break;
        case 'total_credits_data':
          results = data;
          setHeaders(headersTotalCredits);
          break;

        default:
          results = 'Pas de données';
          break;
      }
      if (results.length) setAssetData(results);
      else setAssetData('Pas de données');
    } catch (e) {
      setAssetData('Pas de données');
      message.error(e);
    }
  };

  const [visible, setVisible] = useState(false);
  return (
    <>
      <Modal
        closable={false}
        footer={false}
        visible={visible}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning size="large" style={{ margin: 16 }} />
        <br />
        <Typography.Text>{t(`prepareFile`)}</Typography.Text>
      </Modal>
      <CSVLink
        asyncOnClick
        onClick={(e, done) => {
          if (!assetData.length) {
            setVisible(true);
            e.persist();
            e.preventDefault();
            fetchCSVData().then(() => {
              setVisible(false);
              e.target.click();
              done(false);
            });
          } else done();
        }}
        style={{ float: 'right', marginRight: 16 }}
        filename={`${endPoint}.csv`}
        data={assetData}
        headers={headers.map(({ label: l, ...header }) => ({
          label: t(`${endPoint}.${l}`),
          ...header
        }))}
      >
        <p style={{ transform: 'rotate(90deg) ' }}>&#8677;</p>
      </CSVLink>
    </>
  );
};
CsvButton.propTypes = {
  unit: PropTypes.string.isRequired,
  purpose: PropTypes.shape({}).isRequired,
  setAssetData: PropTypes.func.isRequired,
  assetData: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default CsvButton;
