/* eslint-disable no-empty */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Layout, Menu, Icon, message, Button } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import useAuthContext from '../contexts/AuthContext';
import './BasicLayout.css';
import logoColibris from '../assets/images/ColibrisBlanc.png';
import { useStateWithLocalStorage } from '../utils';
import { languages, assetsIcons } from './utils';
import Preload from '../components/preload/Preload';
import useLanguageContext from '../contexts/LanguageContext';
import UpdateAlert from '../components/UpdateAlert';
import BasicLayoutMenu from './BasicLayoutMenu';
import BasicLayoutHeader from './BasicLayoutHeader';

const { Content, Footer, Sider } = Layout;

const BasicLayout = ({ children, path }) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );
  const [assetsSubMenu, setAssetsSubMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useStateWithLocalStorage('theme', {
    mainColor: '#001529',
    themeLogo: null
  });
  const { dispatch: dispatchLocale } = useLanguageContext();

  const mapAssetsSubMenu = (models) => {
    if (!models) return null;
    const disabledAsset = { Car: true, RealEstate: true, Software: true };

    return Object.entries(models).map(([modelName]) => (
      // eslint-disable-next-line no-underscore-dangle
      <Menu.Item key={`/${modelName}`} disabled={disabledAsset[modelName]}>
        <NavLink to={`/assets/${modelName}`} className="nav-text">
          {assetsIcons[modelName] && <Icon type={assetsIcons[modelName]} />}
          <span>{t(`${modelName}.title`)}</span>
        </NavLink>
      </Menu.Item>
    ));
  };

  const getAssetsName = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/assets/models'
      });
      setAssetsSubMenu(data);
    } catch (e) {}
  };

  useEffect(() => {
    (async () => {
      await getAssetsName();
    })();
  }, []);

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (size.width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [size]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('orientationchange', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const getLogo = async (id) => {
    try {
      const result = await dispatchAPI('GET', {
        responseType: 'blob',
        url: `/files/${id}`
      });
      if (theme.themeLogo !== URL.createObjectURL(result.data))
        setTheme({
          ...theme,
          themeLogo: URL.createObjectURL(result.data)
        });
    } catch (e) {
      console.error(e);
    }
  };

  const getTheme = async () => {
    try {
      const {
        data: { client_config }
      } = await dispatchAPI('GET', {
        url: `/companies/${user.company}?populate=client_config`
      });
      const { sidebar_color, logo } = client_config;
      if (logo) {
        await getLogo(logo, sidebar_color);
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getTheme();
    })();
  }, []);

  const onLanguageMenuClick = ({ key }) => {
    dispatchLocale({ type: 'SET_LOCALE', locale: key });
  };

  const langMenu = (
    <Menu onClick={onLanguageMenuClick}>
      {Object.keys(languages).map((locale) => (
        <Menu.Item key={locale}>
          <span>{languages[locale].icon}</span>
          {` ${languages[locale].label}`}
        </Menu.Item>
      ))}
    </Menu>
  );

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logout'));
    }
  };

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      {window.innerWidth <= 992 && user && (
        <Menu.Item key="user">
          <Button type="link">
            <span className="full-name">
              {`${user.first_name} ${user.last_name}`}
            </span>
            <p className="role">{t(`users.tags.${user.role}`)}</p>
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="logout">
        <Icon type="logout" />
        {` ${t('header.menu.user.logout')}`}
      </Menu.Item>
    </Menu>
  );

  return isLoading && history.action !== 'PUSH' ? (
    <Preload />
  ) : (
    <Layout style={{ minHeight: '100vh' }}>
      <UpdateAlert />
      <Sider
        className="container-sider"
        width={256}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsedWidth={collapseWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <BasicLayoutHeader
          user={user}
          theme={theme}
          profileMenu={profileMenu}
          langMenu={langMenu}
        />
        <div className="menu-container">
          <BasicLayoutMenu
            user={user}
            path={path}
            setCollapsed={setCollapsed}
            size={size}
            assetsSubMenu={assetsSubMenu}
            mapAssetsSubMenu={mapAssetsSubMenu}
          />
          <img
            className="logoLeasetic"
            src={logoColibris}
            alt="logo Colibris"
          />
        </div>
      </Sider>
      <Layout>
        <Content className="content">{children}</Content>
        <Footer className="footer" style={{ textAlign: 'center' }}>
          {`${t('global.title')} ©${moment().year()} powered by `}
          <a href="https://www.colibris.app/">Colibris</a>
          <p>{`Version: ${process.env.REACT_APP_APP_VERSION || '2.0.0'}`}</p>
        </Footer>
      </Layout>
    </Layout>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired
};

export default BasicLayout;
