import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, InputNumber, Button } from 'antd';
import PropTypes from 'prop-types';

const AmountManagerForm = ({
  order,
  getFieldDecorator,
  tailFormItemLayout,
  addArticleField,
  orderIsLoading
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item {...tailFormItemLayout}>
        <Button type="dashed" onClick={addArticleField} icon="plus">
          {t('orders.form.addArticle.addOrderButton')}
        </Button>
      </Form.Item>
      <Form.Item label={t('orders.form.total_ht_amount')}>
        {getFieldDecorator('total_ht_amount', {
          initialValue: order && order.total_ht_amount,
          rules: [
            {
              type: 'number' || 'float',
              message: t(`global.form.typeMessage`)
            },
            {
              required: true,
              message: t(`global.form.requiredMessage`)
            }
          ]
        })(<InputNumber style={{ width: 150 }} />)}
        {` €`}
      </Form.Item>
      <Form.Item label={t('orders.form.tva_amount')}>
        {getFieldDecorator('tva_amount', {
          initialValue: order && order.tva_amount,
          rules: [
            {
              type: 'number' || 'float',
              message: t(`global.form.typeMessage`)
            },
            {
              required: true,
              message: t(`global.form.requiredMessage`)
            }
          ]
        })(<InputNumber style={{ width: 150 }} />)}
        {` €`}
      </Form.Item>
      <Form.Item label={t('orders.form.total_discount')}>
        {getFieldDecorator('total_discount', {
          initialValue: order && order.total_discount,
          rules: [
            {
              type: 'number' || 'float',
              message: t(`global.form.typeMessage`)
            }
          ]
        })(<InputNumber style={{ width: 150 }} />)}
        {` €`}
      </Form.Item>
      <Form.Item label={t('orders.form.ttc_amount')}>
        {getFieldDecorator('ttc_amount', {
          initialValue: order && order.ttc_amount,
          rules: [
            {
              type: 'number' || 'float',
              message: t(`global.form.typeMessage`)
            },
            {
              required: true,
              message: t(`global.form.requiredMessage`)
            }
          ]
        })(<InputNumber style={{ width: 150 }} />)}
        {` €`}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={orderIsLoading}>
          {t('orders.form.submitButton')}
        </Button>
        <Link to="/orders">
          <Button type="danger" style={{ margin: '0 10px' }}>
            {t('orders.form.cancelButton')}
          </Button>
        </Link>
      </Form.Item>
    </>
  );
};
AmountManagerForm.propTypes = {
  order: PropTypes.shape({
    total_ht_amount: PropTypes.number,
    total_discount: PropTypes.number,
    ttc_amount: PropTypes.number,
    tva_amount: PropTypes.number
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  tailFormItemLayout: PropTypes.shape({}).isRequired,
  addArticleField: PropTypes.func.isRequired,
  orderIsLoading: PropTypes.bool.isRequired
};
const WrappedAmountManagerForm = Form.create({
  name: 'amountManagerForm'
})(AmountManagerForm);

export default WrappedAmountManagerForm;
