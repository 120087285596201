import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Typography } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import DescriptionList from '../../components/descriptionList/DescriptionList';
import datas from './dataForInvoiceModal';

const InvoiceModal = ({ id, visible, setVisible }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);

  const getInvoice = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/telecom-invoices/${id}?populate=phone_contract`
      });
      setInvoice(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) getInvoice();
  }, [getInvoice]);

  const { date, amount, number, credits_amount } = invoice || {};

  return (
    <Modal
      title={t('assets.showAsset.invoiceModalTitle', {
        number,
        date: date ? moment(date).format('l') : '-'
      })}
      width={800}
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
    >
      <Spin spinning={isLoading}>
        <DescriptionList bordered data={datas(invoice, t)} />
        <Typography.Title
          level={4}
          style={{ textAlign: 'center', marginTop: 8, fontWeight: 400 }}
        >
          {t('assets.showAsset.phoneContract.subTitles.discount')}
          <span style={{ float: 'right' }}>
            {`${numeral(credits_amount).format('0[.]00')}€`}
          </span>
        </Typography.Title>
        <Typography.Title
          level={4}
          style={{ textAlign: 'center', marginTop: 8 }}
        >
          {t('assets.showAsset.phoneContract.subTitles.total')}
          <span style={{ float: 'right' }}>
            {`${numeral(amount).format('0[.]00')}€`}
          </span>
        </Typography.Title>
      </Spin>
    </Modal>
  );
};

InvoiceModal.propTypes = {
  id: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

InvoiceModal.defaultProps = {
  id: null
};

export default InvoiceModal;
