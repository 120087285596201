export const filtersInitialState = {
  provider: null,
  device: [
    'SMARTPHONE',
    'SMARTPHONE_DATA',
    'SMARTPHONE_VOICE',
    'DATA',
    'M2M',
    'INTERNET',
    'LANDLINE'
  ],
  subsidiaries: [],
  genre: [],
  accessTypes: []
};

let filters;

export const filtersReducer = (state, { type, payload }) => {
  switch (type) {
    case 'add':
      filters = {
        ...state,
        ...payload,
        ...(payload.provider && state.provider === payload.provider
          ? { provider: '' }
          : {}),
        ...(payload.owner && state.owner === payload.owner ? { owner: '' } : {})
      };
      sessionStorage.setItem('filters', JSON.stringify(filters));
      return filters;
    case 'reset':
      filters = {
        ...filtersInitialState,
        provider: state.provider,
        owner: state.owner
      };
      sessionStorage.setItem('filters', JSON.stringify(filters));
      return filters;
    default:
      return filtersInitialState;
  }
};
