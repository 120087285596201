/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { message, Card, Icon, Row, Typography, List, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../components';
import DescriptionList from '../../components/descriptionList/DescriptionList';
import useInformation from './informationList';

const { Title, Text } = Typography;

const ShowPeople = () => {
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const [person, setPerson] = useState({});
  const information = useInformation(person);
  const [models, setModels] = useState([]);
  const [assets, setAssets] = useState({
    assetsLinked: null
  });
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [areModelsLoading, setAreModelsLoading] = useState(false);
  const [extra, setExtra] = useState({
    visible: false
  });

  const getAssetsLinked = async () => {
    try {
      const results = await dispatchAPI('GET', {
        url: `/assets?people=${id}&populate=telecomInvoices`
      });
      setAssets({ ...assets, assetsLinked: results.data });
    } catch (e) {
      console.error(e);
    }
  };

  const getPeople = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/people/${id}?populate=company,subsidiary`
      });
      setPerson(
        data.subsidiary ? { ...data, subsidiary: data.subsidiary.name } : data
      );
      await getAssetsLinked();
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
    }
    setIsMainLoading(false);
  };

  const fetchModels = async () => {
    try {
      const result = await dispatchAPI('GET', { url: '/assets/models' });
      // eslint-disable-next-line
      setModels(Object.keys(result.data));
    } catch (e) {
      message.error(e.message);
    }
    setAreModelsLoading(false);
  };

  const extraButtons = (asset) => (
    <>
      <Link to={`/assets/${asset.type}/show/${asset._id}`}>
        <Icon style={{ fontSize: 18 }} type="eye" />
      </Link>
    </>
  );

  useEffect(() => {
    setIsMainLoading(true);
    setAreModelsLoading(true);
    if (id) {
      (async () => {
        await getPeople();
        await fetchModels();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeaderCustom title={t('people.showPeople.title')} />
      <ContentCustom>
        <Skeleton
          active
          loading={isMainLoading}
          paragraph={{ width: ['50%', '50%', '50%'] }}
        >
          <DescriptionList
            title={`${person.first_name} ${
              person.last_name ? person.last_name : ''
            }`}
            data={information}
          />
        </Skeleton>
      </ContentCustom>
      <ContentCustom marginTop={8}>
        <Title level={4}>{t('people.showPeople.assetsList.title')}</Title>
        <Skeleton
          active
          loading={areModelsLoading}
          paragraph={{
            rows: 6,
            width: ['100%', '50%', '100%', '50%', '100%', '50%']
          }}
        >
          {models.map((name) => {
            const { assetsLinked } = assets || {};
            return (
              <Row key={name}>
                <List
                  dataSource={(assetsLinked || []).filter(
                    (asset) => asset.type === name
                  )}
                  header={
                    <Text strong style={{ fontSize: 20 }}>
                      {t(`people.showPeople.assetList.${name}`)}
                    </Text>
                  }
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4
                  }}
                  renderItem={({
                    _id,
                    type,
                    description,
                    phone_Number,
                    ...asset
                  }) => (
                    <List.Item>
                      <Card
                        className="infolist-card"
                        style={{ marginTop: 10, height: 150 }}
                        onMouseEnter={() => {
                          setExtra({ id: _id, visible: true });
                        }}
                        extra={
                          extra.visible &&
                          _id === extra.id &&
                          extraButtons({ _id, type, asset })
                        }
                        onMouseLeave={() => {
                          setExtra({ id: null, visible: false });
                        }}
                        title={
                          type === 'Telecom' ? `0${phone_Number}` : description
                        }
                      >
                        {type === 'Telecom' ? (
                          (
                            asset.telecomInvoices[
                              asset.telecomInvoices.length - 1
                            ] || {}
                          ).phone_contract_name
                        ) : (
                          <p>
                            {asset.serial_number}
                            <br />
                            {asset.EasyVista_request_serial_number}
                          </p>
                        )}
                      </Card>
                    </List.Item>
                  )}
                />
              </Row>
            );
          })}
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowPeople;
