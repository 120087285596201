import { useTranslation } from 'react-i18next';

const useInformation = ({
  id_number,
  email,
  contract_type,
  subsidiary,
  job,
  job_category,
  job_type,
  site
}) => {
  const { t } = useTranslation();
  return [
    {
      label: t('people.showPeople.id_number'),
      span: 1,
      content: id_number || '-'
    },
    {
      label: t('people.showPeople.email'),
      span: 2,
      content: email || '-'
    },
    {
      label: t('people.showPeople.contract_type'),
      span: 3,
      content: contract_type || '-'
    },
    {
      label: t('people.showPeople.subsidiary'),
      span: 3,
      content: subsidiary || '-'
    },
    {
      label: t('people.showPeople.job'),
      span: 3,
      content: job || '-'
    },
    {
      label: t('people.showPeople.job_category'),
      span: 3,
      content: job_category || '-'
    },
    {
      label: t('people.showPeople.job_type'),
      span: 3,
      content: job_type || '-'
    },
    {
      label: t('people.showPeople.city'),
      span: 3,
      content: site || '-'
    }
  ];
}; 

export default useInformation;
