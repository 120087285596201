import React, { useState } from 'react';
import { Button, Form, Icon, Input, Table, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { assetsStatusColor } from '../../utils';
import { ContentCustom } from '../../components';

const { Title } = Typography;
const { Column } = Table;

const AssetsPart = ({
  tailFormItemLayout,
  handleChange,
  handleSearch,
  allAssets,
  isTableLoading,
  pagination,
  assets,
  selectAllAssets,
  assetsEnumValues,
  filteredInfo,
  isLoading,
  selectAssets
}) => {
  const [searchInput, setSearchInput] = useState(null);
  const history = useHistory();
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const { t } = useTranslation();
  return (
    <ContentCustom>
      <Title level={3}>{t(`tickets.create.assetList`)}</Title>
      <Table
        dataSource={allAssets}
        loading={isTableLoading}
        onChange={handleChange}
        pagination={pagination}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) =>
            selectAssets(selectedRowKeys, selectedRows),
          selectedRowKeys: assets.map((asset) => asset),
          onSelectAll: (selected, selectedRows, changeRows) =>
            selectAllAssets(selected, selectedRows, changeRows)
        }}
      >
        <Column
          title={t(`assets.list.column.city`)}
          dataIndex="city"
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
          }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={(node) => {
                  setSearchInput(node);
                }}
                placeholder={t(`assets.list.column.city`)}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm)}
                icon="search"
                size="small"
                style={{ width: 110, marginRight: 8 }}
              >
                {t('buttons.search')}
              </Button>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </div>
          )}
          filterIcon={(filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#1890ff' : undefined }}
            />
          )}
          onFilterDropdownVisibleChange={(filterVisible) => {
            if (filterVisible) {
              setTimeout(() => searchInput.select());
            }
          }}
        />
        <Column
          title={t(`assets.list.column.serial_number`)}
          dataIndex="serial_number"
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
          }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={(node) => {
                  setSearchInput(node);
                }}
                placeholder={t(`assets.list.column.serial_number`)}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm)}
                icon="search"
                size="small"
                style={{ width: 110, marginRight: 8 }}
              >
                {t('buttons.search')}
              </Button>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </div>
          )}
          filterIcon={(filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#1890ff' : undefined }}
            />
          )}
          onFilterDropdownVisibleChange={(filterVisible) => {
            if (filterVisible) {
              setTimeout(() => searchInput.select());
            }
          }}
        />
        <Column title="Description" dataIndex="description" />
        <Column
          title={t(`assets.list.column.status`)}
          dataIndex="status"
          render={(status) => (
            <Tag color={assetsStatusColor[status]}>
              {t(`assets.tags.${status}`)}
            </Tag>
          )}
          filters={
            assetsEnumValues.status &&
            assetsEnumValues.status.map((enumValue) => ({
              text: t(`assets.tags.${enumValue}`),
              value: enumValue
            }))
          }
          filteredValue={filteredInfo.status || null}
        />
      </Table>
      <Title
        level={4}
        style={{
          marginTop: 10,
          float: 'right',
          textTransform: 'none',
          marginRight: 20
        }}
      >
        {`${t('tickets.totalAssetsSelected')} : ${
          Object.entries(assets).length
        }`}
      </Title>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Form.Item style={{ marginTop: 50 }} {...tailFormItemLayout}>
        <Button loading={isLoading} type="primary" htmlType="submit">
          {t('tickets.form.button')}
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          type="danger"
          onClick={() => {
            history.goBack();
          }}
        >
          {t('tickets.form.cancel')}
        </Button>
      </Form.Item>
    </ContentCustom>
  );
};

AssetsPart.propTypes = {
  tailFormItemLayout: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  allAssets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isTableLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  assets: PropTypes.arrayOf().isRequired,
  selectAllAssets: PropTypes.func.isRequired,
  assetsEnumValues: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  filteredInfo: PropTypes.shape({
    status: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectAssets: PropTypes.func.isRequired
};

export default AssetsPart;
