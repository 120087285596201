import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const ExtraButton = ({ unit, handleSwitch }) => {
  return (
    <div style={{ position: 'absolute', top: 0, right: 5 }}>
      <Button
        style={{ color: unit === 'volume' ? '#1890ff' : 'grey' }}
        value="volume"
        type="link"
        icon="database"
        onClick={(e) => handleSwitch(e.target.value)}
      />
      <Button
        style={{ color: unit === 'amount' ? '#1890ff' : 'grey' }}
        value="amount"
        type="link"
        icon="euro"
        onClick={(e) => handleSwitch(e.target.value)}
      />
    </div>
  );
};

ExtraButton.propTypes = {
  unit: PropTypes.string.isRequired,
  handleSwitch: PropTypes.func.isRequired
};

export default ExtraButton;
