import React from 'react';
import { Divider, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import RACITable from './RACITable';
import MiniDashDisplayContainer from './displayContainers/miniDashDisplayContainer';
import Indicator from './miniDashs/Indicator';
import ChartDash from './dashs/ChartDash';
import DashDisplayContainer from './displayContainers/DashDisplayContainer';
import ThreelevelsPieChart from './dashs/ThreeLevelsPieChart';
import RSEFilters from './RSEFilters';

const RSEDashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <RSEFilters />
      <Divider orientation="center">
        <Typography.Title level={2}>
          {t('dashboard.rse.digital_footprint')}
        </Typography.Title>
      </Divider>
      <MiniDashDisplayContainer smSpan={12} xxlSpan={8}>
        <Indicator
          title={t('home.miniDash.rse.carbone_impact_PC.title')}
          purpose={{ resource: 'RSE', endPoint: 'carbone_impact_PC' }}
        />
        <Indicator
          title={t('home.miniDash.rse.carbone_impact_others.title')}
          purpose={{ resource: 'RSE', endPoint: 'carbone_impact_others' }}
        />
        <Indicator
          title={t('home.miniDash.rse.carbone_impact_total.title')}
          purpose={{ resource: 'RSE', endPoint: 'carbone_impact_total' }}
        />
      </MiniDashDisplayContainer>
      <DashDisplayContainer>
        <ChartDash
          title={t('home.dash.carbone_impact_PC_evolution.title')}
          purpose={{ resource: 'RSE', endPoint: 'carbone_impact_PC_evolution' }}
        />
        <ChartDash
          title={t('home.dash.carbone_impact_others_evolution.title')}
          purpose={{
            resource: 'RSE',
            endPoint: 'carbone_impact_others_evolution'
          }}
        />
        <ChartDash
          title={t('home.dash.carbone_impact_total_evolution.title')}
          purpose={{
            resource: 'RSE',
            endPoint: 'carbone_impact_total_evolution'
          }}
        />
      </DashDisplayContainer>
      <Divider orientation="center" style={{ marginTop: 48 }}>
        <Typography.Title level={2}>
          {t('dashboard.rse.perf_and_opti')}
        </Typography.Title>
      </Divider>
      <DashDisplayContainer>
        <ChartDash
          title={t('home.dash.medals_repartition.title')}
          purpose={{
            resource: 'RSE',
            endPoint: 'medals_repartition'
          }}
          chart="pie"
        />
        <ChartDash
          title={t(
            'home.dash.top_carbone_impact_fabrication_per_brand_making.title'
          )}
          purpose={{
            resource: 'RSE',
            endPoint: 'carbone_impact_fabrication_per_brand_making'
          }}
        />
        <ChartDash
          title={t('home.dash.carbon_footprint_per_type.title')}
          purpose={{
            resource: 'RSE',
            endPoint: 'carbon_footprint_per_type'
          }}
          chart="pie"
        />
        <ThreelevelsPieChart
          title={t('home.dash.medals_per_type.title')}
          purpose={{
            resource: 'RSE',
            endPoint: 'medals_per_type'
          }}
        />
        <ChartDash
          title={t(
            'home.dash.top_carbone_impact_fabrication_per_brand_making_macro.title'
          )}
          purpose={{
            resource: 'RSE',
            endPoint: 'carbone_impact_fabrication_per_brand_making_macro'
          }}
        />
        <ChartDash
          title={t('home.dash.carbon_footprint_per_type_macro.title')}
          purpose={{
            resource: 'RSE',
            endPoint: 'carbon_footprint_per_type_macro'
          }}
          chart="pie"
        />
      </DashDisplayContainer>
      <Divider orientation="center" style={{ marginTop: 48 }}>
        <Typography.Title level={2}>
          {t('dashboard.rse.lifecycle')}
        </Typography.Title>
      </Divider>
      <MiniDashDisplayContainer smSpan={12} xxlSpan={8}>
        <Indicator
          title={t('home.miniDash.rse.recycled_weight_DEEE.title')}
          purpose={{ resource: 'RSE', endPoint: 'recycled_weight_DEEE' }}
        />
        <Indicator
          title={t('home.miniDash.rse.quantity.title')}
          purpose={{ resource: 'RSE', endPoint: 'quantity' }}
        />
        <Indicator
          purpose={{ resource: 'RSE', endPoint: 'valuation_repair' }}
        />
      </MiniDashDisplayContainer>
      <DashDisplayContainer>
        <ChartDash
          title={t('home.dash.recycled_weight_evolution.title')}
          purpose={{
            resource: 'RSE',
            endPoint: 'recycled_weight_evolution'
          }}
        />
        <ChartDash
          title={t('home.dash.recycled_vs_reused.title')}
          purpose={{
            resource: 'RSE',
            endPoint: 'recycled_vs_reused'
          }}
        />
        <ChartDash
          title={t('home.dash.valorisation_evolution.title')}
          purpose={{
            resource: 'RSE',
            endPoint: 'valorisation_evolution'
          }}
        />
      </DashDisplayContainer>
      <Row style={{ marginTop: '50px' }}>
        <RACITable data={[]} />
      </Row>
    </>
  );
};

export default RSEDashboard;
