import React from 'react';
import { Divider, Icon, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';

const actionColumn = (path, pathname, t, deleteResource) => {
  const iconSize = 18;

  return [
    {
      key: 'action',
      fixed: 'right',
      // eslint-disable-next-line react/prop-types
      render: ({ key }) => (
        <span>
          <Link
            to={{
              pathname: `${path || pathname}/show/${key}`
            }}
          >
            <Icon type="eye" style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: `${path || pathname}/edit/${key}`
            }}
          >
            <Icon type="edit" style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => deleteResource(key)}
            icon={<Icon type="warning" style={{ fontSize: iconSize }} />}
          >
            <Icon type="delete" style={{ fontSize: iconSize, color: 'red' }} />
          </Popconfirm>
        </span>
      )
    }
  ];
};

export default actionColumn;
