import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Icon, Row, Tag, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  ticketPriorityColors,
  ticketStatusColors,
  ticketTypeColors,
  useStateWithSessionStorage
} from '../../utils';
import { ResourceLandingLayout } from '../../layouts';
import DatePickerFooter from './DatePickerFooter';
import ExtraOptions from './ExtraOptions';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ListTickets = () => {
  const { t } = useTranslation();
  const [optionsDisplay, setOptionsDisplay] = useState(false);
  const [disabledIncidentDateRange, setDisabledIncidentDateRange] = useState(
    false
  );

  const [rangeDates, setRangeDates] = useStateWithSessionStorage(
    'ticketsRangeDates',
    []
  );

  const [filter, setFilter] = useStateWithSessionStorage('ticketsFilter', {
    open: null,
    waiting: null,
    progress: null,
    audited: null,
    refused: null,
    closed: null,
    deleted: null,
    normal: null,
    high: null,
    urgent: null,
    collection: null,
    theft: null,
    lost: null,
    damage: null,
    delivery: null
  });

  const [dateField, setDateField] = useStateWithSessionStorage(
    'ticketsDateField',
    'creation_date'
  );

  const onDateChange = (date, dateString) => {
    setRangeDates(date.length > 0 ? dateString : []);
  };

  const datesUrl =
    rangeDates && rangeDates[0]
      ? `&${dateField}>=${rangeDates[0]}&${dateField}<=${rangeDates[1]}`
      : null;

  const handleReset = () => {
    setFilter({
      collection: null,
      theft: null,
      lost: null,
      damage: null,
      delivery: null,
      open: null,
      waiting: null,
      progress: null,
      audited: null,
      refused: null,
      closed: null,
      deleted: null,
      normal: null,
      high: null,
      urgent: null
    });
    setRangeDates([]);
    setDisabledIncidentDateRange(false);
  };

  useEffect(() => {
    if (
      filter.delivery ||
      filter.damage ||
      filter.lost ||
      filter.theft ||
      filter.collection ||
      filter.open ||
      filter.waiting ||
      filter.progress ||
      filter.audited ||
      filter.refused ||
      filter.closed ||
      filter.deleted ||
      filter.normal ||
      filter.high ||
      filter.urgent ||
      rangeDates.length
    )
      setOptionsDisplay(true);
    // eslint-disable-next-line
  }, []);

  const extraQuery = `${filter.open || ''}${filter.waiting ||
    ''}${filter.progress || ''}${filter.audited || ''}${filter.closed ||
    ''}${filter.refused || ''}${filter.deleted || ''}${filter.normal ||
    ''}${filter.urgent || ''}${filter.high || ''}${filter.collection ||
    ''}${filter.lost || ''}${filter.damage || ''}${filter.theft ||
    ''}${filter.delivery || ''}${filter.priority || ''}${datesUrl || ''}`;

  return (
    <ResourceLandingLayout
      customActionColumn
      resourceName="tickets"
      extraQuery={extraQuery}
      columns={[
        {
          title: t('tickets.list.column.label'),
          dataIndex: 'label',
          sorter: true
        },
        {
          title: t('tickets.list.column.assets'),
          dataIndex: 'assets',
          sorter: true,
          render: (assets) => <Typography.Text>{assets.length}</Typography.Text>
        },
        {
          title: t('tickets.list.column.type'),
          dataIndex: 'type',
          sorter: true,
          render: (type) => (
            <Tag color={ticketTypeColors[type]}>
              {t(`tickets.options.${type}`)}
            </Tag>
          )
        },
        {
          title: t('tickets.list.column.status'),
          dataIndex: 'status',
          sorter: true,
          render: (status) => (
            <Tag color={ticketStatusColors[status]}>
              {t(`tickets.options.${status}`)}
            </Tag>
          )
        },
        {
          title: t('tickets.list.column.priority'),
          dataIndex: 'priority',
          sorter: true,
          render: (priority) => (
            <Tag color={ticketPriorityColors[priority]}>{priority}</Tag>
          )
        }
      ]}
    >
      <Row type="flex" justify="start" style={{ margin: 16 }}>
        <Tooltip title="Options" placement="right" mouseEnterDelay={1}>
          <Icon
            style={{ fontSize: 20 }}
            type={optionsDisplay ? 'up' : 'filter'}
            onClick={() => setOptionsDisplay(!optionsDisplay)}
          />
        </Tooltip>
      </Row>
      <Row
        style={{ display: optionsDisplay ? 'contents' : 'none' }}
        gutter={36}
      >
        <ExtraOptions
          filter={filter}
          setDisabledIncidentDateRange={setDisabledIncidentDateRange}
          handleFilterChange={(key, value) =>
            setFilter({ ...filter, [key]: value })
          }
          reset={handleReset}
        />
        <Row
          style={{
            margin: '10px 0 0',
            paddingBottom: 16,
            borderBottom: '1px solid var(--main-color)'
          }}
          gutter={16}
          type="flex"
          justify="space-between"
        >
          <Col span={8}>
            <Row style={{ marginBottom: 5 }}>
              <Text strong>{t('header.menu.filteredTab.dateRange')}</Text>
            </Row>
            <Row>
              <RangePicker
                style={{ width: '100%' }}
                value={
                  rangeDates.length > 0
                    ? [moment(rangeDates[0]), moment(rangeDates[1])]
                    : []
                }
                onChange={onDateChange}
                allowClear
                renderExtraFooter={() => (
                  <DatePickerFooter
                    dateField={dateField}
                    disabledIncidentDateRange={disabledIncidentDateRange}
                    handleDateFieldChange={(value) => setDateField(value)}
                  />
                )}
              />
            </Row>
          </Col>
        </Row>
      </Row>
    </ResourceLandingLayout>
  );
};

export default ListTickets;
