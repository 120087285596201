import React, { useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LazyLoad from 'react-lazyload';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import chartsElementsReducer from '../chartsElementsReducer';
import useLanguageContext from '../../../contexts/LanguageContext';
import useFiltersContext from '../context/DashboardContext';
import BasicChart from './BasicChart';
import SquareHierarchyChart from './SquareHierarchyChart';
import { headersTop10Data, headersTop10Voice } from '../exportHeaders';
import PieChart from './PieChart';
import ExtraButton from './ExtraButton';

const { Title } = Typography;

const ChartDash = ({
  title,
  purpose,
  transposed,
  actionPayload,
  withSwitch,
  defaultUnit,
  chart
}) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const {
    timeScale,
    filters: { provider, device, subsidiaries, genre, owner }
  } = useFiltersContext();
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { endPoint, resource } = purpose || {};
  const [unit, setUnit] = useState(defaultUnit);
  const [chartsElements, dispatch] = useReducer(chartsElementsReducer, {
    isLoading: !actionPayload
  });
  const endPoints = ['top_overage_data', 'top_overage_voice'];

  let iconType = 'bar-chart';
  if (['average_real_cost_by_month'].includes(endPoint)) {
    iconType = 'line-chart';
  }

  const { titleExtra } = chartsElements;

  let CancelToken;
  let cancel;

  const formatData = useCallback(async () => {
    CancelToken = axios.CancelToken;
    const fetchData = async () => {
      setIsLoading(true);
      let results;

      let providerUrl;
      if (provider) providerUrl = `&provider=${provider}`;

      let deviceUrl;
      if (device.length) deviceUrl = `&devices=${device}`;

      let genreUrl;
      if (genre.length) genreUrl = `&genre=${genre}`;

      let ownerUrl;
      if (owner) ownerUrl = `&owner=${owner}`;

      let subsidiariesUrl;
      if (subsidiaries.length)
        subsidiariesUrl = `&subsidiaries=${subsidiaries}`;

      try {
        results = await dispatchAPI('GET', {
          url: `/dashboards/${resource}/${endPoint}?start_date=${
            timeScale[0]
          }&end_date=${timeScale[1]}&unit=${unit}${providerUrl ||
            ''}${ownerUrl || ''}${deviceUrl || ''}${genreUrl ||
            ''}${subsidiariesUrl || ''}`,
          cancelToken: new CancelToken((c) => {
            cancel = c;
          })
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        if (axios.isCancel(e)) {
          return [];
        }
      }
      setIsLoading(false);
      if (endPoint === 'top_overage_data') {
        const result = [];
        if (results.data) {
          results.data.forEach(({ date, ...data }) => {
            result.push({
              ...data,
              date: date ? moment(date).format('L') : ''
            });
          });
        }
        setCSVData(result);
        setHeaders(headersTop10Data);
      } else if (endPoint === 'top_overage_voice') {
        const result = [];
        if (results.data) {
          results.data.forEach(({ date, ...data }) => {
            result.push({
              ...data,
              date: date ? moment(date).format('L') : ''
            });
          });
        }
        setCSVData(result);
        setHeaders(headersTop10Voice);
      }
      return (results || {}).data;
    };
    dispatch({
      type: endPoint,
      payload: actionPayload || {
        data: (await fetchData()) || [],
        t,
        timeScale,
        unit
      }
    });
  }, [
    resource,
    endPoint,
    timeScale,
    device,
    genre,
    owner,
    subsidiaries,
    actionPayload,
    unit,
    provider,
    locale
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    formatData();
    if (cancel)
      return () => {
        cancel();
      };
  }, [formatData]);

  const handleSwitch = (v) => {
    setUnit(v);
  };

  const extraButton = () => {
    if (withSwitch)
      return <ExtraButton unit={unit} handleSwitch={handleSwitch} />;
    if (titleExtra) return titleExtra;
    return null;
  };

  const CSVButton = () => (
    <>
      <CSVLink
        style={{ float: 'center', marginLeft: 30 }}
        filename={`${endPoint}.csv`}
        data={csvData}
        headers={headers.map(({ label, ...header }) => ({
          label: t(`${endPoint}.${label}`),
          ...header
        }))}
      >
        <p style={{ transform: 'rotate(90deg) ' }}>&#8677;</p>
      </CSVLink>
    </>
  );

  const charts = {
    basic: (
      <BasicChart
        chartsElements={chartsElements}
        iconType={iconType}
        transposed={transposed}
      />
    ),
    square: <SquareHierarchyChart chartsElements={chartsElements} />,
    pie: (
      <PieChart
        chartsElements={chartsElements}
        iconType={iconType}
        transposed={transposed}
      />
    )
  };

  return (
    <div>
      <Title style={{ textAlign: 'center' }} level={4}>
        {`${title} `}
        {extraButton()}
        {endPoints.includes(endPoint) ? <CSVButton /> : null}
      </Title>
      <LazyLoad
        debounce={500}
        height={100}
        placeholder={
          <Spin size="large" spinning>
            <div style={{ height: 500, width: '100%' }} />
          </Spin>
        }
      >
        <Spin spinning={isLoading} size="large">
          {charts[chart]}
        </Spin>
      </LazyLoad>
    </div>
  );
};

ChartDash.propTypes = {
  title: PropTypes.string,
  rawData: PropTypes.shape({}),
  purpose: PropTypes.shape({
    resource: PropTypes.string,
    endPoint: PropTypes.string
  }),
  transposed: PropTypes.bool,
  actionPayload: PropTypes.shape({}),
  withSwitch: PropTypes.bool,
  defaultUnit: PropTypes.string,
  chart: PropTypes.string
};
ChartDash.defaultProps = {
  title: null,
  rawData: null,
  purpose: null,
  transposed: false,
  actionPayload: null,
  withSwitch: false,
  defaultUnit: 'volume',
  chart: 'basic'
};
export default ChartDash;
