/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message, Spin } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import InputGenerator from '../../components/inputGenerator/InputGenerator';
import orderFields from './fields';
import { ContentCustom } from '../../components';
import AddArticleForm from './AddArticleForm';
import AmountManagerForm from './AmountManagerForm';
import {
  makeArticle,
  articlesSummer,
  handleSubmitUtil,
  defaultLoading,
  defaultCalculatedValues,
  spinStyle,
  defaultFieldsLoading,
  defaultProps,
  PropTypesDeclaration
} from './utils';

const EditAndCreateOrderForm = ({
  id,
  preFilledInfo,
  history,
  formItemLayout,
  tailFormItemLayout,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFieldsValue,
    getFieldValue,
    getFieldProps,
    getFieldsValue
  }
}) => {
  const { dispatchAPI } = useAuthContext();
  const [loading, setLoading] = useState(defaultLoading);
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [leasers, setLeasers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [order, setOrder] = useState(null);
  const [enums, setEnums] = useState({ type: [] });
  const companyName = null;
  const [articles, setArticles] = useState([makeArticle(0)]);
  const [calculatedValues, setCalculatedValues] = useState(
    defaultCalculatedValues
  );
  const { seller, total, buyer, assets } = preFilledInfo || {};
  const supplier = getFieldValue('supplier');
  const client = getFieldValue('client');
  const owner = getFieldValue('owner');

  useEffect(() => {
    setOrder({ ...order, supplier, client, owner });
  }, [owner, client, supplier]);

  const getOrder = async () => {
    setLoading({ ...loading, orderIsLoading: true });
    try {
      const result = await dispatchAPI('GET', {
        url: `/orders/${id}`
      });
      setOrder(result.data);
    } catch (e) {
      if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
    }
    setLoading({ ...loading, orderIsLoading: false });
  };

  const getOrderEnums = async () => {
    setLoading({ ...loading, typesAreLoading: true });
    try {
      const { data } = await dispatchAPI('GET', { url: '/orders/enums' });
      setEnums(data);
    } catch (e) {
      console.error(e);
    }
    setLoading({ ...loading, typesAreLoading: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitUtil(validateFieldsAndScroll, dispatchAPI, history, id, t);
  };

  const getCompanyNames = async () => {
    setLoading({ ...loading, companiesAreLoading: true });
    try {
      const result = await dispatchAPI('GET', {
        url: '/companies'
      });
      setCompanies(result.data);
      setLeasers(result.data.filter((c) => c.type.includes('LEASER')));
    } catch (e) {
      message.error(e.message);
    }
    setLoading({ ...loading, companiesAreLoading: false });
  };

  const getContracts = async () => {
    setLoading({ ...loading, contractsAreLoading: true });
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/contracts'
      });
      setContracts(data);
    } catch (e) {
      console.error(e);
    }
    setLoading({ ...loading, contractsAreLoading: false });
  };

  const getSettings = async () => {
    setLoading({ ...loading, catalogIsLoading: true });
    try {
      const { data } = await dispatchAPI('GET', { url: '/catalogs' });
      setCatalog(data.articles ? data.articles.sort() : []);
    } catch (e) {
      console.error(e);
    }
    setLoading({ ...loading, catalogIsLoading: false });
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await getOrder();
      })();
    }
    (async () => {
      await getOrderEnums();
      await getCompanyNames();
      await getContracts();
      await getSettings();
    })();
  }, []);

  useEffect(() => {
    if (order && order.articles && order.articles.length) {
      const tmp = [];
      order.articles.forEach((opt, idx) => {
        tmp.push(makeArticle(idx));
      });
      setArticles([...tmp]);
    }
  }, [order]);

  const addArticleField = () => {
    const tmp = articles;
    tmp.push(makeArticle(tmp.length));
    setArticles([...tmp]);
  };

  const sumUpArticles = useCallback(
    (fieldsValue = getFieldsValue()) => {
      if (fieldsValue.addArticle) {
        setFieldsValue(articlesSummer(fieldsValue));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFieldsValue, articles, getFieldsValue]
  );

  useEffect(() => {
    const values = getFieldsValue();
    if (values.addArticle) sumUpArticles();
  }, [calculatedValues, sumUpArticles, articles]);

  const deleteArticle = (key) => {
    const tmp = articles.filter(({ articleId }) => articleId !== key);
    setArticles([...tmp]);
  };

  const { orderIsLoading } = loading;

  useEffect(() => {
    if (seller && buyer && total && assets && companies.length) {
      setFieldsValue({ to: buyer, from: seller, total });
      const tmp = [];
      assets.forEach((opt, idx) => {
        tmp.push(makeArticle(idx));
      });
      setArticles([...tmp]);
    }
  }, [seller, buyer, total, assets, companies]);

  return (
    <ContentCustom>
      {orderIsLoading ? (
        <Spin style={spinStyle} />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Form {...formItemLayout} onSubmit={handleSubmit}>
          <InputGenerator
            title="orders"
            fields={orderFields(
              defaultFieldsLoading(loading),
              order,
              enums.type,
              companies,
              leasers,
              contracts,
              catalog,
              t,
              companyName
            )}
            getFieldDecorator={getFieldDecorator}
          />
          <AddArticleForm
            articles={articles}
            order={order}
            getFieldDecorator={getFieldDecorator}
            getFieldProps={getFieldProps}
            calculatedValues={calculatedValues}
            setCalculatedValues={setCalculatedValues}
            catalog={catalog}
            deleteArticle={deleteArticle}
          />
          <AmountManagerForm
            order={order}
            getFieldDecorator={getFieldDecorator}
            tailFormItemLayout={tailFormItemLayout}
            addArticleField={addArticleField}
            orderIsLoading={orderIsLoading}
          />
        </Form>
      )}
    </ContentCustom>
  );
};

EditAndCreateOrderForm.defaultProps = defaultProps;

EditAndCreateOrderForm.propTypes = PropTypesDeclaration;

const WrappedEditAndCreateOrderAntForm = Form.create({
  name: 'orders'
})(EditAndCreateOrderForm);

export default WrappedEditAndCreateOrderAntForm;
