const headers = [
  {
    label: 'number',
    key: 'number'
  },
  {
    label: 'label',
    key: 'label'
  },
  {
    label: 'leaseticLabel',
    key: 'leasetic_label'
  },
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'seller',
    key: 'from.name'
  },
  {
    label: 'buyer',
    key: 'to.name'
  },
  {
    label: 'contract',
    key: 'contract.number'
  },
  {
    label: 'amendment',
    key: 'contract.amendment_number'
  },
  {
    label: 'order_number',
    key: 'order_number'
  },
  {
    label: 'pv_number',
    key: 'pv_number'
  },
  {
    label: 'emission_date',
    key: 'emission_date'
  },
  {
    label: 'payment_date',
    key: 'payment_date'
  },
  {
    label: 'pv_date',
    key: 'pv_date'
  },
  {
    label: 'due_date',
    key: 'due_date'
  },
  {
    label: 'delivery_date',
    key: 'delivery_date'
  },
  {
    label: 'comment',
    key: 'comment'
  },
  {
    label: 'quantity',
    key: 'total_quantity'
  },
  {
    label: 'VAT',
    key: 'VAT'
  },
  {
    label: 'sales',
    key: 'sales'
  },
  {
    label: 'total_excluding_taxes',
    key: 'total_excluding_taxes'
  },
  {
    label: 'total',
    key: 'total'
  }
];

export default headers;
