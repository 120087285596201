/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Typography, Row, Button } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import indicatorsElementsReducer from '../indicatorsElementsReducer';
import useLanguageContext from '../../../contexts/LanguageContext';
import useDashboardContext from '../context/DashboardContext';
import IndicatorBody from './IndicatorBody';
import CsvButton from './CsvButton';

const { Title } = Typography;

const Indicator = ({
  title,
  purpose,
  actionPayload,
  withSwitch,
  defaultUnit,
  fixedProvider,
  config
}) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const {
    timeScale,
    filters: { provider, device, subsidiaries, genre, owner }
  } = useDashboardContext();
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { resource, endPoint } = purpose || {};
  const [unit, setUnit] = useState(defaultUnit);
  const [assetData, setAssetData] = useState([]);
  const [indicatorElements, dispatch] = useReducer(
    indicatorsElementsReducer,
    {}
  );
  const CSVEndPoint = [
    'volume_asset_to_be_returned',
    'affectation',
    'assets_stock',
    'inactive_lines',
    'total_credits'
  ];

  let CancelToken;
  let cancel;

  const formatData = useCallback(async () => {
    let results;

    CancelToken = axios.CancelToken;

    let providerUrl;
    if (fixedProvider || provider)
      providerUrl = `&provider=${fixedProvider || provider}`;

    let deviceUrl;
    if (device.length) deviceUrl = `&devices=${device}`;

    let genreUrl;
    if (genre.length) genreUrl = `&genre=${genre}`;

    let ownerUrl;
    if (owner) ownerUrl = `&owner=${owner}`;

    let subsidiariesUrl;
    if (subsidiaries.length) subsidiariesUrl = `&subsidiaries=${subsidiaries}`;

    const fetchData = async () => {
      setIsLoading(true);
      setAssetData([]);
      try {
        const result = await dispatchAPI('GET', {
          url: `/dashboards/${resource}/${endPoint}?start_date=${
            timeScale[0]
          }&end_date=${timeScale[1]}&unit=${unit}${providerUrl ||
            ''}${ownerUrl || ''}${deviceUrl || ''}${genreUrl ||
            ''}${subsidiariesUrl || ''}`,
          cancelToken: new CancelToken((c) => {
            cancel = c;
          })
        });
        const { data } = await dispatchAPI('GET', {
          url: `/companies/me`
        });

        results = { ...result.data, company: data.name };
      } catch (e) {
        console.error(e);
        if (axios.isCancel(e)) {
          return {};
        }
      }
      setIsLoading(false);
      return results || {};
    };
    dispatch({
      type: endPoint,
      payload: actionPayload || {
        data: (await fetchData()) || {},
        t,
        unit,
        timeScale
      }
    });
  }, [
    endPoint,
    timeScale,
    device,
    genre,
    owner,
    subsidiaries,
    unit,
    provider,
    locale,
    actionPayload
  ]);

  useEffect(() => {
    if (resource && endPoint) formatData();
    if (cancel)
      return () => {
        cancel();
      };
  }, [formatData]);

  const handleSwitch = (v) => {
    setUnit(v);
  };

  const extraButton = () => {
    if (withSwitch)
      return (
        <div style={{ position: 'absolute', top: 0, right: 5 }}>
          <Button
            style={{ color: unit === 'volume' ? '#1890ff' : 'grey' }}
            value="volume"
            type="link"
            icon="clock-circle"
            onClick={(e) => handleSwitch(e.target.value)}
          />
          <Button
            style={{ color: unit === 'amount' ? '#1890ff' : 'grey' }}
            value="amount"
            type="link"
            icon="euro"
            onClick={(e) => handleSwitch(e.target.value)}
          />
        </div>
      );
    return null;
  };

  return (
    <Row>
      <Title level={4}>
        {title ? `${title} ` : ''}
        {extraButton()}
        {CSVEndPoint.includes(endPoint) ? (
          <CsvButton
            assetData={assetData}
            setAssetData={setAssetData}
            purpose={purpose}
            unit={unit}
          />
        ) : null}
      </Title>
      <IndicatorBody
        indicatorElements={indicatorElements}
        isLoading={isLoading}
        config={config}
        endPoint={endPoint}
      />
    </Row>
  );
};

Indicator.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  actionPayload: PropTypes.shape({}),
  purpose: PropTypes.shape({
    resource: PropTypes.string,
    endPoint: PropTypes.string
  }),
  withSwitch: PropTypes.bool,
  defaultUnit: PropTypes.string,
  fixedProvider: PropTypes.string,
  config: PropTypes.shape({
    number2: PropTypes.number
  })
};

Indicator.defaultProps = {
  title: null,
  actionPayload: null,
  purpose: null,
  withSwitch: false,
  defaultUnit: 'volume',
  fixedProvider: null,
  config: null
};

export default Indicator;
