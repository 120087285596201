import React, { useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Modal, Spin, Typography } from 'antd';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import MapFrance from '../../../components/MapFrance';
import useAuthContext from '../../../contexts/AuthContext';
import useFiltersContext from '../context/DashboardContext';
import useLanguageContext from '../../../contexts/LanguageContext';
import chartsElementsReducer from '../chartsElementsReducer';
import { headersAssetStock } from '../exportHeaders';

const { Title } = Typography;

const MapDash = ({ title, showLabel, purpose }) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const {
    timeScale,
    filters: { provider, device, subsidiaries, genre, owner }
  } = useFiltersContext();
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { endPoint, resource } = purpose || {};
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [chartsElements, dispatch] = useReducer(chartsElementsReducer, {
    isLoading: false
  });

  let CancelToken;
  let cancel;

  const formatData = useCallback(async () => {
    CancelToken = axios.CancelToken;
    const fetchData = async () => {
      setIsLoading(true);
      let results;

      let providerUrl;
      if (provider) providerUrl = `&provider=${provider}`;

      let deviceUrl;
      if (device.length) deviceUrl = `&devices=${device}`;

      let genreUrl;
      if (genre.length) genreUrl = `&genre=${genre}`;
      let ownerUrl;
      if (owner) ownerUrl = `&owner=${owner}`;
      let subsidiariesUrl;
      if (subsidiaries.length)
        subsidiariesUrl = `&subsidiaries=${subsidiaries}`;

      try {
        results = await dispatchAPI('GET', {
          url: `/dashboards/${resource}/${endPoint}?start_date=${
            timeScale[0]
          }&end_date=${timeScale[1]}${providerUrl || ''}${ownerUrl ||
            ''}${deviceUrl || ''}${genreUrl || ''}${subsidiariesUrl || ''}`,
          cancelToken: new CancelToken((c) => {
            cancel = c;
          })
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        if (axios.isCancel(e)) {
          return [];
        }
      }
      setIsLoading(false);
      return (results || {}).data;
    };
    dispatch({
      type: endPoint,
      payload: {
        data: (await fetchData()) || [],
        t,
        timeScale
      }
    });
  }, [
    resource,
    endPoint,
    timeScale,
    device,
    genre,
    owner,
    subsidiaries,
    provider,
    locale
  ]);

  const fetchCSVData = async (endPointData) => {
    try {
      const results = [];
      const { data: d } = await dispatchAPI('GET', {
        url: `/dashboards/${resource}/${endPointData}?start_date=${
          timeScale[0]
        }&end_date=${timeScale[1]}`
      });
      if (d) {
        d.forEach(({ entry_date, delivery_date, ...asset }) => {
          const tmp = {
            ...asset,
            entry_date: entry_date ? moment(entry_date).format('L') : '',
            delivery_date: delivery_date
              ? moment(delivery_date).format('L')
              : ''
          };
          results.push(tmp);
        });
      }
      setData(results);
    } catch (e) {
      message.error(e);
    }
  };

  useEffect(() => {
    formatData();
    if (cancel)
      return () => {
        cancel();
      };
  }, [formatData]);

  const { markers, selectedZones } = chartsElements;

  return (
    <div>
      <Title style={{ textAlign: 'center' }} level={4}>
        {title}
        {endPoint === 'stock_repartition' && (
          <>
            <Modal
              closable={false}
              footer={false}
              visible={visible}
              maskClosable={false}
              bodyStyle={{ textAlign: 'center' }}
            >
              <Spin spinning size="large" style={{ margin: 16 }} />
              <br />
              <Typography.Text>{t(`prepareFile`)}</Typography.Text>
            </Modal>
            <CSVLink
              asyncOnClick
              onClick={(e, done) => {
                if (!data.length) {
                  setVisible(true);
                  e.persist();
                  e.preventDefault();
                  fetchCSVData('assets_stock_data').then(() => {
                    setVisible(false);
                    e.target.click();
                    done(false);
                  });
                } else done();
              }}
              style={{ float: 'right', marginRight: 16 }}
              filename="repartition.csv"
              data={data}
              headers={headersAssetStock.map(({ label, ...header }) => ({
                label: t(`assets_stock.${label}`),
                ...header
              }))}
            >
              <p style={{ transform: 'rotate(90deg) ' }}>&#8677;</p>
            </CSVLink>
          </>
        )}
      </Title>

      <Spin spinning={isLoading}>
        <MapFrance
          markers={markers}
          showLabel={showLabel}
          selectedZones={selectedZones}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...chartsElements}
        />
      </Spin>
    </div>
  );
};

MapDash.defaultProps = {
  title: null,
  showLabel: false,
  purpose: null
};

MapDash.propTypes = {
  title: PropTypes.string,
  showLabel: PropTypes.bool,
  purpose: PropTypes.shape({
    resource: PropTypes.string,
    endPoint: PropTypes.string
  })
};

export default MapDash;
