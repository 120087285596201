import React from 'react';
import { Checkbox } from 'antd';
import moment from 'moment';
import numeral from 'numeral';

const searchFields = [
  'number',
  'phone_contract_name',
  'phone_number',
  'provider'
];

const formatNumber = (numb) => {
  let str = numb;
  str = str.replace(/ /g, '');
  str = str.replace(/^33/, '');
  str = str.replace(/^0/, '');
  return str;
};

export const extraQueryUtil = (
  rate,
  absoluteOverage,
  provider,
  selectedSub,
  keyword
) => {
  return {
    $expr: {
      $and: [
        {
          $gt: ['$amount', 0]
        },
        ...(rate !== 'all' && !absoluteOverage
          ? [
              {
                $gte: [
                  {
                    $add: [
                      '$overage_voice_amount',
                      '$overage_messages_amount',
                      '$overage_data_amount'
                    ]
                  },
                  { $multiply: ['$amount', rate] }
                ]
              }
            ]
          : []),
        ...(absoluteOverage
          ? [
              {
                $gte: [
                  {
                    $add: [
                      '$overage_voice_amount',
                      '$overage_messages_amount',
                      '$overage_data_amount'
                    ]
                  },
                  absoluteOverage
                ]
              }
            ]
          : [])
      ]
    },
    ...(provider !== 'all'
      ? {
          provider
        }
      : {}),
    ...(selectedSub.length
      ? {
          subsidiary: { $in: selectedSub }
        }
      : {}),
    ...(keyword
      ? {
          $or: searchFields.map((field) => ({
            [field]: {
              $regex:
                field === 'phone_number' ? formatNumber(keyword) : keyword,
              $options: 'i'
            }
          }))
        }
      : {})
  };
};
export const columnsUtil = (
  allSelected,
  setAllSelected,
  selectedKeys,
  handleSelect,
  t,
  getOverageDuration
) => {
  return [
    {
      title: (
        <Checkbox
          checked={allSelected}
          onChange={() => setAllSelected(!allSelected)}
        />
      ),
      width: 50,
      dataIndex: 'key',
      render: (id) => (
        <Checkbox
          checked={allSelected || selectedKeys.includes(id)}
          onChange={() => handleSelect(id)}
        />
      )
    },
    {
      title: t('telecomInvoices.list.column.date'),
      dataIndex: 'date',
      sorter: true,
      render: (d) => (d ? moment(d).format('ll') : '')
    },
    {
      title: t('telecomInvoices.list.column.subsidiary'),
      dataIndex: 'subsidiary',
      sorter: true,
      render: (s) => (s ? s.name : 'N/R')
    },
    {
      title: t('telecomInvoices.list.column.number'),
      dataIndex: 'number',
      sorter: true
    },
    {
      title: t('telecomInvoices.list.column.phone_number'),
      dataIndex: 'phone_number',
      render: (phoneNumber) =>
        phoneNumber &&
        `${(phoneNumber[0] === '0' ? phoneNumber : `0${phoneNumber}`).replace(
          /(.{2})/g,
          '$1 '
        )}`
    },
    {
      title: t('telecomInvoices.list.column.asset'),
      dataIndex: 'asset',
      render: (a) => (a && a.people && a.people.email) || 'N/R'
    },
    {
      title: t('telecomInvoices.list.column.overageDuration'),
      key: 'overageDuration',
      align: 'right',
      render: ({ asset }) => getOverageDuration(asset)
    },
    {
      title: t('telecomInvoices.list.column.amount'),
      dataIndex: 'amount',
      align: 'right',
      sorter: true,
      render: (a) => numeral(a).format('0,0.00$')
    },
    {
      title: t('telecomInvoices.list.column.overage_voice_amount'),
      dataIndex: 'overage_voice_amount',
      align: 'right',
      sorter: true,
      render: (a, { amount }) =>
        `${numeral(a).format('0,0.00$')} (${numeral(a / amount).format('0%')})`
    },
    {
      title: t('telecomInvoices.list.column.overage_messages_amount'),
      dataIndex: 'overage_messages_amount',
      align: 'right',
      sorter: true,
      render: (a, { amount }) =>
        `${numeral(a).format('0,0.00$')} (${numeral(a / amount).format('0%')})`
    },
    {
      title: t('telecomInvoices.list.column.overage_data_amount'),
      dataIndex: 'overage_data_amount',
      align: 'right',
      sorter: true,
      render: (a, { amount }) =>
        `${numeral(a).format('0,0.00$')} (${numeral(a / amount).format('0%')})`
    }
  ];
};
