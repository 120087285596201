import moment from 'moment';

const timeReducer = (state, { initiator, payload }) => {
  switch (initiator) {
    case 'buttons':
      switch (payload) {
        case 'lastYear':
          return [
            moment()
              .subtract('1', 'year')
              .startOf('year'),
            moment()
              .subtract('1', 'year')
              .endOf('year')
              .startOf('day')
          ];
        case '12months':
          return [
            moment()
              .subtract(1, 'year')
              .startOf('month'),
            moment()
              .subtract(1, 'month')
              .endOf('month')
              .startOf('day')
          ];
        case 'year':
          return [
            moment().startOf('year'),
            moment()
              .endOf('year')
              .startOf('day')
          ];
        case 's1':
          return [
            moment().startOf('year'),
            moment()
              .startOf('year')
              .add(5, 'months')
              .endOf('month')
              .startOf('day')
          ];
        case 's2':
          return [
            moment()
              .startOf('year')
              .add(6, 'month'),
            moment()
              .endOf('year')
              .startOf('day')
          ];
        case 'q1':
          return [
            moment()
              .quarter(1)
              .startOf('quarter'),
            moment()
              .quarter(1)
              .endOf('quarter')
              .startOf('day')
          ];
        case 'q2':
          return [
            moment()
              .quarter(2)
              .startOf('quarter'),
            moment()
              .quarter(2)
              .endOf('quarter')
              .startOf('day')
          ];
        case 'q3':
          return [
            moment()
              .quarter(3)
              .startOf('quarter'),
            moment()
              .quarter(3)
              .endOf('quarter')
              .startOf('day')
          ];
        case 'q4':
          return [
            moment()
              .quarter(4)
              .startOf('quarter'),
            moment()
              .quarter(4)
              .endOf('quarter')
              .startOf('day')
          ];
        default:
          return [];
      }
    case 'picker':
      return payload;
    default:
      return [];
  }
};

export default timeReducer;
