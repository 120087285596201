import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import LoginForm from './LoginForm';
import ForgotPwdForm from './ForgotPwdForm';
import ChangePwdForm from './ChangePwdForm';
import leaseticLogo from '../../assets/images/leasetic.png';
import logo from '../../assets/images/ColibrisBlanc.png';
import logoBlack from '../../assets/images/colibrisbleu.png';
import loginBg from '../../assets/images/Visuel4.jpg';
import './login.css';

const { Title } = Typography;

const Login = () => {
  const { token } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const firstPwd = params.get('firstPwd');
  const { t } = useTranslation();
  const [currentForm, setCurrentForm] = useState('login');
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    ),
    choosePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (token) {
      if (firstPwd) setCurrentForm('choosePwd');
      else setCurrentForm('changePwd');
    }
    // eslint-disable-next-line
  }, [token]);

  const { width, height } = size;

  return (
    <div>
      <Layout
        style={{
          display: 'inherit',
          minHeight: '100vh',
          maxHeight: '100vh',
          background: 'var(--main-color)'
        }}
      >
        <Col
          style={{ minHeight: 'inherit' }}
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 12 }}
          lg={{ span: 14 }}
          xl={{ span: 16 }}
          xxl={{ span: 18 }}
        >
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{
              height: '100vh',
              overflow: 'hidden'
            }}
          >
            <img
              style={{
                width: width > 1200 ? '100%' : 'auto',
                height: width > 1200 ? 'auto' : '100%',
                objectPosition: width > 1650 ? 'center -25vw' : 'initial'
              }}
              src={loginBg}
              alt="Bienvenue sur Colibris"
            />
          </Row>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 10 }}
          xl={{ span: 8 }}
          xxl={{ span: 6 }}
          className="col-login"
        >
          <Row
            type="flex"
            justify="center"
            align={width < 768 ? 'top' : 'middle'}
            style={{
              minHeight: '100vh',
              maxHeight: '100vh',
              overflowX: 'hidden',
              overflowY: 'auto'
            }}
          >
            {width < 768 && height > 550 && (
              <Row
                style={{
                  textAlign: 'center',
                  padding: 48
                }}
              >
                <img
                  src={logoBlack}
                  alt="Colibris logo"
                  style={{ width: '70%' }}
                />
              </Row>
            )}
            {width < 768 && height <= 550 && (
              <Row
                style={{
                  textAlign: 'center',
                  padding: 32
                }}
              >
                <img
                  src={logoBlack}
                  alt="Colibris logo"
                  style={{ width: '60%' }}
                />
              </Row>
            )}
            <Col
              style={{
                textAlign: 'center',
                padding: '20px 0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {width > 768 && (
                <img
                  style={{
                    width: 250,
                    marginBottom: 64
                  }}
                  src={logoBlack}
                  alt="Leasetic logo"
                />
              )}
              <Title style={{ textAlign: 'center', width: '80%' }}>
                {t(`login.title.${currentForm}`)}
              </Title>
              {forms[currentForm]}
            </Col>
          </Row>
        </Col>
      </Layout>
    </div>
  );
};

export default Login;
