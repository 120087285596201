import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import AssetsTab from './AssetsTab';
import InvoicesTab from './InvoicesTab';
import PartiesTab from './PartiesTab';
import useAuthContext from '../../contexts/AuthContext';
import useLanguageContext from '../../contexts/LanguageContext';
import { ContentCustom } from '../../components';

const RelatedItems = ({ contractId, parties, invoices, history }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const [isLoading, setIsLoading] = useState(true);
  const [activeKey, setActiveKey] = useState('assets');
  const [tabList, setTabList] = useState([]);
  const [contentList, setContentList] = useState({});

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  const checkIfHasAssets = async () => {
    setIsLoading(true);
    const result = await dispatchAPI('GET', {
      url: `/assets?contract=${contractId}&limit=10`
    });
    if (result.headers['x-total-count'] > 0) {
      setActiveKey('assets');
      setTabList([
        {
          key: 'assets',
          tab: 'Assets'
        },
        {
          key: 'parties',
          tab: t('relatedItems.tabs.parties')
        },
        {
          key: 'invoices',
          tab: t('relatedItems.tabs.invoices')
        }
      ]);
      setContentList({
        assets: <AssetsTab contractId={contractId} history={history} />,
        parties: <PartiesTab partiesData={parties} />,
        invoices: <InvoicesTab invoicesData={invoices} history={history} />
      });
    } else {
      setActiveKey('parties');
      setTabList([
        {
          key: 'parties',
          tab: t('relatedItems.tabs.parties')
        },
        {
          key: 'invoices',
          tab: t('relatedItems.tabs.invoices')
        }
      ]);
      setContentList({
        parties: <PartiesTab partiesData={parties} />,
        invoices: <InvoicesTab invoicesData={invoices} history={history} />
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (parties) {
      (async () => {
        await checkIfHasAssets();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parties, locale]);

  return (
    <ContentCustom marginTop={8}>
      <Typography.Title level={4}>
        {t('contracts.showContract.relatedItems.title')}
      </Typography.Title>
      <Card
        tabList={tabList}
        activeTabKey={activeKey}
        onTabChange={(key) => {
          onTabChange(key);
        }}
        className="infolist-card"
      >
        <Skeleton
          active
          title={false}
          loading={isLoading}
          paragraph={{
            rows: 6,
            width: ['100%', '100%', '100%', '100%', '100%', '100%']
          }}
        >
          {contentList[activeKey]}
        </Skeleton>
      </Card>
    </ContentCustom>
  );
};

RelatedItems.defaultProps = {
  parties: null,
  invoices: null
};

RelatedItems.propTypes = {
  contractId: PropTypes.string.isRequired,
  parties: PropTypes.shape({
    customer: PropTypes.shape({}).isRequired,
    suppliers: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
  }),
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({}).isRequired
};

export default RelatedItems;
