import React, { useState } from 'react';
import { Button, Input, Icon, Table, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { assetsStatusColor } from '../../utils';
import { ContentCustom } from '../../components';

const { Title } = Typography;
const { Column } = Table;
const TicketAssets = ({ isTableLoading, assets, assetsEnumValues }) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const handleChange = (page) => {
    setPagination(page);
  };
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const [searchInput, setSearchInput] = useState(null);
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  return (
    <ContentCustom marginTop={8}>
      <Title level={4}>{t('tickets.showTicket.assetsTitle')}</Title>
      <Table
        dataSource={assets}
        loading={isTableLoading}
        pagination={pagination}
        onChange={handleChange}
      >
        <Column
          title={t(`assets.list.column.city`)}
          dataIndex="city"
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
          }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={(node) => {
                  setSearchInput(node);
                }}
                placeholder={t(`assets.list.column.city`)}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm)}
                icon="search"
                size="small"
                style={{ width: 110, marginRight: 8 }}
              >
                {t('buttons.search')}
              </Button>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </div>
          )}
          onFilter={(value, record) =>
            record.city
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          }
          filterIcon={(filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#1890ff' : undefined }}
            />
          )}
          onFilterDropdownVisibleChange={(filterVisible) => {
            if (filterVisible) {
              setTimeout(() => searchInput.select());
            }
          }}
        />
        <Column
          title={t(`assets.list.column.serial_number`)}
          dataIndex="serial_number"
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
          }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={(node) => {
                  setSearchInput(node);
                }}
                placeholder={t('assets.list.column.serial_number')}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm)}
                icon="search"
                size="small"
                style={{ width: 110, marginRight: 8 }}
              >
                {t('buttons.search')}
              </Button>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </div>
          )}
          filterIcon={(filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#1890ff' : undefined }}
            />
          )}
          onFilter={(value, record) =>
            record.serial_number
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          }
          onFilterDropdownVisibleChange={(filterVisible) => {
            if (filterVisible) {
              setTimeout(() => searchInput.select());
            }
          }}
        />
        <Column title="Description" dataIndex="description" />
        <Column
          title={t(`assets.list.column.status`)}
          dataIndex="status"
          render={(assetStatus) => (
            <Tag color={assetsStatusColor[assetStatus]}>
              {t(`assets.tags.${assetStatus}`)}
            </Tag>
          )}
          filters={
            assetsEnumValues.status &&
            assetsEnumValues.status.map((enumValue) => ({
              text: t(`assets.tags.${enumValue}`),
              value: enumValue
            }))
          }
          onFilter={(value, record) =>
            record.status
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          }
        />
      </Table>
    </ContentCustom>
  );
};

TicketAssets.propTypes = {
  isTableLoading: PropTypes.bool.isRequired,
  assetsEnumValues: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default TicketAssets;
