import { useState, useEffect, useReducer } from 'react';

// TODO: Switch la local storage if "Remember me" is checked
export const useStateWithSessionStorage = (storageKey, defaultValue = null) => {
  const [value, setValue] = useState(
    JSON.parse(sessionStorage.getItem(storageKey)) || defaultValue
  );

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);
  return [value, setValue];
};

export const useStateWithLocalStorage = (storageKey, defaultValue = null) => {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem(storageKey)) || defaultValue
  );

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);
  return [value, setValue];
};

export const useReducerWithSessionStorage = (
  storageKey,
  reducer,
  defaultValue
) => {
  const [value, dispatch] = useReducer(
    reducer,
    JSON.parse(sessionStorage.getItem(storageKey)) || defaultValue
  );

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);
  return [value, dispatch];
};

export const useStateWithDynamicStorage = (useLocalStorage, storageKey) => {
  const [value, setValue] = useState(
    JSON.parse(
      useLocalStorage
        ? localStorage.getItem(storageKey)
        : sessionStorage.getItem(storageKey)
    )
  );

  useEffect(() => {
    if (useLocalStorage) {
      localStorage.setItem(storageKey, JSON.stringify(value));
      sessionStorage.removeItem(storageKey);
    } else {
      sessionStorage.setItem(storageKey, JSON.stringify(value));
      localStorage.removeItem(storageKey);
    }
  }, [value, storageKey, useLocalStorage]);
  return [value, setValue];
};

export const invoiceTypeColors = {
  CESSION: 'green',
  BROKERAGE: 'magenta',
  CONSULTING: 'geekblue',
  CREDIT: 'blue',
  BUILD: 'volcano',
  RUN: 'orange',
  FINANCIAL_PORTAGE: 'cyan'
};

export const contractTypeColor = {
  CLASSIC: 'green',
  CAP: 'magenta',
  MASTER_AGREEMENT: 'geekblue',
  CAPéTIC: 'volcano',
  MCR: 'orange',
  CONSULTING: 'cyan',
  SERVICES_CONTRACT: 'gold'
};

export const contractStatusColor = {
  SIGNED: 'green',
  YIELDED: 'magenta'
};

export const contractPeriodicityColor = {
  MONTHLY: 'green',
  QUARTERLY: 'magenta',
  SEMESTER: 'geekblue',
  YEARLY: 'volcano'
};

export const userRoleColor = {
  ADMIN: 'volcano',
  'SUPER-USER': 'geekblue',
  'SUPER-ADMIN': 'purple',
  USER: 'green'
};

export const raciStatusColor = {
  IN_PROGRESS: 'orange',
  RELEASED: 'green',
  ESCALATION: 'red'
};

export const companyTypeColor = {
  LEASER: 'green',
  CLIENT: 'magenta',
  SUPPLIER: 'geekblue',
  REFINANCER: 'volcano'
};

export const assetsStatusColor = {
  'EN STOCK': 'green',
  'STOCK SORTIE': 'red',
  'STOCK ENTREE': 'geekblue',
  AFFECTE: 'volcano',
  'A DEPOLLUER': 'red',
  SINISTER: 'red',
  USED: 'blue',
  null: 'grey'
};

export const assetsStateColor = {
  UNKNOWN: 'grey',
  BROKEN: 'green',
  GOOD: 'geekblue',
  VERY_GOOD: 'volcano'
};

export const ticketTypeColors = {
  COLLECTION: 'green',
  THEFT: 'volcano',
  BREAKDOWN: 'orange',
  DAMAGE: 'red',
  DELIVERY: 'blue',
  LOST: 'cyan'
};

export const ticketStatusColors = {
  OPEN: 'blue',
  WAITING: 'green',
  IN_PROGRESS: 'orange',
  CLOSED: 'red',
  DELETED: 'red',
  AUDITED: 'cyan',
  REFUSED_BY_INSURER: 'volcano'
};

export const ticketPriorityColors = {
  NORMAL: 'green',
  HIGH: 'orange',
  URGENT: 'red'
};

export const providerColors = {
  BOUYGUES: '#4f9fcd',
  ORANGE: '#e86229',
  SFR: '#e22d2c'
};

export const reportTypes = {
  DESTRUCTION: 'red',
  ERASURE: 'orange'
};
