import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Radio, Typography, Col, Select, message } from 'antd';
import moment from 'moment';
import './timeScaling.css';
import useDashboardContext from '../../routes/home/context/DashboardContext';
import { useStateWithSessionStorage } from '../../utils';
import useHandleResize from '../../utils/handleResize';
import useAuthContext from '../../contexts/AuthContext';
import useLanguageContext from '../../contexts/LanguageContext';

const { Title } = Typography;
const { Option } = Select;

const TimeScaling = ({ activeKey }) => {
  const { dispatchAPI } = useAuthContext();
  const { locale } = useLanguageContext();
  const { timeScale, dispatchTime } = useDashboardContext();
  const { t } = useTranslation();
  const [pickerValue, setPickerValue] = useStateWithSessionStorage(
    'timeScalePickerValue'
  );
  const [buttonValue, setButtonValue] = useStateWithSessionStorage(
    'timeScaleButtonKey',
    pickerValue ? null : '12months'
  );
  const [lastUpdate, setLastUpdate] = useState();
  const { width } = useHandleResize();

  const handleTimeScaleChoice = (initiator, value) => {
    dispatchTime({ initiator, payload: value });
    setButtonValue(value);
    setPickerValue(null);
  };

  useEffect(() => {
    if (!pickerValue) {
      dispatchTime({ initiator: 'buttons', payload: buttonValue });
    }
  }, []);

  const getLastUpdate = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/importHistory/${activeKey}`
      });
      if (data) setLastUpdate(moment(data.update));
      else setLastUpdate(undefined);
    } catch (e) {
      message.error('Une erreur est survenue.');
    }
  }, [activeKey]);

  useEffect(() => {
    (async () => {
      await getLastUpdate();
    })();
  }, [getLastUpdate]);

  return (
    <>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ paddingBottom: 10 }}
      >
        <Col xs={24} xl={4}>
          {lastUpdate && (
            <Typography.Text
              strong
              style={{ textAlign: width > 1200 ? 'center' : 'left' }}
              level={4}
              className="timescale-update"
            >
              {`${t('dashboard.timeScale.update')} ${lastUpdate.format(
                'DD/MM/YYYY'
              )}`}
            </Typography.Text>
          )}
        </Col>
        <Col
          xs={24}
          xl={14}
          style={{ textAlign: width > 1200 ? 'center' : 'left' }}
        >
          {width > 1200 ? (
            <Radio.Group
              value={buttonValue}
              buttonStyle="solid"
              onChange={(e) => handleTimeScaleChoice('buttons', e.target.value)}
            >
              <Radio.Button value="lastYear">
                {moment()
                  .subtract('1', 'year')
                  .year()}
              </Radio.Button>
              <Radio.Button value="12months">
                {t('dashboard.timeScale.last12months')}
              </Radio.Button>
              <Radio.Button value="year">
                {t('dashboard.timeScale.year')}
              </Radio.Button>
              <Radio.Button value="s1">S1</Radio.Button>
              <Radio.Button value="s2">S2</Radio.Button>
              <Radio.Button value="q1">Q1</Radio.Button>
              <Radio.Button value="q2">Q2</Radio.Button>
              <Radio.Button value="q3">Q3</Radio.Button>
              <Radio.Button value="q4">Q4</Radio.Button>
            </Radio.Group>
          ) : (
            <Select
              style={{ width: 200, margin: '16px 0' }}
              value={buttonValue}
              onChange={(value) => handleTimeScaleChoice('buttons', value)}
            >
              <Option value="lastYear">
                {moment()
                  .subtract('1', 'year')
                  .year()}
              </Option>
              <Option value="12months">
                {t('dashboard.timeScale.last12months')}
              </Option>
              <Option value="year">{t('dashboard.timeScale.year')}</Option>
              <Option value="s1">S1</Option>
              <Option value="s2">S2</Option>
              <Option value="q1">Q1</Option>
              <Option value="q2">Q2</Option>
              <Option value="q3">Q3</Option>
              <Option value="q4">Q4</Option>
            </Select>
          )}
        </Col>
        <Col xs={24} xl={6}>
          <Title
            style={{
              textAlign: width > 1200 ? 'center' : 'left',
              marginBottom: 0
            }}
            level={3}
          >
            {`${moment(timeScale[0])
              .locale(locale.split('_')[0])
              .format('MMM YYYY')} - ${moment(timeScale[1])
              .locale(locale.split('_')[0])
              .format('MMM YYYY')}`}
          </Title>
        </Col>
      </Row>
    </>
  );
};

TimeScaling.propTypes = {
  activeKey: PropTypes.string.isRequired
};

export default TimeScaling;
