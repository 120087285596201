import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { message, Button, Icon, Tooltip, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import InvoiceAmount from './InvoiceAmount';
import InvoiceDetails from './InvoiceDetails';
import InvoiceTable from './InvoiceTable';
import {
  ContentCustom,
  PageHeaderCustom,
  UploadDrawer
} from '../../components';

const ShowInvoice = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [badgeCount, setBadgeCount] = useState(0);
  const [invoice, setInvoice] = useState({ files: [] });

  const getInvoice = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: `/invoices/${id}?populate=to,from,leaser,contract,files`
      });
      if (result.data.files.length) {
        setBadgeCount(result.data.files.length);
      } else setBadgeCount(0);
      setInvoice(result.data);
      setLoading(false);
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getInvoice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const prev = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?emission_date<${invoice.emission_date}&sort=-emission_date&limit=1`
      });
      if (data[0]) {
        const { _id } = data[0];
        history.push(`/invoices/show/${_id}`);
      } else {
        message.error(t('invoices.showInvoice.noPrev'));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const next = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?emission_date>${invoice.emission_date}&sort=emission_date&limit=1`
      });
      if (data[0]) {
        const { _id } = data[0];
        history.push(`/invoices/show/${_id}`);
      } else {
        message.error(t('invoices.showInvoice.noNext'));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const downloadInvoice = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/invoices/download/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoice_${invoice.number}.pdf`;
      a.click();
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  const { order_number, type } = invoice;

  return (
    <>
      <PageHeaderCustom
        title={t('invoices.showInvoice.title')}
        extra={
          <div style={{ float: 'right' }}>
            <Popconfirm
              title={t('datatable.column.action.download.title')}
              okText={t('datatable.column.action.download.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.download.cancel')}
              onConfirm={downloadInvoice}
              icon={<Icon type="warning" />}
            >
              <Button shape="circle" icon="download" type="primary" />
            </Popconfirm>
            <Tooltip
              title={t('invoices.showInvoice.toolTip.prev')}
              placement="topRight"
            >
              <Button
                shape="circle"
                icon="left"
                onClick={prev}
                style={{ marginRight: 5 }}
              />
            </Tooltip>
            <Tooltip
              title={t('invoices.showInvoice.toolTip.next')}
              placement="topRight"
            >
              <Button shape="circle" icon="right" onClick={next} />
            </Tooltip>
          </div>
        }
      />
      <ContentCustom padding={false}>
        <div
          style={{
            padding: 24,
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            width: '100%'
          }}
        >
          <InvoiceDetails
            invoice={invoice}
            isLoading={isLoading}
            order_number={order_number}
            type={type}
          />
          <InvoiceTable invoice={invoice} isLoading={isLoading} />
          <InvoiceAmount invoice={invoice} isLoading={isLoading} />
          <UploadDrawer
            badgeCount={badgeCount}
            getResource={getInvoice}
            resourceFiles={invoice.files}
            resourceId={id}
            resourceName="invoices"
            topPosition={100}
          />
        </div>
      </ContentCustom>
    </>
  );
};

export default ShowInvoice;
