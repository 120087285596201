import React from 'react';
import { Icon, Avatar, Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HeaderDropdwon from '../components/HeaderDropdown/HeaderDropdown';

const BasicLayoutHeader = ({ user, theme, profileMenu, langMenu }) => {
  const { t } = useTranslation();

  return (
    <>
      {theme.themeLogo && (
        <div className="logo">
          <img
            style={{
              ...(window.innerWidth <= 992
                ? { width: 60, height: 30, marginLeft: '-27px' }
                : { width: 100, height: 45, marginLeft: '-50px' })
            }}
            src={theme.themeLogo}
            alt="Company logo"
          />
        </div>
      )}
      <div className="container">
        {window.innerWidth > 992 && (
          <Avatar
            size="medium"
            icon={user && user.img ? '' : 'user'}
            src={user && user.img && user.img.data ? user.img.data : ''}
          >
            {user && `${user.first_name[0]}${user.last_name[0]}`}
          </Avatar>
        )}
        <HeaderDropdwon overlayMenu={profileMenu}>
          {window.innerWidth <= 992 ? (
            <span className="dropdown-container">
              <Icon type="user" />
            </span>
          ) : (
            <div>
              <span className="dropdown-container">
                <Button type="link" style={{ height: 38, padding: 0 }}>
                  <span className="full-name">
                    {`${user.first_name} ${user.last_name}`}
                  </span>
                  <p className="role">{t(`users.tags.${user.role}`)}</p>
                </Button>
              </span>
            </div>
          )}
        </HeaderDropdwon>
        <HeaderDropdwon overlayMenu={langMenu}>
          <span className="dropdown-container">
            <Icon type="global" />
          </span>
        </HeaderDropdwon>
      </div>
    </>
  );
};

BasicLayoutHeader.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    img: PropTypes.shape({
      data: PropTypes.string
    })
  }).isRequired,
  theme: PropTypes.shape({
    themeLogo: PropTypes.string
  }).isRequired,
  profileMenu: PropTypes.node.isRequired,
  langMenu: PropTypes.node.isRequired
};

export default BasicLayoutHeader;
