import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Tag } from 'antd';
import moment from 'moment';
import '../../layouts/TableLayout/style.css';
import { userRoleColor } from '../../utils';
import { ResourceLandingLayout } from '../../layouts';
import useHandleResize from '../../utils/handleResize';

const ListUsers = () => {
  const { t } = useTranslation();
  const { width } = useHandleResize();

  return (
    <ResourceLandingLayout
      customActionColumn
      withCreateButton={false}
      resourceName="users"
      columns={[
        {
          title: t('users.list.column.name'),
          key: 'last_name',
          sorter: true,
          render: (user) => (
            <Typography>{`${user.first_name} ${user.last_name}`}</Typography>
          )
        },
        {
          title: t('users.list.column.email'),
          dataIndex: 'email',
          sorter: true
        },
        {
          title: t('users.list.column.role'),
          dataIndex: 'role',
          sorter: true,
          render: (role) => (
            <Tag color={userRoleColor[role]}>{t(`users.tags.${role}`)}</Tag>
          )
        },
        {
          title: t('users.list.column.creation_date'),
          dataIndex: 'registration_date',
          sorter: true,
          render: (date) => moment(date).format('LL')
        },
        {
          title: t('users.list.column.last_login_date'),
          dataIndex: 'last_login_date',
          sorter: true,
          render: (date) =>
            width > 768
              ? (date && moment(date).format('LL')) || 'Not Logged in yet'
              : false
        }
      ]}
    />
  );
};

export default ListUsers;
