import React from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const DatePickerFooter = ({
  dateField,
  handleDateFieldChange,
  disabledIncidentDateRange
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Radio.Group
        buttonStyle="solid"
        size="small"
        value={dateField}
        onChange={(e) => handleDateFieldChange(e.target.value)}
      >
        <Radio.Button value="creation_date">
          {t(`header.menu.filteredTab.creationDateOrders`)}
        </Radio.Button>
        <Radio.Button
          value="incident_date"
          disabled={disabledIncidentDateRange}
        >
          {t(`header.menu.filteredTab.incidentTicketsDate`)}
        </Radio.Button>
        <Radio.Button value="compensation_reception_date">
          {t(`header.menu.filteredTab.compensation_reception_dateTickets`)}
        </Radio.Button>
        <Radio.Button value="ready_date">
          {t(`header.menu.filteredTab.readyDateTickets`)}
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

DatePickerFooter.propTypes = {
  dateField: PropTypes.string.isRequired,
  handleDateFieldChange: PropTypes.func.isRequired,
  disabledIncidentDateRange: PropTypes.bool.isRequired
};

export default DatePickerFooter;
