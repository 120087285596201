import React from 'react';
import { Button } from 'antd';
import { useStateWithLocalStorage } from '../../utils';
import leaseticLogo from '../../assets/images/leasetic vert.png';
import useFiltersContext from './context/DashboardContext';

const OwnerSelect = () => {
  const {
    filters: { owner },
    dispatchFilters
  } = useFiltersContext();
  const [theme] = useStateWithLocalStorage('theme', {
    mainColor: '#001529',
    themeLogo: null
  });

  const ownerLogos = {
    other: theme.themeLogo,
    leasetic: leaseticLogo
  };

  return (
    <div style={{ marginBottom: 8 }}>
      {Object.entries(ownerLogos).map(([p, logo]) => (
        <Button
          key={p}
          type="link"
          style={{
            padding: 0
          }}
          onClick={() =>
            dispatchFilters({ type: 'add', payload: { owner: p } })
          }
        >
          <img
            src={logo}
            alt="owner logo"
            style={{
              height: 40,
              margin: '0 8px',
              opacity: owner && owner !== p ? 0.5 : 1,
              boxShadow: owner && owner === p ? '0 0 10px' : ''
            }}
          />
        </Button>
      ))}
    </div>
  );
};

export default OwnerSelect;
