import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Axis, Chart, Geom, Tooltip } from 'bizcharts';
import DataSet from '@antv/data-set';
import { Empty, Icon } from 'antd';
import numeral from 'numeral';
import InvoiceModal from './InvoiceModal';

const titlesConfig = {
  offset: -2,
  textStyle: {
    fill: 'rgba(0,0,0,0.6)',
    stroke: 'rgba(0,0,0,0.3)'
  }
};

const ConsumptionHistory = ({ invoices }) => {
  const { t } = useTranslation();
  const [id, setId] = useState(null);
  const [visible, setVisible] = useState(false);

  const ds = new DataSet();
  const dv = ds
    .createView()
    .source(
      invoices.sort((a, b) => moment(a.date).diff(moment(b.date))).slice(-12) ||
        []
    );
  dv.transform({
    type: 'fold',
    key: 'overage',
    value: 'cost',
    fields: [
      'overage_data_international_amount',
      'overage_voice_special_amount',
      'overage_voice_international_amount',
      'overage_message_international_amount',
      'overage_messages_special_amount'
    ]
  });

  const cols = {
    date: {
      formatter: (date) => moment(date).format('M/YYYY')
    },
    amount: {
      alias: t('home.dash.legends.amount'),
      formatter: (v) => `${numeral(v).format('0,0')}€`
    },
    cost: {
      alias: t('home.dash.legends.overage'),
      formatter: (v) => `${numeral(v).format('0,0.00')}€`
    }
  };

  const openModal = (e) => {
    let invoiceId;
    if (e.data && e.data._origin && e.data._origin._id)
      invoiceId = e.data._origin._id;
    if (invoiceId) {
      setId(invoiceId);
      setVisible(true);
    }
  };

  return (
    <>
      <InvoiceModal visible={visible} id={id} setVisible={setVisible} />
      <Chart
        data={dv}
        height={200}
        forceFit
        padding={[20, 60, 50, 70]}
        onClick={(e) => openModal(e)}
        scale={cols}
        placeholder={
          <Empty
            style={{ height: '100%' }}
            description={t('home.nodata')}
            imageStyle={{ height: '90%' }}
            image={
              <Icon
                style={{ fontSize: 150, color: '#E0E0E0' }}
                type="bar-chart"
              />
            }
          />
        }
      >
        <Tooltip crosshairs={{ type: 'y', position: 'top' }} />
        <Axis name="date" />
        <Axis name="cost" title={titlesConfig} />
        <Axis name="amount" position="right" title={titlesConfig} />
        <Geom
          opacity={1}
          type="intervalStack"
          color={['overage']}
          position="date*cost"
          tooltip={[
            'overage*cost',
            (c, v) => ({
              name: t(`home.dash.legends.${c}`),
              value: `${numeral(v).format('0,0.00')}€`
            })
          ]}
        />
        <Geom
          opacity={1}
          type="line"
          color="#1a1a1a"
          position="date*amount"
          tooltip={[
            'amount',
            (v) => ({
              name: t(`home.dash.legends.amount`),
              value: `${numeral(v).format('0,0.00')}€`
            })
          ]}
        />
      </Chart>
    </>
  );
};

ConsumptionHistory.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({}))
};

ConsumptionHistory.defaultProps = {
  invoices: []
};

export default ConsumptionHistory;
