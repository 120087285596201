import React from 'react';
import { DatePicker, Form, Icon, Input, Select, Tag } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import procedureSinistre from '../../assets/images/PROCEDURE SINISTRE DEF.pdf';
import {
  ticketPriorityColors,
  ticketStatusColors,
  ticketTypeColors
} from '../../utils';
import ContactForm from './ContactForm';
import IncidentDetailsForm from './IncidentDetailsForm';
import SiteForm from './SiteForm';
import { ContentCustom } from '../../components';

const { Option } = Select;
const BaseTicketForm = ({
  ticketType,
  ticketsEnumValues,
  getFieldDecorator,
  isSitesLoading,
  getCompanySites,
  companySites,
  siteId,
  isPeopleLoading,
  getCompanyPeople,
  companyPeople,
  peopleId,
  setFieldsValue
}) => {
  const { t } = useTranslation();
  return (
    <ContentCustom>
      <Form.Item label={t('tickets.fields.label')}>
        {getFieldDecorator('label', {
          rules: [{ required: true, message: t('global.form.requiredMessage') }]
        })(<Input />)}
      </Form.Item>
      <Form.Item
        label={t('tickets.fields.type')}
        extra={
          (ticketType === 'LOST' ||
            ticketType === 'THEFT' ||
            ticketType === 'DAMAGE') && (
            <>
              <Icon
                type="warning"
                style={{
                  fontSize: '16px',
                  marginRight: '10px',
                  color: '#fadb14'
                }}
              />
              <a
                style={{ fontSize: 12 }}
                href={procedureSinistre}
                download="procedure-sinistre.pdf"
              >
                Procédure sinistre
              </a>
            </>
          )
        }
      >
        {getFieldDecorator('type', {
          rules: [{ required: true, message: t('global.form.requiredMessage') }]
        })(
          <Select>
            {ticketsEnumValues &&
              ticketsEnumValues.type.map((type) => (
                <Option value={type} key={type}>
                  <Tag color={ticketTypeColors[type]}>
                    {t(`tickets.options.${type}`)}
                  </Tag>
                </Option>
              ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('tickets.fields.priority')}>
        {getFieldDecorator('priority', {
          rules: [{ required: true, message: t('global.form.requiredMessage') }]
        })(
          <Select>
            {ticketsEnumValues &&
              ticketsEnumValues.priority.map((priority) => (
                <Option value={priority} key={priority}>
                  <Tag color={ticketPriorityColors[priority]}>
                    {t(`tickets.options.${priority}`)}
                  </Tag>
                </Option>
              ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('tickets.fields.status')}>
        {getFieldDecorator('status', {
          rules: [{ required: true, message: t('global.form.requiredMessage') }]
        })(
          <Select>
            {ticketsEnumValues &&
              ticketsEnumValues.status.map((status) => (
                <Option value={status} key={status}>
                  <Tag color={ticketStatusColors[status]}>
                    {t(`tickets.options.${status}`)}
                  </Tag>
                </Option>
              ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        label={t('tickets.fields.readyDate')}
        style={{ display: ticketType === 'COLLECTION' ? 'block' : 'none' }}
      >
        {getFieldDecorator('ready_date', {
          rules: [
            {
              required: ticketType === 'COLLECTION',
              message: t('global.form.requiredMessage')
            },
            { type: 'object', message: t('global.form.typeMessage') }
          ]
        })(<DatePicker />)}
      </Form.Item>
      <SiteForm
        ticketType={ticketType}
        getFieldDecorator={getFieldDecorator}
        isSitesLoading={isSitesLoading}
        getCompanySites={getCompanySites}
        companySites={companySites}
        siteId={siteId}
      />
      <IncidentDetailsForm
        ticketType={ticketType}
        getFieldDecorator={getFieldDecorator}
        isPeopleLoading={isPeopleLoading}
        getCompanyPeople={getCompanyPeople}
        companyPeople={companyPeople}
      />
      <ContactForm
        peopleId={peopleId}
        getFieldDecorator={getFieldDecorator}
        isPeopleLoading={isPeopleLoading}
        getCompanyPeople={getCompanyPeople}
        companyPeople={companyPeople}
        setFieldsValue={setFieldsValue}
      />
    </ContentCustom>
  );
};

BaseTicketForm.propTypes = {
  ticketType: PropTypes.string.isRequired,
  ticketsEnumValues: PropTypes.shape({
    type: PropTypes.arrayOf(PropTypes.string),
    priority: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  isSitesLoading: PropTypes.bool.isRequired,
  getCompanySites: PropTypes.func.isRequired,
  companySites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  siteId: PropTypes.string.isRequired,
  isPeopleLoading: PropTypes.bool.isRequired,
  getCompanyPeople: PropTypes.func.isRequired,
  companyPeople: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  peopleId: PropTypes.string.isRequired,
  setFieldsValue: PropTypes.func.isRequired
};

export default BaseTicketForm;
