import React, { memo, useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker
} from 'react-simple-maps';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { blue } from '@ant-design/colors';

const geoUrl = '/france.json';

const MapFrance = ({
  showLabel,
  showTooltip,
  markers,
  selectedZones,
  onTooltipMarker,
  onClickMarker,
  onClickZone,
  disabledHover,
  disabledPress,
  colors: { default: defaultColor, selected }
}) => {
  const [tooltip, setTooltip] = useState('');

  const getSelectedZone = (region) => {
    if (selectedZones) {
      const zone = selectedZones.find((z) => z.name === region);

      if (zone) return zone.color ? zone.color : selected;
    }
    return defaultColor;
  };

  const getTooltips = (region) => {
    if (selectedZones) {
      const zone = selectedZones.find((z) => z.name === region);

      if (zone) return zone.tooltip ? zone.tooltip : '';
    }
    return '';
  };

  return (
    <div style={{ marginTop: 24 }}>
      <ComposableMap data-tip="">
        <ZoomableGroup
          center={[2.2137, 46.2276]}
          maxZoom={50}
          zoom={20}
          minZoom={20}
          disablePanning
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo, idx) => {
                const color = getSelectedZone(geo.properties.nom);
                const tT = getTooltips(geo.properties.nom);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onClick={() => onClickZone && onClickZone(geo, idx)}
                    onMouseEnter={() => {
                      if (tT) setTooltip(tT);
                      else setTooltip('');
                    }}
                    onMouseLeave={() => setTooltip('')}
                    style={{
                      default: {
                        fill: color,
                        outline: 'none'
                      },
                      hover: {
                        fill: color,
                        opacity: disabledHover ? 1 : 0.7,
                        outline: 'none'
                      },
                      pressed: {
                        fill: color,
                        opacity: disabledPress ? 1 : 0.7,
                        outline: 'none'
                      }
                    }}
                  />
                );
              })
            }
          </Geographies>
          {markers &&
            markers.map((marker, idx) => {
              const { name, stock, fontSize, stroke, fill, coordinates, r } = {
                r: 0.5,
                fontSize: 1.5,
                fill: blue.primary,
                stroke: '#fff',
                ...marker
              };
              const markerOffset = r + fontSize - 0.2;

              return (
                <Marker key={name || idx} coordinates={coordinates || []}>
                  <circle
                    r={r}
                    fill="#7FD4B4"
                    stroke={stroke}
                    strokeWidth={0.1}
                    onMouseEnter={() =>
                      onTooltipMarker
                        ? setTooltip(onTooltipMarker(marker))
                        : setTooltip(`${name}: ${stock}`)
                    }
                    onMouseLeave={() => setTooltip('')}
                    onClick={() => onClickMarker && onClickMarker(marker, idx)}
                    style={{ cursor: onClickMarker ? 'pointer' : 'default' }}
                  />
                  {showLabel && (
                    <text
                      textAnchor="middle"
                      y={markerOffset}
                      style={{
                        fontFamily: 'system-ui',
                        fill: '#5D5A6D',
                        fontSize,
                        cursor: 'default'
                      }}
                    >
                      {name}
                    </text>
                  )}
                </Marker>
              );
            })}
        </ZoomableGroup>
      </ComposableMap>
      {showTooltip && <ReactTooltip>{tooltip}</ReactTooltip>}
    </div>
  );
};

MapFrance.propTypes = {
  showLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.number),
      name: PropTypes.string,
      r: PropTypes.number,
      fill: PropTypes.string,
      stroke: PropTypes.string
    })
  ),
  selectedZones: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, color: PropTypes.string })
  ),
  onTooltipMarker: PropTypes.func,
  onClickMarker: PropTypes.func,
  onClickZone: PropTypes.func,
  colors: PropTypes.shape({
    default: PropTypes.string,
    hover: PropTypes.string,
    pressed: PropTypes.string,
    selected: PropTypes.string
  }),
  disabledPress: PropTypes.bool,
  disabledHover: PropTypes.bool
};

MapFrance.defaultProps = {
  showLabel: true,
  showTooltip: true,
  markers: null,
  selectedZones: null,
  onTooltipMarker: null,
  onClickMarker: null,
  onClickZone: null,
  colors: {
    default: '#d9d9d9',
    hover: blue[3],
    pressed: blue[4],
    selected: blue[4]
  },
  disabledPress: false,
  disabledHover: false
};

export default memo(MapFrance);
