import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Typography } from 'antd';
import Indicator from './miniDashs/Indicator';
import ChartDash from './dashs/ChartDash';
import RACITable from './RACITable';
import MiniDashDisplayContainer from './displayContainers/miniDashDisplayContainer';
import DashDisplayContainer from './displayContainers/DashDisplayContainer';
import TableDash from './dashs/TableDash';
import TelecomFilters from './TelecomFilters';

const TelecomDashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <TelecomFilters />
      <Divider orientation="center">
        <Typography.Title level={2}>
          {t('dashboard.telecom.consumptions')}
        </Typography.Title>
      </Divider>
      <MiniDashDisplayContainer xxlSpan={8}>
        <Indicator
          purpose={{
            resource: 'telecom',
            endPoint: 'inactive_lines'
          }}
          title={t('home.miniDash.assignment.lineUsage.title')}
        />
        <Indicator
          title={t('home.miniDash.assignment.consumptions.title')}
          purpose={{
            resource: 'telecom',
            endPoint: 'consumptions'
          }}
        />
        <Indicator
          title={t('home.miniDash.assignment.overages.title')}
          purpose={{
            resource: 'telecom',
            endPoint: 'overages'
          }}
          withSwitch
          defaultUnit="amount"
        />
      </MiniDashDisplayContainer>
      <DashDisplayContainer>
        <ChartDash
          title={t('home.dash.histoJoined.title.inactiveLines')}
          purpose={{
            resource: 'telecom',
            endPoint: 'count_unused_line'
          }}
        />
        <ChartDash
          title={t('home.dash.histoJoined.title.dataConsumptionEvolution')}
          purpose={{
            resource: 'telecom',
            endPoint: 'data_consumption_evolution'
          }}
        />
        <ChartDash
          title={t('home.dash.histoJoined.title.voiceConsumptionEvolution')}
          purpose={{
            resource: 'telecom',
            endPoint: 'voice_consumption_evolution'
          }}
        />
      </DashDisplayContainer>
      <Divider orientation="center" style={{ marginTop: 48 }}>
        <Typography.Title level={2}>
          {t('dashboard.telecom.plans')}
        </Typography.Title>
      </Divider>
      <MiniDashDisplayContainer xxlSpan={12}>
        <Indicator
          title={t('home.miniDash.assignment.average_cost_voice_line.title')}
          purpose={{
            resource: 'telecom',
            endPoint: 'average_voice_cost'
          }}
        />
        <Indicator
          title={t(
            'home.miniDash.assignment.average_cost_voiceData_line.title'
          )}
          purpose={{
            resource: 'telecom',
            endPoint: 'average_data_voice_cost'
          }}
        />
        <Indicator
          title={t('home.miniDash.assignment.average_cost_data_line.title')}
          purpose={{
            resource: 'telecom',
            endPoint: 'average_data_cost'
          }}
        />
        <Indicator
          title={t('home.miniDash.assignment.average_cost_landline_line.title')}
          purpose={{
            resource: 'telecom',
            endPoint: 'average_landlines_cost'
          }}
        />
      </MiniDashDisplayContainer>
      <DashDisplayContainer>
        <TableDash
          title={t('home.miniDash.table.costByPhoneNumber.title')}
          scroll={{ y: 380 }}
          fixedProvider="SFR"
          purpose={{
            resource: 'telecom',
            endPoint: 'cost_by_contract'
          }}
        />
        <TableDash
          title={t('home.miniDash.table.costByPhoneNumber.title')}
          scroll={{ y: 380 }}
          fixedProvider="ORANGE"
          purpose={{
            resource: 'telecom',
            endPoint: 'cost_by_contract'
          }}
        />
        <TableDash
          title={t('home.miniDash.table.costByPhoneNumber.title')}
          scroll={{ y: 380 }}
          fixedProvider="BOUYGUES"
          purpose={{
            resource: 'telecom',
            endPoint: 'cost_by_contract'
          }}
        />
      </DashDisplayContainer>
      <Divider orientation="center" style={{ marginTop: 48 }}>
        <Typography.Title level={2}>
          {t('dashboard.telecom.billing')}
        </Typography.Title>
      </Divider>
      <MiniDashDisplayContainer xxlSpan={12}>
        <Indicator
          title={t('home.miniDash.assignment.total_cost_contract.title')}
          purpose={{
            resource: 'telecom',
            endPoint: 'total_contracts_amount'
          }}
        />
        <Indicator
          title={t('home.miniDash.assignment.total_credit.title')}
          purpose={{ resource: 'telecom', endPoint: 'total_credits' }}
        />
        <Indicator
          title={t('home.miniDash.assignment.total_services_cost.title')}
          purpose={{ resource: 'telecom', endPoint: 'total_services_cost' }}
        />
        <Indicator
          purpose={{ resource: 'telecom', endPoint: 'total_overages_cost' }}
          title={t('home.miniDash.assignment.invoices.title')}
        />
      </MiniDashDisplayContainer>
      <DashDisplayContainer>
        <ChartDash
          title={t('home.dash.histoJoined.title.totalCostTelecom')}
          purpose={{ resource: 'telecom', endPoint: 'monthly_costs' }}
        />
        <ChartDash
          title={t('home.dash.histoJoined.title.fixedVsVariableCost')}
          purpose={{ resource: 'telecom', endPoint: 'fixed_variable_cost' }}
        />
        <ChartDash
          purpose={{ resource: 'telecom', endPoint: 'overages_evolution' }}
          title={t('home.dash.overages_evolution.title')}
        />
      </DashDisplayContainer>
      <Divider orientation="center" style={{ marginTop: 48 }}>
        <Typography.Title level={2}>
          {t('dashboard.telecom.overages')}
        </Typography.Title>
      </Divider>
      <MiniDashDisplayContainer xxlSpan={12}>
        <Indicator
          title={t(
            'home.miniDash.assignment.voice_overage_special_numbers.title'
          )}
          purpose={{
            resource: 'telecom',
            endPoint: 'voice_overage_special_numbers'
          }}
        />
        <Indicator
          title={t(
            'home.miniDash.assignment.messages_overage_special_numbers.title'
          )}
          purpose={{
            resource: 'telecom',
            endPoint: 'messages_overage_special_numbers'
          }}
        />
        <Indicator
          title={t(
            'home.miniDash.assignment.voice_overage_international.title'
          )}
          purpose={{
            resource: 'telecom',
            endPoint: 'voice_overage_international'
          }}
        />
        <Indicator
          title={t('home.miniDash.assignment.data_overage_international.title')}
          purpose={{
            resource: 'telecom',
            endPoint: 'data_overage_international'
          }}
        />
      </MiniDashDisplayContainer>
      <DashDisplayContainer>
        <TableDash
          purpose={{ resource: 'telecom', endPoint: 'top_overage' }}
          scroll={{ y: 350 }}
          title={t('home.miniDash.table.rankOverrun.title')}
        />
        <ChartDash
          purpose={{ resource: 'telecom', endPoint: 'top_overage_data' }}
          title={t('home.dash.top_overage_data.title')}
          transposed
          withSwitch
          defaultUnit="amount"
        />
        <ChartDash
          purpose={{ resource: 'telecom', endPoint: 'top_overage_voice' }}
          title={t('home.dash.top_overage_voice.title')}
          transposed
          withSwitch
          defaultUnit="amount"
        />
      </DashDisplayContainer>
      <Row style={{ marginTop: '50px' }}>
        <RACITable data={[]} />
      </Row>
    </>
  );
};

export default TelecomDashboard;
