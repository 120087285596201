import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';

const { Column } = Table;

const InvoicesTab = ({ invoicesData, history }) => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });

  const handlePageChange = (page) => {
    setPagination(page);
  };

  useEffect(() => {
    if (invoicesData && invoicesData.length) {
      setInvoices(
        // eslint-disable-next-line no-underscore-dangle
        invoicesData.map((invoice) => ({ ...invoice, key: invoice._id }))
      );
      setPagination({ ...pagination, total: invoicesData.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesData]);

  return (
    <>
      <Table
        rowClassName="rowStyle"
        onRow={({ key }) => ({
          onClick: () => history.push(`/invoices/show/${key}`)
        })}
        dataSource={invoices}
        onChange={handlePageChange}
        pagination={pagination}
      >
        <Column
          title={t('invoices.list.column.number')}
          key="number"
          render={(invoice) => <Typography>{invoice.number}</Typography>}
        />
        <Column
          title={t('invoices.list.column.payment_date')}
          key="payment_date"
          render={(invoice) => (
            <Typography>
              {invoice.payment_date &&
                moment(invoice.payment_date).format('LL')}
            </Typography>
          )}
        />
        <Column
          title={t('invoices.list.column.total')}
          key="total"
          align="right"
          render={(invoice) => (
            <Typography>
              {`${numeral(invoice.total).format('0,0.00')} €`}
            </Typography>
          )}
        />
      </Table>
    </>
  );
};

InvoicesTab.defaultProps = {
  invoicesData: null
};

InvoicesTab.propTypes = {
  invoicesData: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default InvoicesTab;
