import React, { createContext, useContext, useReducer } from 'react';
import moment from 'moment';
import timeReducer from './timeReducer';
import { filtersReducer, filtersInitialState } from './filtersReducer';

const FiltersContext = createContext({});

export const DashboardContextProvider = ({ children }) => {
  const [timeScale, dispatchTime] = useReducer(timeReducer, [
    moment()
      .subtract(1, 'year')
      .startOf('month'),
    moment()
      .subtract(1, 'month')
      .endOf('month')
  ]);
  const [filters, dispatchFilters] = useReducer(
    filtersReducer,
    JSON.parse(sessionStorage.getItem('filters')) || filtersInitialState
  );

  return (
    <FiltersContext.Provider
      value={{ timeScale, dispatchTime, filters, dispatchFilters }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default () => useContext(FiltersContext);
