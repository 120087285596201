/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Checkbox,
  Typography,
  DatePicker,
  Tree,
  Spin,
  Button,
  Drawer,
  Divider,
  Badge
} from 'antd';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useFiltersContext from './context/DashboardContext';
import ProviderSelect from './ProviderSelect';
import { useStateWithSessionStorage } from '../../utils';

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { TreeNode } = Tree;

const TelecomFilters = () => {
  const { user, dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const {
    filters,
    dispatchFilters,
    timeScale,
    dispatchTime
  } = useFiltersContext();
  const { provider, device, subsidiaries: sub, accessTypes: aT } = filters;
  const [payload, setPayload] = useState({});
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [buttonValue, setButtonValue] = useStateWithSessionStorage(
    'timeScaleButtonKey'
  );
  const [pickerValue, setPickerValue] = useStateWithSessionStorage(
    'timeScalePickerValue'
  );
  const checkIfFilterActive = !!(
    provider ||
    device.length ||
    sub.length ||
    aT.length
  );

  const fetchSubsidiaries = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/subsidiaries/tree-organization?company=${user.company}`
      });
      if (data) setSubsidiaries([data]);
      else setSubsidiaries([]);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSubsidiaries();
  }, [fetchSubsidiaries]);

  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            title={<Typography href="">{item.title}</Typography>}
            key={item.key}
            dataRef={item}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          title={<Typography href="">{item.title}</Typography>}
          key={item.key}
          dataRef={item}
        />
      );
    });

  const applyFilters = () => {
    dispatchFilters({ type: 'add', payload });
  };

  const resetFilters = () => {
    setPayload({});
    dispatchFilters({ type: 'reset' });
  };

  const handleRangeChange = (date) => {
    dispatchTime({ initiator: 'picker', payload: date });
    setPickerValue(date);
    setButtonValue(null);
  };

  useEffect(() => {
    if (!buttonValue && pickerValue)
      dispatchTime({
        initiator: 'picker',
        payload: [moment(pickerValue[0]), moment(pickerValue[1])]
      });
    setPayload({
      ...payload,
      ...(sub ? { subsidiaries: sub } : {}),
      ...(device ? { device } : {})
    });
  }, []);

  return (
    <div>
      <Badge
        dot={checkIfFilterActive}
        style={{
          position: 'fixed',
          top: '35%',
          right: 40,
          width: 10,
          height: 10
        }}
      >
        <Button
          type="primary"
          onClick={() => setVisible(true)}
          size="large"
          icon="setting"
          style={{
            position: 'fixed',
            top: '35%',
            right: 0,
            fontSize: 26,
            borderRightColor: 'none',
            borderRadius: '8px 0 0 8px',
            boxShadow: '-3px 0 3px inset rgba(0, 0, 0, 0.2)',
            zIndex: 100
          }}
        />
      </Badge>
      <Drawer
        className="options-drawer"
        drawerStyle={{ overflowX: 'hidden' }}
        title="Options"
        destroyOnClose
        width="300"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <Paragraph strong>
          {t('dashboard.telecomFilters.providerTitle')}
        </Paragraph>
        <Row
          style={{
            margin: '0 -25px 8px -25px',
            padding: '8px 25px 8px 25px',
            backgroundColor: 'rgba(0,0,0,0.1)'
          }}
        >
          <ProviderSelect />
        </Row>
        <Divider />
        <Paragraph strong>
          {t('dashboard.telecomFilters.pickerTitle')}
        </Paragraph>
        <Row style={{ marginBottom: 8 }}>
          <RangePicker
            allowClear={false}
            value={timeScale}
            onChange={handleRangeChange}
          />
        </Row>
        <Divider />
        <div style={{ width: '100%', marginBottom: 16, textAlign: 'center' }}>
          <Button onClick={applyFilters} type="primary">
            {t('dashboard.ITFilters.filterButton')}
          </Button>
          <Button onClick={resetFilters} type="link" icon="undo" />
        </div>
        <Paragraph style={{ marginTop: 16 }} strong>
          {t('dashboard.telecomFilters.deviceTitle')}
        </Paragraph>
        <Checkbox.Group
          style={{ width: '100%', color: 'white' }}
          onChange={(value) => setPayload({ ...payload, device: value })}
          value={payload.device}
        >
          <Row>
            {[
              'SMARTPHONE',
              'SMARTPHONE_DATA',
              'SMARTPHONE_VOICE',
              'DATA',
              'M2M',
              'INTERNET',
              'LANDLINE'
            ].map((d) => (
              <Col span={24} key={d}>
                <Checkbox value={d}>
                  {t(`dashboard.telecomFilters.${d}`)}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
        <Divider />
        <Paragraph style={{ marginTop: 16 }} strong>
          {t('dashboard.telecomFilters.subsidiariesTitle')}
        </Paragraph>
        <Spin spinning={isLoading} size="large">
          {subsidiaries.length ? (
            <Tree
              checkable
              checkedKeys={payload.subsidiaries}
              onCheck={(keys) => setPayload({ ...payload, subsidiaries: keys })}
            >
              {renderTreeNodes(subsidiaries)}
            </Tree>
          ) : (
            t('dashboard.ITFilters.noSubsidiaries')
          )}
        </Spin>
      </Drawer>
    </div>
  );
};

export default TelecomFilters;
