import React from 'react';
import { Col, Row, Skeleton, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { invoiceTypeColors } from '../../utils';

const { Title } = Typography;
const InvoiceDetails = ({ invoice, isLoading, order_number, type }) => {
  const { t } = useTranslation();
  const {
    from,
    to,
    contract,
    emission_date,
    label,
    delivery_date,
    order_date,
    pv_number,
    pv_date
  } = invoice;
  return (
    <>
      <Row>
        <Col span={12}>
          <Skeleton active loading={isLoading}>
            <Title level={3}>
              {t('invoices.showInvoice.invoiceNumber', {
                number: invoice.number
              })}
            </Title>
            <Row style={{ margin: '30px 0', fontWeight: 'bold' }}>
              {from && (
                <Typography style={{ fontSize: '18px' }}>
                  {`${from.name} - ${from.group}`}
                  <br />
                  {`${from.address.number} ${from.address.street}`}
                  <br />
                  {from.address.additional}
                  {from.address.additional && <br />}
                  {`${from.address.postal_code} ${from.address.city}`}
                </Typography>
              )}
            </Row>
          </Skeleton>
        </Col>
      </Row>
      <Row style={{ margin: '30px 0' }}>
        <Col offset={1} span={13}>
          <div>
            <Skeleton
              active
              loading={isLoading}
              title={{ width: '20%' }}
              paragraph={0}
            >
              <Typography.Text>
                {emission_date &&
                  t('invoices.showInvoice.emissionDate', {
                    date: moment(emission_date).format('LL')
                  })}
                <br />
                {label && <Typography.Text strong>{label}</Typography.Text>}
              </Typography.Text>
            </Skeleton>
          </div>
          <div
            style={{
              marginTop: 16,
              borderLeft: '2px solid rgba(0, 0, 0, 0.1)',
              paddingLeft: 4,
              marginLeft: -4
            }}
          >
            <Skeleton active loading={isLoading} paragraph={{ rows: 3 }}>
              {contract && (
                <Typography.Text>
                  {t('invoices.showInvoice.contract', {
                    number: contract.amendment_number
                      ? `${contract.number}-${contract.amendment_number}`
                      : contract.number
                  })}
                  <br />
                </Typography.Text>
              )}
              {type && (
                <Typography.Text>
                  {`Type: `}
                  <Tag color={invoiceTypeColors[type]}>
                    {t(`invoices.tags.${type}`)}
                  </Tag>
                  <br />
                </Typography.Text>
              )}
              {order_number && (
                <Typography.Text>
                  {t('invoices.showInvoice.order_number', { order_number })}
                  <br />
                </Typography.Text>
              )}
              {order_date && (
                <Typography.Text>
                  {t('invoices.showInvoice.order_date', {
                    order_date: moment(order_date).format('L')
                  })}
                  <br />
                </Typography.Text>
              )}
              {delivery_date && (
                <Typography.Text>
                  {t('invoices.showInvoice.delivery_date', {
                    delivery_date: moment(delivery_date).format('L')
                  })}
                  <br />
                </Typography.Text>
              )}
              {pv_number && (
                <Typography.Text>
                  {t('invoices.showInvoice.pv_number', { pv_number })}
                  <br />
                </Typography.Text>
              )}
              {pv_date && (
                <Typography.Text>
                  {t('invoices.showInvoice.pv_date', {
                    pv_date: moment(pv_date).format('L')
                  })}
                  <br />
                </Typography.Text>
              )}
            </Skeleton>
          </div>
        </Col>
        <Col span={10}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
            {to && (
              <Typography.Text style={{ fontSize: '18px' }}>
                {`${to.name} - ${to.group}`}
                <br />
                {`${to.address.number} ${to.address.street}`}
                <br />
                {to.address.additional}
                {to.address.additional && <br />}
                {`${to.address.postal_code} ${to.address.city}`}
              </Typography.Text>
            )}
          </Skeleton>
        </Col>
      </Row>
    </>
  );
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.shape({
    pv_number: PropTypes.string,
    label: PropTypes.string,
    pv_date: PropTypes.string,
    emission_date: PropTypes.string,
    delivery_date: PropTypes.string,
    order_date: PropTypes.string,
    number: PropTypes.string,
    from: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
      address: PropTypes.shape({
        additional: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        number: PropTypes.number,
        street: PropTypes.string,
        postal_code: PropTypes.string
      })
    }),
    to: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
      address: PropTypes.shape({
        additional: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        number: PropTypes.number,
        street: PropTypes.string,
        postal_code: PropTypes.string
      })
    }),
    contract: PropTypes.shape({
      number: PropTypes.string,
      amendment_number: PropTypes.string
    })
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  order_number: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
};

export default InvoiceDetails;
