import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table, Tag, Typography } from 'antd';
import { raciStatusColor } from '../../utils';

const { Column } = Table;
const { Title } = Typography;

const RaciTable = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Table
      title={() => <Title level={4}>RACI</Title>}
      dataSource={data}
      rowKey="id"
      scroll={{ x: window.innerWidth > 576 }}
    >
      <Column
        title={t('companies.showCompany.contactList.column.subject')}
        dataIndex="subject"
        render={(subject) => subject}
      />
      <Column
        title={t('companies.showCompany.contactList.column.customerAction')}
        key="customerAction"
        render={({ customerAction, email }) => (
          <a href={`mailto:${email}`}>{customerAction}</a>
        )}
      />
      <Column
        title={t('companies.showCompany.contactList.column.leaseticAction')}
        key="leaseticAction"
        render={({ leaseticAction, leaseticEmail }) => (
          <a href={`mailto:${leaseticEmail}`}>{leaseticAction}</a>
        )}
      />
      <Column
        title={t('companies.showCompany.contactList.column.startDate')}
        dataIndex="startDate"
        render={(startDate) => startDate}
      />
      <Column
        title={t('companies.showCompany.contactList.column.endDate')}
        dataIndex="endDate"
        render={(endDate) => endDate}
      />
      <Column
        title={t('companies.showCompany.contactList.column.status')}
        dataIndex="status"
        render={(status) => (
          <Tag color={raciStatusColor[status]}>{t(`raci.tags.${status}`)}</Tag>
        )}
      />
      <Column
        title={t('companies.showCompany.contactList.column.informed')}
        dataIndex="informed"
        render={(informed) => informed}
      />
    </Table>
  );
};

RaciTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

RaciTable.defaultProps = {
  data: []
};

export default RaciTable;
