import { DatePicker, Form, Input, InputNumber, Radio, Select } from 'antd';
import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { RangePicker } = DatePicker;

const InputGenerator = ({
  title,
  fields,
  getFieldDecorator,
  extra,
  translation
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {fields.map(
        (item) =>
          ((item.input === 'rangePicker' || item.input === 'datePicker') && (
            <Form.Item
              label={
                translation ? t(`${title}.form.${item.label}`) : item.label
              }
              key={item.label}
            >
              {getFieldDecorator(item.label, item.config)(
                (item.input === 'rangePicker' && <RangePicker />) ||
                  (item.input === 'datePicker' && (
                    <DatePicker disabled={item.disabled} />
                  ))
              )}
            </Form.Item>
          )) || (
            <Form.Item
              label={
                translation ? t(`${title}.form.${item.label}`) : item.label
              }
              key={item.label}
            >
              {getFieldDecorator(item.label, {
                initialValue: item.initialValue,
                rules: [
                  {
                    type: item.type || 'string',
                    message: translation
                      ? t(`global.form.typeMessage`)
                      : item.label
                  },
                  {
                    required: item.required,
                    message: translation
                      ? t(`global.form.requiredMessage`)
                      : item.label
                  }
                ]
              })(
                (item.input === 'number' && <InputNumber />) ||
                  (item.input === 'radio' && (
                    <Radio.Group>
                      {item.buttons.map((button) => (
                        <Radio.Button value={button.value} key={button.value}>
                          {button.text}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  )) ||
                  (item.input === 'select' && (
                    <Select disabled={!!item.initialValue}>
                      {item.options.map((option) => (
                        <Option value={option.value} key={option.value}>
                          {option.text}
                        </Option>
                      ))}
                    </Select>
                  )) ||
                  (item.addOn && <Input addonBefore={item.addOn} />) || (
                    <Input />
                  )
              )}
              {(item.extra && extra) || ''}
            </Form.Item>
          )
      )}
    </div>
  );
};

InputGenerator.defaultProps = {
  title: null,
  extra: null,
  translation: true
};

InputGenerator.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(shape({})).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  extra: PropTypes.shape(),
  translation: PropTypes.bool
};

export default InputGenerator;
