import React from 'react';
import { Dropdown } from 'antd';
import PropTypes from 'prop-types';

const HeaderDropdown = ({ children, overlayMenu }) => {
  return (
    <Dropdown className="dropdown-container-wrapper" overlay={overlayMenu}>
      {children}
    </Dropdown>
  );
};

HeaderDropdown.propTypes = {
  overlayMenu: PropTypes.node.isRequired
};

export default HeaderDropdown;
