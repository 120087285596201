import React from 'react';
import { Col, DatePicker, Select, Tooltip, Icon, InputNumber } from 'antd';
import PropTypes from 'prop-types';

const { RangePicker } = DatePicker;
const { Option } = Select;
export const Filters = ({
  rate,
  absoluteOverage,
  handleRangeChange,
  range,
  setRate,
  setAbsoluteOverage
}) => {
  let timeout;
  const handleAbsoluteValueChange = (value) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => setAbsoluteOverage(value), 1000);
  };
  return (
    <Col xs={{ span: 24 }} md={{ span: 14 }}>
      <RangePicker
        onChange={handleRangeChange}
        style={{ marginRight: 8, width: 250 }}
        value={range}
        format="DD-MM-YYYY"
      />
      <Select
        style={{ width: 100 }}
        defaultValue={rate}
        onChange={(v) => setRate(v)}
      >
        <Option value="all">Toutes</Option>
        <Option value={0.1}>10%</Option>
        <Option value={0.2}>20%</Option>
        <Option value={0.3}>30%</Option>
        <Option value={0.4}>40%</Option>
        <Option value={0.5}>50%</Option>
        <Option value={0.6}>60%</Option>
        <Option value={0.7}>70%</Option>
        <Option value={0.8}>80%</Option>
        <Option value={0.9}>90%</Option>
        <Option value={1}>100%</Option>
      </Select>
      <Tooltip title="Ratio hors-forfait" placement="right">
        <Icon
          type="question-circle"
          style={{ verticalAlign: 4, marginLeft: 4 }}
        />
      </Tooltip>
      <InputNumber
        style={{ marginLeft: 8 }}
        value={absoluteOverage}
        onChange={(value) => handleAbsoluteValueChange(value)}
        min={0}
      />
      <Tooltip title="Hors forfait minimum en €" placement="right">
        <Icon
          type="question-circle"
          style={{ verticalAlign: 4, marginLeft: 4 }}
        />
      </Tooltip>
    </Col>
  );
};

Filters.propTypes = {
  rate: PropTypes.number.isRequired,
  absoluteOverage: PropTypes.number.isRequired,
  handleRangeChange: PropTypes.func.isRequired,
  range: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setRate: PropTypes.func.isRequired,
  setAbsoluteOverage: PropTypes.func.isRequired
};

export default Filters;
