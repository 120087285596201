/* eslint-disable no-console */
import { message } from 'antd';

export const noLabelLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 14, offset: 10 },
    xl: { span: 18, offset: 8 }
  }
};
export const defaultPagination = {
  hideOnSinglePage: true,
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true
};
export const defaultFilteredInfo = {
  site: null,
  status: null,
  contract: null,
  serial_number: null
};
export const setSite = (site) => {
  const {
    number,
    street,
    additional,
    postal_code,
    city,
    country
  } = site.address;
  return {
    site: {
      name: site.name,
      address: {
        number,
        street,
        additional,
        postal_code,
        city,
        country
      }
    }
  };
};
export const setPeople = (people) => {
  const { first_name, last_name, email, phone_number } = people;
  return {
    contact: {
      name: `${first_name} ${last_name}`,
      email,
      phone_number
    }
  };
};
export const getTicketsEnumsUtil = async (dispatchAPI, setTicketsEnums) => {
  try {
    const result = await dispatchAPI('GET', { url: '/tickets/enums' });
    setTicketsEnums(result.data);
  } catch (e) {
    console.error(e);
  }
};

export const getAssetsEnumsUtil = async (dispatchAPI, setAssetsEnums) => {
  try {
    const result = await dispatchAPI('GET', { url: '/assets/enums' });
    setAssetsEnums(result.data);
  } catch (e) {
    console.error(e);
  }
};
export const getCompanyPeopleUtil = async (
  searchValue,
  setIsPeopleLoading,
  dispatchAPI,
  user,
  siteName,
  setCompanyPeople
) => {
  setIsPeopleLoading(true);
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/people/${searchValue ? `/search/${searchValue}` : ''}?company=${
        user.company
      }${siteName ? `&site=${siteName}` : ''}&limit=10`
    });
    setCompanyPeople(data);
  } catch (e) {
    console.error(e);
  }
  setIsPeopleLoading(false);
};
export const submitUtil = (
  assets,
  validateFieldsAndScroll,
  setIsLoading,
  dispatchAPI,
  user,
  t,
  history
) => {
  if (assets.length) {
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        setIsLoading(true);
        try {
          await dispatchAPI('POST', {
            url: '/tickets',
            body: { ...values, company: user.company, assets }
          });
          message.success(t('tickets.success'));
          history.push('/tickets');
        } catch (e) {
          message.error(t('tickets.error'));
        }
        setIsLoading(false);
      }
    });
  } else {
    message.error(t('tickets.fields.error.noAssets'));
  }
};

export const getAssetsUtil = async (
  page,
  filters,
  selectMany,
  dispatchAPI,
  setAssets,
  setAllAssets,
  setPagination
) => {
  const { pageSize, current } = page;
  const { status, serial_number, city } = filters;
  let citySearchUrl;
  if (city && city.length) citySearchUrl = `city=${city[0]}&`;

  let serial_numberSearchUrl;
  if (serial_number && serial_number.length)
    serial_numberSearchUrl = `serial_number=${serial_number[0]}&`;

  try {
    let result;
    if (selectMany) {
      result = await dispatchAPI('GET', {
        url: `/assets?type=IT&${citySearchUrl || ''}${
          status ? `status=${status}&` : ''
        }${serial_numberSearchUrl || ''}populate=contract,tickets,people`
      });
      const assetsId = Object.values(result.data).map(({ _id }) => _id);
      setAssets(assetsId);
    } else {
      result = await dispatchAPI('GET', {
        url: `/assets?type=IT&populate=contract,tickets,people&${citySearchUrl ||
          ''}${status ? `status=${status}&` : ''}${serial_numberSearchUrl ||
          ''}limit=${pageSize}&skip=${(current - 1) * pageSize}`
      });
    }
    setAllAssets(result.data.map((asset) => ({ ...asset, key: asset._id })));
    setPagination({
      ...page,
      total: parseInt(result.headers['x-total-count'], 10)
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
