/* eslint-disable import/prefer-default-export */
export const headers = [
  { label: 'number', key: 'number' },
  { label: 'date', key: 'date' },
  { label: 'provider', key: 'provider' },
  { label: 'contract_number', key: 'contract_number' },
  { label: 'device', key: 'device' },
  { label: 'phone_number', key: 'phone_number' },
  { label: 'phone_contract_name', key: 'phone_contract_name' },
  { label: 'amount', key: 'amount' },
  { label: 'percent_overage', key: 'percent_overage' },
  { label: 'consumption_voice_seconds', key: 'consumption_voice_seconds' },
  { label: 'consumption_messages_count', key: 'consumption_messages_count' },
  { label: 'consumption_data_ko', key: 'consumption_data_ko' },
  { label: 'overage_voice_amount', key: 'overage_voice_amount' },
  { label: 'overage_voice_seconds', key: 'overage_voice_seconds' },
  { label: 'overage_messages_amount', key: 'overage_messages_amount' },
  { label: 'overage_messages_count', key: 'overage_messages_count' },
  { label: 'overage_data_amount', key: 'overage_data_amount' },
  { label: 'overage_data_ko', key: 'overage_data_ko' },
  {
    label: 'overage_voice_special_amount',
    key: 'overage_voice_special_amount'
  },
  {
    label: 'overage_voice_special_seconds',
    key: 'overage_voice_special_seconds'
  },
  {
    label: 'overage_messages_special_amount',
    key: 'overage_messages_special_amount'
  },
  {
    label: 'overage_messages_special_count',
    key: 'overage_messages_special_count'
  },
  {
    label: 'overage_voice_international_amount',
    key: 'overage_voice_international_amount'
  },
  {
    label: 'overage_voice_international_seconds',
    key: 'overage_voice_international_seconds'
  },
  {
    label: 'overage_messages_international_amount',
    key: 'overage_messages_international_amount'
  },
  {
    label: 'overage_messages_international_count',
    key: 'overage_messages_international_count'
  },
  {
    label: 'overage_data_international_amount',
    key: 'overage_data_international_amount'
  },
  {
    label: 'overage_data_international_ko',
    key: 'overage_data_international_ko'
  }
];
