import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message, Table, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import DescriptionList from '../../components/descriptionList/DescriptionList';
import list from './informationList';
import ContractsTab from './ContractsTab';
import { ContentCustom, PageHeaderCustom } from '../../components';

const { Column } = Table;

const ShowCompany = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { companyList } = list;
  const [company, setCompany] = useState({
    address: {},
    contact: { address: {}, phone_number: {}, phone_number2: {} }
  });
  const [contactList, setContactList] = useState([]);
  const [contracts, setContracts] = useState(null);

  const getCompany = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${id}?populate=contracts`
      });
      setCompany(data);
      setContracts(data.contracts);
      setLoading(false);
      setContactList(data.contacts);
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await getCompany();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('companies.showCompany.title')}
        pathOverride={{
          name: t('companies.title'),
          path: '/leaser'
        }}
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active paragraph={{ rows: 4 }}>
          <DescriptionList data={companyList(company, t)} />
        </Skeleton>
      </ContentCustom>
      <ContentCustom marginTop={8}>
        <Typography.Title level={4}>
          {t('companies.showCompany.contactList.title')}
        </Typography.Title>
        <Skeleton
          loading={isLoading}
          active
          title={0}
          paragraph={{
            rows: 8,
            width: [
              '100%',
              '100%',
              '100%',
              '100%',
              '100%',
              '100%',
              '100%',
              '100%'
            ]
          }}
        >
          <Table
            dataSource={
              contactList &&
              contactList.map((contact, idx) => ({ ...contact, key: idx }))
            }
          >
            <Column
              title={t('companies.showCompany.contactList.column.contact')}
              key="contact"
              render={(contact) => `${contact.first_name} ${contact.last_name}`}
            />
            <Column
              title={t('companies.showCompany.contactList.column.function')}
              key="function"
              render={(contact) => contact.function}
            />
            <Column
              title={t('companies.showCompany.contactList.column.phoneNumber')}
              dataIndex="phone_number"
              render={(phone_number) =>
                phone_number
                  ? `${phone_number.country_code} ${phone_number.number.slice(
                      0,
                      1
                    )} ${phone_number.number
                      .slice(1)
                      .replace(/(.{2})/g, '$1 ')}`
                  : '-'
              }
            />
            <Column
              title={t('companies.showCompany.contactList.column.email')}
              dataIndex="email"
              render={(email) => email}
            />
          </Table>
        </Skeleton>
      </ContentCustom>
      <ContentCustom marginTop={8}>
        <Typography.Title level={4}>
          {t('contracts.list.title')}
        </Typography.Title>
        <ContractsTab companyId={id} contractsData={contracts} />
      </ContentCustom>
    </>
  );
};

export default ShowCompany;
