import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { Chart, Axis, Geom, Coord, Guide } from 'bizcharts';

const { Arc, Html } = Guide;

const GaugeBody = ({ indicatorElements, isLoading }) => {
  const { percent } = indicatorElements;

  let value = 3;
  if (percent) {
    if (percent >= 120 && percent <= 125) value = 4.5;
    if (percent >= 110 && percent <= 115) value = 1.5;
    if (percent < 110) value = 0.5;
    if (percent > 125) value = 5.5;
  }

  const data = [{ value }];
  const lineWidth = 8;
  const color = ['#F5222D', '#fa8c16', '#4ecb73'];
  const cols = {
    value: {
      min: 0,
      max: 6,
      tickInterval: 1,
      nice: false
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Chart
        height={90}
        data={data}
        scale={cols}
        padding={[0, 0, 0, 0]}
        forceFit
      >
        <Coord
          type="polar"
          startAngle={(-9 / 8) * Math.PI}
          endAngle={(1 / 8) * Math.PI}
          radius={0.75}
        />
        <Axis
          name="value"
          zIndex={2}
          line={null}
          label={{
            offset: -20,
            textStyle: {
              fontSize: 0,
              fill: '#CBCBCB',
              textAlign: 'center',
              textBaseline: 'middle'
            }
          }}
          tickLine={{
            length: -24,
            stroke: '#fff',
            strokeOpacity: 1
          }}
        />
        <Axis name="1" visible={false} />
        <Guide>
          <Arc
            zIndex={0}
            start={[0, 0.8]}
            end={[1, 0.8]}
            style={{
              // 底灰色
              stroke: color[0],
              lineWidth,
              lineCap: 'round'
            }}
          />
          <Arc
            zIndex={0}
            start={[1, 0.8]}
            end={[2, 0.8]}
            style={{
              // 底灰色
              stroke: color[1],
              lineWidth
            }}
          />
          <Arc
            zIndex={0}
            start={[2, 0.8]}
            end={[4, 0.8]}
            style={{
              // 底灰色
              stroke: color[2],
              lineWidth
            }}
          />
          <Arc
            zIndex={0}
            start={[5, 0.8]}
            end={[6, 0.8]}
            style={{
              // 底灰色
              stroke: color[0],
              lineWidth,
              lineCap: 'round'
            }}
          />
          <Arc
            zIndex={0}
            start={[4, 0.8]}
            end={[5, 0.8]}
            style={{
              // 底灰色
              stroke: color[1],
              lineWidth
            }}
          />
          <Html
            position={['50%', '95%']}
            html={() => {
              return `<div style="width: 300px;text-align: center;font-size: 12px!important;"><h3>${
                percent ? `${percent}%` : ''
              }</h3></div>`;
            }}
          />
        </Guide>
        <Geom
          type="point"
          position="value*1"
          shape="pointer"
          color="#000"
          active={false}
          style={{ stroke: '#fff', lineWidth: 1 }}
        />
      </Chart>
    </Spin>
  );
};

GaugeBody.propTypes = {
  indicatorElements: PropTypes.shape({
    percent: PropTypes.number.isRequired
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default GaugeBody;
