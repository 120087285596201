/* eslint-disable react/jsx-indent */
import React from 'react';
import { Tag } from 'antd';
import numeral from 'numeral';
import { companyTypeColor } from '../../utils';

const companyList = (data, t) => {
  const {
    type,
    name,
    group,
    siren,
    capital,
    legal_status,
    APE,
    address
  } = data;

  return [
    {
      label: t('companies.showCompany.name'),
      span: 1,
      content: name || '-'
    },
    {
      label: t('companies.showCompany.group'),
      span: 1,
      content: group || '-'
    },
    {
      label: t('companies.showCompany.address'),
      span: 3,
      content: `${address.number || '-'} ${address.street || '-'}, ${
        address.additional ? address.additional : ''
      }${address.additional ? ', ' : ''} ${address.postal_code ||
        '-'} ${address.city || '-'}, ${address.country || '-'}`
    },
    {
      label: t('companies.showCompany.type'),
      span: 1,
      content: type
        ? type.map((tp) => (
            <Tag color={companyTypeColor[tp]} key={tp}>
              {t(`companies.form.options.${tp}`).toUpperCase()}
            </Tag>
          ))
        : '-'
    },
    {
      label: t('companies.showCompany.siren'),
      span: 1,
      content: siren ? numeral(siren).format('siren') : '-'
    },
    {
      label: t('companies.showCompany.capital'),
      span: 1,
      content: capital ? `${numeral(capital).format('0,0.00')}€` : '-'
    },
    {
      label: t('companies.showCompany.legalStatus'),
      span: 1,
      content: legal_status || '-'
    },
    {
      label: t('companies.showCompany.APE'),
      span: 2,
      content: APE || '-'
    }
  ];
};

const contactList = (data, t) => {
  const {
    civility,
    first_name,
    last_name,
    address,
    email,
    phone_number,
    phone_number2
  } = data;
  return [
    {
      label: t('users.showUser.civility'),
      span: 1,
      content: civility || '-'
    },
    {
      label: t('users.showUser.firstName'),
      span: 1,
      content: first_name || '-'
    },
    {
      label: t('users.showUser.lastName'),
      span: 1,
      content: last_name || '-'
    },
    {
      label: t('users.showUser.function'),
      span: 3,
      content: data.function || '-'
    },
    {
      label: t('users.showUser.address'),
      span: 3,
      content: `${address.number || '-'} ${address.street || '-'}, ${
        address.additional ? address.additional : ''
      }${address.additional ? ', ' : ''} ${address.postal_code ||
        '-'} ${address.city || '-'}, ${address.country || '-'}`
    },
    {
      label: t('users.showUser.email'),
      span: 1,
      content: email || '-'
    },
    {
      label: t('users.showUser.phoneNumber'),
      span: 1,
      content: `${phone_number.country_code || '-'} ${phone_number.number ||
        '-'}`
    },
    {
      label: t('users.showUser.phoneNumber'),
      span: 1,
      content: `${phone_number2.country_code || '-'} ${phone_number2.number ||
        '-'}`
    }
  ];
};

export default { companyList, contactList };
