import numeral from 'numeral';
import * as moment from 'moment';
import { green, red, yellow } from '@ant-design/colors';

const millisecondsInDay = 86400000;

const formatNumbToTime = (numb) =>
  `${moment
    .duration(numb, 'seconds')
    .asHours()
    .toFixed(1)}h`;

const colorTAAP = (d) => {
  if (d < 1.1) return yellow.primary;
  if (d < 1.2) return green.primary;
  return red.primary;
};

const colorAS = (d) => {
  if (d > 8 && d < 12) return green.primary;
  return red.primary;
};

const colorAL = (d) => {
  if (d < 40) return green.primary;
  if (d < 43) return yellow.primary;
  return red.primary;
};

const indicatorsElementsReducer = (state, action) => {
  const { data, t, unit } = action.payload;

  switch (action.type) {
    case 'sinister_count':
      return {
        number1: numeral(data.count).format('0,0'),
        trend1: data.trend && data.trend.count,
        invertedTrendColor: true
      };
    case 'client_performance':
      return {
        percent: data.client_performance
      };
    case 'stock_duration':
      return {
        number1: numeral(data.total_stock_days / millisecondsInDay).format(
          '0,0'
        ),
        trend1: data.trend && data.trend.total_stock_days,
        suffix1: t('home.miniDash.assignment.stock_duration.suffix1')
      };
    case 'constel_production_duration':
      return {
        number1: numeral(data.constel_duration).format('0,0'),
        trend1: data.trend && data.trend.constel_duration,
        suffix1: t(
          'home.miniDash.assignment.constel_production_duration.suffix1'
        )
      };
    case 'constructor_production_duration':
      return {
        number1: numeral(data.duration).format('0,0'),
        color1: data.duration > 21 ? red.primary : green.primary,
        trend1: data.trend && data.trend.duration,
        invertedTrendColor: true,
        suffix1: t(
          'home.miniDash.assignment.constructor_production_duration.suffix1'
        )
      };
    case 'volume_asset_to_be_returned':
      return {
        number1: numeral(data.leaseticCount).format('0,0'),
        number2: numeral(data.otherCount).format('0,0'),
        trend1: data.trend && data.trend.leaseticCount,
        trend2: data.trend && data.trend.otherCount,
        label1: t(
          'home.miniDash.assignment.volume_asset_to_be_returned.label1'
        ),
        label2: data.company
      };
    case 'volume_asset_returned':
      return {
        number1: numeral(data.leasetic).format('0,0'),
        number2: numeral(data.other).format('0,0'),
        trend1: data.trend && data.trend.leasetic,
        trend2: data.trend && data.trend.other,
        label1: t('home.miniDash.assignment.volume_asset_returned.label1'),
        label2: data.company
      };
    case 'assets_stock':
      return {
        percent: data.stock_in,
        number: data.it_total,
        label: t('home.miniDash.assignment.assets_stock.label'),
        strokeColor: colorAS(data.stock_in)
      };
    case 'affectation':
      return {
        number1: numeral(data.two_assets).format('0,0'),
        number2: numeral(data.more_two_assets).format('0,0'),
        trend1: data.trend && data.trend.two_assets,
        trend2: data.trend && data.trend.more_two_assets,
        label1: t('home.miniDash.assignment.affectation.label1'),
        label2: t('home.miniDash.assignment.affectation.label2')
      };
    case 'average_lifespan':
      return {
        
        number1: numeral(data.average2).format('0,0'),
        
        label1: t('home.miniDash.assignment.average_lifespan.label2'),
        trend1: data.trend && data.trend.average2,
        
        suffix1: t('home.miniDash.assignment.average_lifespan.suffix1')
      };
    case 'total_assets_and_people':
      return {
        number1: numeral(data.total).format('0,0'),
        number2: numeral(data.average).format('0,0.0'),
        trend1: data.trend && data.trend.total,
        trend2: data.trend && data.trend.average,
        invertedTrendColor: true,
        label1: t('home.miniDash.assignment.total_assets_and_people.label1'),
        label2: t('home.miniDash.assignment.total_assets_and_people.label2'),
        color2: colorTAAP(data.average),
        trend1Colored: false
      };
    case 'sinister_rate':
      return {
        number1:
          data.rate !== null
            ? `${numeral(data.rate).format('0,0[.]00')}%`
            : null,
        trend1: data.trend && data.trend.rate,
        invertedTrendColor: true
      };
    case 'investments_amount':
      return {
        number1: `${numeral(data.total).format('0[.]00 a')}€`,
        trend1: data.trend && data.trend.total
      };
    case 'leaser_performance':
      return {
        percent: data.leaser_performance
      };
    case 'inactive_lines':
      return {
        percent: data.percent,
        number: data.inactive,
        label: t('home.miniDash.assignment.lineUsage.label')
      };
    case 'total_overages_cost':
      return {
        percent: data.percent,
        number: `${numeral(data.overage).format('0[.]00 a')}€`,
        trend2: data.trend && data.trend.overage,
        invertedTrendColor: true,
        label: t('home.miniDash.assignment.invoices.label'),
        invertedProgress: true
      };
    case 'billed_for_period':
      return {
        number1: numeral(data.lines_count).format('0,0'),
        number2: `${numeral(data.total).format('0[.]00 a$')}€`,
        label1: t('home.miniDash.counter.telecomNumber.label1'),
        suffix1: '',
        label2: t('home.miniDash.counter.telecomNumber.label2')
      };
    case 'consumptions':
      return {
        number1: moment
          .duration(data.voice, 'seconds')
          .asHours()
          .toFixed(0),
        number2: numeral(data.data * 1000).format('0 b'),
        suffix1: 'h',
        trend1: data.trend && data.trend.voice,
        trend1Colored: false,
        trend2: data.trend && data.trend.data,
        trend2Colored: false,
        label1: t('home.miniDash.assignment.consumptions.label1'),
        label2: t('home.miniDash.assignment.consumptions.label2')
      };
    case 'overages_costs':
      return {
        number1: `${numeral(data.special).format('0[.]00 a')}€`,
        number2: `${numeral(data.international).format('0[.]00 a')}€`,
        label1: t('home.miniDash.assignment.overages_cost.label1'),
        label2: t('home.miniDash.assignment.overages_cost.label2')
      };
    case 'overages':
      return {
        number1:
          unit === 'amount'
            ? `${numeral(data.voice).format('0[.]00 a')}€`
            : formatNumbToTime(data.voice),
        number2:
          unit === 'amount'
            ? `${numeral(data.data).format('0[.]00 a')}€`
            : numeral(data.data * 1000).format('0.0 b'),
        trend1: data.trend && data.trend.voice,
        trend2: data.trend && data.trend.data,
        label1: t('home.miniDash.assignment.overages.label1'),
        label2: t('home.miniDash.assignment.overages.label2'),
        invertedTrendColor: true
      };
    case 'voice_overage_special_numbers':
      return {
        number1: `${numeral(data.amount).format('0[.]00 a')}€`,
        number2: numeral(data.count).format('0,0'),
        trend1: data.trend && data.trend.amount,
        trend2: data.trend && data.trend.count,
        trend2Colored: false,
        label1: t(
          'home.miniDash.assignment.voice_overage_special_numbers.label1'
        ),
        label2: t(
          'home.miniDash.assignment.voice_overage_special_numbers.label2'
        ),
        invertedTrendColor: true
      };
    case 'messages_overage_special_numbers':
      return {
        number1: `${numeral(data.amount).format('0[.]00 a')}€`,
        number2: numeral(data.count).format('0,0'),
        trend1: data.trend && data.trend.amount,
        trend2: data.trend && data.trend.count,
        trend2Colored: false,
        label1: t(
          'home.miniDash.assignment.voice_overage_special_numbers.label1'
        ),
        label2: t(
          'home.miniDash.assignment.voice_overage_special_numbers.label2'
        ),
        invertedTrendColor: true
      };
    case 'voice_overage_international':
      return {
        number1: `${numeral(data.amount).format('0[.]00 a')}€`,
        number2: numeral(data.count).format('0,0'),
        trend1: data.trend && data.trend.amount,
        trend2: data.trend && data.trend.count,
        trend2Colored: false,
        label1: t(
          'home.miniDash.assignment.voice_overage_international.label1'
        ),
        label2: t(
          'home.miniDash.assignment.voice_overage_international.label2'
        ),
        invertedTrendColor: true
      };
    case 'data_overage_international':
      return {
        number1: `${numeral(data.amount).format('0[.]00 a')}€`,
        number2: numeral(data.count).format('0,0'),
        trend1: data.trend && data.trend.amount,
        trend2: data.trend && data.trend.count,
        trend2Colored: false,
        label1: t('home.miniDash.assignment.data_overage_international.label1'),
        label2: t('home.miniDash.assignment.data_overage_international.label2'),
        invertedTrendColor: true
      };
    case 'total_services_cost':
      return {
        number1: `${numeral(data.total).format('0[.]00 a')}€`,
        trend1: data.trend && data.trend.total,
        invertedTrendColor: true
      };
    case 'total_credits':
      return {
        number1: `${numeral(data.total).format('0[.]00 a')}€`,
        trend1: data.trend && data.trend.total
      };
    case 'assignmentRate':
      return {
        percent: (data.used_assets / data.total_assets) * 100 || 0,
        number: data.total_assets - data.used_assets || 0,
        label: t('home.miniDash.assignment.assignmentRate.label')
      };
    case 'leasing_key_features':
      return {
        number1: numeral(data.total_assets).format('0,0') || 0,
        label1: t('home.miniDash.leasing.leasingFigures.label1'),
        number2: `${numeral(data.annual_cost).format('0[.]00 a')}€` || 0,
        label2: t('home.miniDash.leasing.leasingFigures.label2')
      };
    case 'renewal_volume':
      return {
        number1: `${numeral(data.renewal_consumed).format('0[.]00 a')}€` || 0,
        label1: t('home.miniDash.assignment.renewal_volume.label1'),
        number2: `${numeral(data.renewal_available).format('0[.]00 a')}€` || 0,
        label2: t('home.miniDash.assignment.renewal_volume.label2')
      };
    case 'valorisation_returns':
      return {
        number1: `${numeral(data.valuation_returns).format('0[.]00 a')}€` || 0,
        trend1: data.trend && data.trend.valuation_returns
      };
    case 'average_landlines_cost':
      return {
        label1: t('home.miniDash.assignment.average_cost_landline_line.label1'),
        label2: t('home.miniDash.assignment.average_cost_landline_line.label2'),
        number1: `${numeral(data.total).format('0[.]00 a')}€`,
        number2: numeral(data.count).format('0,0'),
        trend1: data.trend && data.trend.total,
        trend2: data.trend && data.trend.count,
        trend2Colored: false,
        invertedTrendColor: true,
        color1: data.limit < data.total ? red.primary : ''
      };
    case 'average_data_cost':
      return {
        label1: t('home.miniDash.assignment.average_cost_data_line.label1'),
        label2: t('home.miniDash.assignment.average_cost_data_line.label2'),
        number1: `${numeral(data.total).format('0[.]00 a')}€`,
        trend1: data.trend && data.trend.total,
        trend2: data.trend && data.trend.count,
        trend2Colored: false,
        invertedTrendColor: true,
        color1: data.limit < data.total ? red.primary : '',
        number2: numeral(data.count).format('0,0')
      };
    case 'average_data_voice_cost':
      return {
        label1: t(
          'home.miniDash.assignment.average_cost_voiceData_line.label1'
        ),
        label2: t(
          'home.miniDash.assignment.average_cost_voiceData_line.label2'
        ),
        number1: `${numeral(data.total).format('0[.]00 a')}€`,
        trend1: data.trend && data.trend.total,
        trend2: data.trend && data.trend.count,
        trend2Colored: false,
        invertedTrendColor: true,
        color1: data.limit < data.total ? red.primary : '',
        number2: numeral(data.count).format('0,0')
      };
    case 'average_voice_cost':
      return {
        label1: t('home.miniDash.assignment.average_cost_voice_line.label1'),
        label2: t('home.miniDash.assignment.average_cost_voice_line.label2'),
        number1: `${numeral(data.total).format('0[.]00 a')}€`,
        trend1: data.trend && data.trend.total,
        trend2: data.trend && data.trend.count,
        trend2Colored: false,
        invertedTrendColor: true,
        color1: data.limit < data.total ? red.primary : '',
        number2: numeral(data.count).format('0,0')
      };
    case 'total_contracts_amount':
      return {
        number1: `${numeral(data.total).format('0[.]00 a')}€`,
        trend1: data.trend && data.trend.total,
        invertedTrendColor: true,
        color1: data.limit < data.total ? red.primary : ''
      };
    case 'carbone_impact_PC':
      return {
        label1: t('home.miniDash.rse.carbone_impact_PC.label1'),
        label2: t('home.miniDash.rse.carbone_impact_PC.label2'),
        number1: `${numeral(data.making / 1000).format('0[.]00')} T`,
        number2: `${numeral(data.usage / 1000).format('0[.]00')} T`,
        trend1: data.trend && data.trend.making,
        trend2: data.trend && data.trend.usage,
        invertedTrendColor: true
      };
    case 'carbone_impact_others':
      return {
        label1: t('home.miniDash.rse.carbone_impact_others.label1'),
        label2: t('home.miniDash.rse.carbone_impact_others.label2'),
        number1: `${numeral(data.making / 1000).format('0[.]00')} T`,
        number2: `${numeral(data.usage / 1000).format('0[.]00')} T`,
        trend1: data.trend && data.trend.making,
        trend2: data.trend && data.trend.usage,
        invertedTrendColor: true
      };
    case 'carbone_impact_total':
      return {
        label1: t('home.miniDash.rse.carbone_impact_total.label1'),
        label2: t('home.miniDash.rse.carbone_impact_total.label2'),
        number1: `${numeral(data.making / 1000).format('0[.]00')} T`,
        number2: `${numeral(data.usage / 1000).format('0[.]00')} T`,
        trend1: data.trend && data.trend.making,
        trend2: data.trend && data.trend.usage,
        invertedTrendColor: true
      };
    case 'recycled_weight_DEEE':
      return {
        number1: `${numeral(data.recycled_weight).format('0[.]00')} Kg`
      };
    case 'quantity':
      return {
        label1: t('home.miniDash.rse.quantity.label1'),
        label2: t('home.miniDash.rse.quantity.label2'),
        number1: `${numeral(data.re_used).format('0[.]00')} `,
        number2: `${numeral(data.donations).format('0[.]00')} `,
        invertedTrendColor: true
      };
    case 'valuation_repair':
      return {
        label1: t('home.miniDash.rse.valuation_repair.label1'),
        label2: t('home.miniDash.rse.valuation_repair.label2'),
        number1: `${numeral(data.valuation / 1000).format('0')} K€`,
        number2: `${numeral(data.repair ).format('0')}`,
        invertedTrendColor: true
      };
    default:
      return {};
  }
};

export default indicatorsElementsReducer;
