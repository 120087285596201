import React from 'react';
import { Icon, Radio, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ExtraOptions = ({
  filter,
  handleFilterChange,
  reset,
  setDisabledPaymentDateRange,
  disabledPayment
}) => {
  const { t } = useTranslation();

  return (
    <Row style={{ marginTop: 8 }}>
      <Row>
        <Text strong style={{ marginBottom: 10, marginLeft: 8 }}>
          {t(`header.menu.filteredTab.invoicesFilter`)}
        </Text>
        <Icon style={{ marginLeft: 4 }} type="undo" onClick={reset} />
      </Row>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.paid}
        onChange={(e) => handleFilterChange('paid', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button
          value="&payment_date&payment_date!=null"
          onChange={() => setDisabledPaymentDateRange(false)}
        >
          {t(`header.menu.filteredTab.paidInvoices`)}
        </Radio.Button>
        <Radio.Button
          value="&!payment_date&payment_date=null"
          onChange={() => setDisabledPaymentDateRange(true)}
          disabled={disabledPayment}
        >
          {t(`header.menu.filteredTab.unpaidInvoices`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.order}
        onChange={(e) => handleFilterChange('pv', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&pv_date">
          {t(`header.menu.filteredTab.signedInvoices`)}
        </Radio.Button>
        <Radio.Button value="&!pv_date">
          {t(`header.menu.filteredTab.unsignedInvoices`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.order}
        onChange={(e) => handleFilterChange('order', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&delivery_date">
          {t(`header.menu.filteredTab.orderReceivedInvoices`)}
        </Radio.Button>
        <Radio.Button value="&!delivery_date">
          {t(`header.menu.filteredTab.orderNotReceivedInvoices`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.due}
        onChange={(e) => handleFilterChange('due', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="due">
          {t(`header.menu.filteredTab.orderDue`)}
        </Radio.Button>
        <Radio.Button value="!due">
          {t(`header.menu.filteredTab.orderNotDue`)}
        </Radio.Button>
      </Radio.Group>
    </Row>
  );
};

ExtraOptions.propTypes = {
  filter: PropTypes.shape({
    paid: PropTypes.string,
    pv: PropTypes.string,
    order: PropTypes.string,
    due: PropTypes.string
  }),
  handleFilterChange: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  setDisabledPaymentDateRange: PropTypes.func.isRequired,
  disabledPayment: PropTypes.func.isRequired
};

ExtraOptions.defaultProps = {
  filter: null
};

export default ExtraOptions;
