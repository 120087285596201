import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { Tag } from 'antd';
import { providerColors } from '../../utils';

const data = (invoice, t) => {
  const {
    provider,
    phone_contract_name,
    included_voice_seconds,
    included_messages_count,
    included_data_ko,
    consumption_voice_seconds,
    consumption_messages_count,
    consumption_data_ko,
    overage_voice_seconds,
    overage_messages_count,
    overage_data_ko,
    overage_voice_amount,
    overage_messages_amount,
    overage_data_amount,
    overage_voice_international_seconds,
    overage_messages_international_count,
    overage_data_international_ko,
    overage_voice_international_amount,
    overage_messages_international_amount,
    overage_data_international_amount,
    overage_voice_special_seconds,
    overage_messages_special_count,
    overage_voice_special_amount,
    overage_messages_special_amount
  } = invoice || {};
  return [
    {
      label: t('assets.showAsset.phoneContract.provider'),
      span: 1,
      content: <Tag color={providerColors[provider]}>{provider}</Tag>
    },
    {
      label: t('assets.showAsset.phoneContract.name'),
      span: 2,
      content: phone_contract_name
    },
    {
      label: t('assets.showAsset.phoneContract.included'),
      span: 3,
      content: (
        <>
          {t('assets.showAsset.phoneContract.subTitles.calls')}
          <span style={{ float: 'right' }}>
            {`${moment
              .duration(included_voice_seconds, 'seconds')
              .asMinutes()} minutes`}
          </span>
          <br />
          {t('assets.showAsset.phoneContract.subTitles.texts')}
          <span style={{ float: 'right' }}>
            {`${included_messages_count} messages`}
          </span>
          <br />
          {t('assets.showAsset.phoneContract.subTitles.data')}
          <span style={{ float: 'right' }}>
            {numeral(included_data_ko * 1000).format('0 b')}
          </span>
        </>
      )
    },
    {
      label: t('assets.showAsset.phoneContract.consumptions'),
      span: 3,
      content: (
        <>
          {t('assets.showAsset.phoneContract.subTitles.calls')}
          <span style={{ float: 'right' }}>
            {`${moment
              .duration(consumption_voice_seconds, 'seconds')
              .asMinutes()
              .toFixed(0)} minutes`}
          </span>
          <br />
          {t('assets.showAsset.phoneContract.subTitles.texts')}
          <span style={{ float: 'right' }}>
            {`${consumption_messages_count} messages`}
          </span>
          <br />
          {t('assets.showAsset.phoneContract.subTitles.data')}
          <span style={{ float: 'right' }}>
            {numeral(consumption_data_ko * 1000).format('0 b')}
          </span>
        </>
      )
    },
    {
      label: t('assets.showAsset.phoneContract.overages'),
      span: 3,
      content: (
        <>
          {t('assets.showAsset.phoneContract.subTitles.calls')}
          <span style={{ float: 'right' }}>
            {`${moment
              .duration(overage_voice_seconds, 'seconds')
              .asMinutes()
              .toFixed(0)} minutes (${numeral(overage_voice_amount).format(
              '0[.]00'
            )})€`}
          </span>
          <br />
          <span style={{ fontWeight: 400 }}>
            {t('assets.showAsset.phoneContract.subTitles.international')}
            <span style={{ float: 'right' }}>
              {`${moment
                .duration(overage_voice_international_seconds, 'seconds')
                .asMinutes()
                .toFixed(0)} minutes (${numeral(
                overage_voice_international_amount
              ).format('0[.]00')})€`}
            </span>
          </span>
          <br />
          <span style={{ fontWeight: 400 }}>
            {t('assets.showAsset.phoneContract.subTitles.special')}
            <span style={{ float: 'right' }}>
              {`${moment
                .duration(overage_voice_special_seconds, 'seconds')
                .asMinutes()
                .toFixed(0)} minutes (${numeral(
                overage_voice_special_amount
              ).format('0[.]00')})€`}
            </span>
          </span>
          <br />
          <br />
          {t('assets.showAsset.phoneContract.subTitles.texts')}
          <span style={{ float: 'right' }}>
            {`${overage_messages_count} messages (${numeral(
              overage_messages_amount
            ).format('0[.]00')})€`}
          </span>
          <br />
          <span style={{ fontWeight: 400 }}>
            {t('assets.showAsset.phoneContract.subTitles.international')}
            <span style={{ float: 'right' }}>
              {`${overage_messages_international_count} messages (${numeral(
                overage_messages_international_amount
              ).format('0[.]00')})€`}
            </span>
          </span>
          <br />
          <span style={{ fontWeight: 400 }}>
            {t('assets.showAsset.phoneContract.subTitles.special')}
            <span style={{ float: 'right' }}>
              {`${overage_messages_special_count} messages (${numeral(
                overage_messages_special_amount
              ).format('0[.]00')})€`}
            </span>
          </span>
          <br />
          <br />
          {t('assets.showAsset.phoneContract.subTitles.data')}
          <span style={{ float: 'right' }}>
            {`${numeral(overage_data_ko * 1000).format('0 b')} (${numeral(
              overage_data_amount
            ).format('0[.]00')})€`}
          </span>
          <br />
          <span style={{ fontWeight: 400 }}>
            {t('assets.showAsset.phoneContract.subTitles.international')}
            <span style={{ float: 'right' }}>
              {`${numeral(overage_data_international_ko * 1000).format(
                '0 b'
              )} (${numeral(overage_data_international_amount).format(
                '0[.]00 '
              )})€`}
            </span>
          </span>
          <br />
        </>
      )
    }
  ];
};

export default data;
