/* eslint-disable no-console */
import React, { useContext, createContext } from 'react';
import { useStateWithLocalStorage } from '../utils';

const TicketContext = createContext({});

export const TicketContextProvider = ({ children }) => {
  const [assets, setAssets] = useStateWithLocalStorage('ticket_assets', {});

  const dispatchTicket = (action) => {
    let cpy;
    switch (action.type) {
      case 'ADD':
        if (!assets[action.asset._id])
          setAssets({ ...assets, [action.asset._id]: action.asset });
        break;
      case 'ADD_MANY':
        setAssets({ ...action.assets });
        break;
      case 'REMOVE':
        cpy = assets;
        delete cpy[action.id];
        setAssets(cpy);
        break;
      case 'DROP':
        setAssets({});
        break;
      default:
        console.error('Unhandled action.');
    }
  };

  return (
    <TicketContext.Provider value={{ assets, dispatchTicket }}>
      {children}
    </TicketContext.Provider>
  );
};

export default () => useContext(TicketContext);
