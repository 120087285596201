import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import headers from './headers';
import { ResourceLandingLayout } from '../../layouts';

const ListCompanies = ({ type }) => {
  const { t } = useTranslation();

  return (
    <ResourceLandingLayout
      tradKey={type.toLowerCase()}
      headers={headers}
      extraQuery={`type=${type}`}
      customActionColumn
      withCreateButton={false}
      resourceName="companies"
      path="companies"
      exportUrl={`companies?type=${type}`}
      columns={[
        {
          title: t('companies.list.column.name'),
          dataIndex: 'name',
          sorter: true
        },
        {
          title: t('companies.list.column.group'),
          dataIndex: 'group',
          sorter: true
        },
        {
          title: t('companies.list.column.siren'),
          dataIndex: 'siren',
          sorter: true,
          render: (siren) => (siren ? numeral(siren).format('siren') : '-')
        },
        {
          title: t('companies.list.column.APE'),
          dataIndex: 'APE',
          sorter: true
        }
      ]}
    />
  );
};

ListCompanies.propTypes = {
  type: PropTypes.string.isRequired
};

export default ListCompanies;
