import React from 'react';
import { Input, Checkbox, Divider, Form, Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noLabelLayout } from './utils';

const { Option } = Select;
const IncidentDetailsForm = ({
  ticketType,
  getFieldDecorator,
  isSitesLoading,
  getCompanySites,
  companySites,
  siteId
}) => {
  const { t } = useTranslation();
  return (
    ticketType === 'COLLECTION' && (
      <>
        <Divider orientation="left">
          {t('tickets.fields.divider.address')}
        </Divider>
        <Form.Item label={t('tickets.fields.site')}>
          {getFieldDecorator('siteId')(
            <Select
              allowClear
              notFoundContent={null}
              loading={isSitesLoading}
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => getCompanySites(value)}
            >
              {companySites.map(({ _id, name }) => (
                <Option value={_id} key={_id}>
                  {name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t('tickets.fields.siteName')}>
          {getFieldDecorator('site.name', {
            rules: [
              {
                required: ticketType === 'COLLECTION',
                message: t('global.form.requiredMessage')
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('tickets.fields.siteNumber')}>
          {getFieldDecorator('site.address.number', {
            rules: [
              {
                required: ticketType === 'COLLECTION',
                message: t('global.form.requiredMessage')
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('tickets.fields.siteStreet')}>
          {getFieldDecorator('site.address.street', {
            rules: [
              {
                required: ticketType === 'COLLECTION',
                message: t('global.form.requiredMessage')
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('tickets.fields.siteAdditional')}>
          {getFieldDecorator('site.address.additional')(<Input />)}
        </Form.Item>
        <Form.Item label={t('tickets.fields.sitePostalCode')}>
          {getFieldDecorator('site.address.postal_code', {
            rules: [
              {
                required: ticketType === 'COLLECTION',
                message: t('global.form.requiredMessage')
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('tickets.fields.siteCity')}>
          {getFieldDecorator('site.address.city', {
            rules: [
              {
                required: ticketType === 'COLLECTION',
                message: t('global.form.requiredMessage')
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('tickets.fields.siteCountry')}>
          {getFieldDecorator('site.address.country', {
            rules: [
              {
                required: ticketType === 'COLLECTION',
                message: t('global.form.requiredMessage')
              }
            ]
          })(<Input />)}
        </Form.Item>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Form.Item {...noLabelLayout}>
          {getFieldDecorator('createUpdateSite', {
            valuePropName: 'checked'
          })(
            <Checkbox>
              {t(`tickets.fields.${siteId ? 'update' : 'create'}`)}
            </Checkbox>
          )}
        </Form.Item>
      </>
    )
  );
};

IncidentDetailsForm.propTypes = {
  ticketType: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  isSitesLoading: PropTypes.bool.isRequired,
  getCompanySites: PropTypes.func.isRequired,
  companySites: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.number
  }).isRequired,
  siteId: PropTypes.string.isRequired
};

export default IncidentDetailsForm;
