import React from 'react';
import { Descriptions, Tag } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  ticketStatusColors,
  ticketPriorityColors,
  ticketTypeColors
} from '../../utils';
import { ContentCustom } from '../../components';
import UploadDrawer from '../../components/uploadDrawer';

const TicketInformation = ({ id, ticket, badgeCount, getTicket }) => {
  const { t } = useTranslation();
  const {
    label,
    creation_date,
    priority,
    ready_date,
    status,
    type,
    files,
    audit_report
  } = ticket;
  return (
    <ContentCustom padding={false}>
      <div
        style={{
          padding: 24,
          position: 'relative',
          overflow: 'hidden',
          height: '100%',
          width: '100%'
        }}
      >
        <UploadDrawer
          badgeCount={badgeCount}
          getResource={getTicket}
          resourceFiles={[
            ...(files || []),
            ...(audit_report ? [audit_report] : [])
          ]}
          resourceId={id}
          resourceName="tickets"
          withUpload={ticket.type === 'THEFT'}
        />
        <Descriptions column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
          <Descriptions.Item label={t('tickets.showTicket.label')} span={2}>
            {label || '-'}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('tickets.showTicket.creation_date')}
            span={1}
          >
            {moment(creation_date).format('LL') || '-'}
          </Descriptions.Item>
          <Descriptions.Item label={t('tickets.showTicket.priority')} span={3}>
            <Tag color={ticketPriorityColors[priority]}>
              {t(`tickets.options.${priority}`)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item
            label={t('tickets.showTicket.ready_date')}
            span={1}
          >
            {ready_date ? moment(ready_date).format('LL') : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label={t('tickets.showTicket.status')} span={1}>
            <Tag color={ticketStatusColors[status]}>
              {t(`tickets.options.${status}`)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label={t('tickets.showTicket.type')} span={1}>
            <Tag color={ticketTypeColors[type]}>
              {t(`tickets.options.${type}`)}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </ContentCustom>
  );
};

TicketInformation.propTypes = {
  id: PropTypes.string.isRequired,
  ticket: PropTypes.shape({
    label: PropTypes.string,
    creation_date: PropTypes.string,
    priority: PropTypes.string,
    ready_date: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    files: PropTypes.arrayOf().isRequired,
    audit_report: PropTypes.shape({}).isRequired
  }).isRequired,
  badgeCount: PropTypes.number.isRequired,
  getTicket: PropTypes.func.isRequired
};

export default TicketInformation;
