import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../../layouts/TableLayout/style.css';
import { useTranslation } from 'react-i18next';
import {
  Col,
  DatePicker,
  Icon,
  Menu,
  Row,
  Tooltip,
  Typography,
  Button
} from 'antd';
import moment from 'moment';
import useColumns from './columns';
import { ResourceLandingLayout } from '../../layouts';
import { useStateWithSessionStorage } from '../../utils';
import ExtraOptions from './ExtraOptions';
import DatePickerFooter from './DatePickerFooter';
import ExportButton from '../../components/exportButton/ExportButton';
import exportHeaders from './exportHeaders';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ListAssets = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const { [type]: tableColumns } = useColumns();
  const [rangeDates, setRangeDates] = useStateWithSessionStorage(
    'assetsRangeDates',
    []
  );

  const [dateField, setDateField] = useStateWithSessionStorage(
    'assetsDateField',
    'entry_date'
  );

  const [filter, setFilter] = useStateWithSessionStorage('assetsFilter', {
    status: undefined,
    overage: undefined
  });

  const [optionsDisplay, setOptionsDisplay] = useState(false);

  const onDateChange = (date, dateString) => {
    setRangeDates(date.length > 0 ? dateString : []);
  };

  const datesUrl =
    rangeDates && rangeDates[0]
      ? `&${dateField}>=${rangeDates[0]}&${dateField}<=${rangeDates[1]}`
      : null;

  const handleReset = () => {
    setFilter({ status: undefined, overage: undefined });
    setRangeDates([]);
  };

  const extraQuery = `type=${type}${filter.status || ''}${filter.overage ||
    ''}${datesUrl || ''}`;

  const menu = (
    <Menu>
      <Menu.Item>
        <ExportButton
          dataName="assets"
          headers={exportHeaders[type]}
          fileName={`MCR-assets-${type}.csv`}
          url={`/assets/${type}?populate=contract,people,owner,phone_contract${filter.status ||
            ''}${filter.overage || ''}${datesUrl || ''}`}
        />
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (filter.status || filter.overage || rangeDates.length)
      setOptionsDisplay(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleReset();
  }, [type]);

  return (
    <ResourceLandingLayout
      customActionColumn
      customMenu={menu}
      withCreateButton={false}
      resourceName="assets"
      path={`${type}`}
      tradKey={type}
      populate="telecomInvoices"
      extraQuery={extraQuery}
      columns={tableColumns}
      extra={
        type === 'Telecom' ? (
          <Link to={{ pathname: '/telecomInvoices', search: '?s=-date' }}>
            <Button type="primary">Factures</Button>
          </Link>
        ) : (
          undefined
        )
      }
    >
      <Row type="flex" justify="start" style={{ margin: '16px 0' }}>
        <Tooltip title="Options" placement="right" mouseEnterDelay={1}>
          <Icon
            style={{ fontSize: 20 }}
            type={optionsDisplay ? 'up' : 'filter'}
            onClick={() => setOptionsDisplay(!optionsDisplay)}
          />
        </Tooltip>
      </Row>
      <Row
        style={{ display: optionsDisplay ? 'contents' : 'none' }}
        gutter={36}
      >
        <ExtraOptions
          type={type}
          filter={filter}
          handleFilterChange={(key, value) =>
            setFilter({ ...filter, [key]: value })
          }
          reset={handleReset}
        />
        <Row
          style={{ margin: '10px 0' }}
          gutter={16}
          type="flex"
          justify="space-between"
        >
          <Col span={8}>
            <Row>
              <Text strong style={{ marginBottom: '10px' }}>
                {t('header.menu.filteredTab.dateRange')}
              </Text>
            </Row>
            <Row>
              <RangePicker
                style={{ width: '100%' }}
                value={
                  rangeDates.length > 0
                    ? [moment(rangeDates[0]), moment(rangeDates[1])]
                    : []
                }
                onChange={onDateChange}
                allowClear
                renderExtraFooter={() => (
                  <DatePickerFooter
                    dateField={dateField}
                    handleDateFieldChange={(value) => setDateField(value)}
                  />
                )}
              />
            </Row>
          </Col>
        </Row>
      </Row>
    </ResourceLandingLayout>
  );
};

export default ListAssets;
