export const languages = {
  en_GB: { label: 'English', icon: '🇬🇧' },
  fr_FR: { label: 'Français', icon: '🇫🇷' },
  es_ES: {
    label: 'Español',
    icon: '🇪🇸'
  } /* ,
    de_DE: { label: 'Deutsche', icon: '🇩🇪'} */
};

export const assetsIcons = {
  IT: 'laptop',
  Telecom: 'mobile',
  Car: 'car',
  RealEstate: 'home'
};
