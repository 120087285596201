export const headersTop50 = [
  {
    label: 'number',
    key: '_id'
  },
  {
    label: 'total_beyond',
    key: 'overage'
  },
  {
    label: 'registrationNumber',
    key: 'people[0].id_number'
  },
  {
    label: 'firstName',
    key: 'people[0].first_name'
  },
  {
    label: 'lastName',
    key: 'people[0].last_name'
  },
  {
    label: 'invoiceDate',
    key: 'date'
  },
  {
    label: 'contractLabel',
    key: 'phone_contract_name'
  },
  {
    label: 'provider',
    key: 'provider'
  },
  {
    label: 'consumptionVT',
    key: 'consumption_voice_seconds'
  },
  {
    label: 'consumptionDT',
    key: 'consumption_data_ko'
  },
  {
    label: 'overageVT',
    key: 'overage_voice_seconds'
  },
  {
    label: 'amountVOverage',
    key: 'overage_voice_amount'
  },
  {
    label: 'amountDConsumption',
    key: 'overage_data_amount'
  },
  {
    label: 'overageDT',
    key: 'overage_data_ko'
  }
];
export const headersTop10Data = [
  {
    label: 'number',
    key: '_id'
  },
  {
    label: 'total_beyond',
    key: 'overage'
  },
  {
    label: 'registrationNumber',
    key: 'people[0].id_number'
  },
  {
    label: 'firstName',
    key: 'people[0].first_name'
  },
  {
    label: 'lastName',
    key: 'people[0].last_name'
  },
  {
    label: 'invoiceDate',
    key: 'date'
  },
  {
    label: 'contractLabel',
    key: 'phone_contract_name'
  },
  {
    label: 'provider',
    key: 'provider'
  },
  {
    label: 'consumptionDT',
    key: 'consumption_data_ko'
  },
  {
    label: 'amountDConsumption',
    key: 'overage_data_amount'
  },
  {
    label: 'overageDT',
    key: 'overage_data_ko'
  },
  {
    label: 'overageDInter',
    key: 'overage_data_international_ko'
  }
];

export const headersTop10Voice = [
  {
    label: 'number',
    key: '_id'
  },
  {
    label: 'total_beyond',
    key: 'overage'
  },
  {
    label: 'registrationNumber',
    key: 'people[0].id_number'
  },
  {
    label: 'firstName',
    key: 'people[0].first_name'
  },
  {
    label: 'lastName',
    key: 'people[0].last_name'
  },
  {
    label: 'invoiceDate',
    key: 'date'
  },
  {
    label: 'contractLabel',
    key: 'phone_contract_name'
  },
  {
    label: 'provider',
    key: 'provider'
  },
  {
    label: 'consumptionVT',
    key: 'consumption_voice_seconds'
  },
  {
    label: 'overageVT',
    key: 'overage_voice_seconds'
  },
  {
    label: 'amountVOverage',
    key: 'overage_voice_amount'
  },
  {
    label: 'overageVSpecial',
    key: 'overage_voice_special_seconds'
  },
  {
    label: 'overageVInter',
    key: 'overage_voice_international_seconds'
  }
];

export const headersPhoneContract = [
  {
    label: 'contractName',
    key: '_id'
  },
  {
    label: 'contract_total',
    key: 'average'
  }
];

export const headersStockDivision = [
  {
    label: 'cityName',
    key: 'name'
  },
  {
    label: 'totalAssetStock',
    key: 'stock[0].count'
  }
];

export const headersVolumeAssetsReturned = [
  {
    label: 'serialNumber',
    key: 'serial_number'
  },
  {
    label: 'easyVistaRequestSerialNumber',
    key: 'EasyVista_request_serial_number'
  },
  {
    label: 'label',
    key: 'description'
  },
  {
    label: 'genre',
    key: 'genre'
  },
  {
    label: 'brand',
    key: 'brand'
  },
  {
    label: 'owner',
    key: 'owner'
  },
  {
    label: 'entryDate',
    key: 'entry_date'
  },
  {
    label: 'deliveryDate',
    key: 'delivery_date'
  },
  {
    label: 'registrationNumber',
    key: 'people[0].id_number'
  },
  {
    label: 'fistName',
    key: 'people[0].first_name'
  },
  {
    label: 'lastName',
    key: 'people[0].last_name'
  },
  {
    label: 'jobFamily',
    key: 'people[0].job_category'
  },
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'site',
    key: 'site[0].name'
  },
  {
    label: 'subsidiary',
    key: 'subsidiary[0].name'
  },
  {
    label: 'unitPrice',
    key: 'unit_price'
  }
];

export const headersAffectation = [
  {
    label: 'assetsNumber',
    key: 'count'
  },
  {
    label: 'firstName',
    key: 'people[0].first_name'
  },
  {
    label: 'lastName',
    key: 'people[0].last_name'
  },
  {
    label: 'contractType',
    key: 'people[0].contract_type'
  },
  {
    label: 'email',
    key: 'people[0].email'
  },
  {
    label: 'job',
    key: 'people[0].job'
  },
  {
    label: 'jobCategory',
    key: 'people[0].job_category'
  },
  {
    label: 'jobType',
    key: 'people[0].job_type'
  },
  {
    label: 'site',
    key: 'people[0].site'
  }
];
export const headersAssetStock = [
  {
    label: 'easyVistaRequestSerialNumber',
    key: 'EasyVista_request_serial_number'
  },
  {
    label: 'serialNumber',
    key: 'serial_number'
  },
  {
    label: 'description',
    key: 'description'
  },
  {
    label: 'genre',
    key: 'genre'
  },
  {
    label: 'brand',
    key: 'brand'
  },
  {
    label: 'owner',
    key: 'owner[0].name'
  },
  {
    label: 'entryDate',
    key: 'entry_date'
  },
  {
    label: 'exitDate',
    key: 'delivery_date'
  },
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'site',
    key: 'site[0].name'
  },
  {
    label: 'subsidiary',
    key: 'subsidiary[0].name'
  },
  {
    label: 'unitPrice',
    key: 'unit_price'
  }
];

export const headersInactiveLines = [
  { label: 'number', key: 'number' },
  { label: 'date', key: 'date' },
  { label: 'provider', key: 'provider' },
  { label: 'contract_number', key: 'contract_number' },
  { label: 'device', key: 'device' },
  { label: 'credits_amount', key: 'credits_amount' },
  { label: 'phone_number', key: 'phone_number' },
  { label: 'phone_contract_name', key: 'phone_contract_name' },
  { label: 'amount', key: 'amount' },
  { label: 'percent_overage', key: 'percent_overage' },
  { label: 'consumption_voice_seconds', key: 'consumption_voice_seconds' },
  { label: 'consumption_messages_count', key: 'consumption_messages_count' },
  { label: 'consumption_data_ko', key: 'consumption_data_ko' },
  { label: 'overage_voice_amount', key: 'overage_voice_amount' },
  { label: 'overage_voice_seconds', key: 'overage_voice_seconds' },
  { label: 'overage_messages_amount', key: 'overage_messages_amount' },
  { label: 'overage_messages_count', key: 'overage_messages_count' },
  { label: 'overage_data_amount', key: 'overage_data_amount' },
  { label: 'overage_data_ko', key: 'overage_data_ko' },
  {
    label: 'overage_voice_special_amount',
    key: 'overage_voice_special_amount'
  },
  {
    label: 'overage_voice_special_seconds',
    key: 'overage_voice_special_seconds'
  },
  {
    label: 'overage_messages_special_amount',
    key: 'overage_messages_special_amount'
  },
  {
    label: 'overage_messages_special_count',
    key: 'overage_messages_special_count'
  },
  {
    label: 'overage_voice_international_amount',
    key: 'overage_voice_international_amount'
  },
  {
    label: 'overage_voice_international_seconds',
    key: 'overage_voice_international_seconds'
  },
  {
    label: 'overage_messages_international_amount',
    key: 'overage_messages_international_amount'
  },
  {
    label: 'overage_messages_international_count',
    key: 'overage_messages_international_count'
  },
  {
    label: 'overage_data_international_amount',
    key: 'overage_data_international_amount'
  },
  {
    label: 'overage_data_international_ko',
    key: 'overage_data_international_ko'
  }
];
export const headersTotalCredits = [
  { label: 'number', key: 'number' },
  { label: 'date', key: 'date' },
  { label: 'provider', key: 'provider' },
  { label: 'contract_number', key: 'contract_number' },
  { label: 'device', key: 'device' },
  { label: 'credits_amount', key: 'credits_amount' },
  { label: 'phone_number', key: 'phone_number' },
  { label: 'phone_contract_name', key: 'phone_contract_name' },
  { label: 'amount', key: 'amount' },
  { label: 'percent_overage', key: 'percent_overage' },
  { label: 'consumption_voice_seconds', key: 'consumption_voice_seconds' },
  { label: 'consumption_messages_count', key: 'consumption_messages_count' },
  { label: 'consumption_data_ko', key: 'consumption_data_ko' },
  { label: 'overage_voice_amount', key: 'overage_voice_amount' },
  { label: 'overage_voice_seconds', key: 'overage_voice_seconds' },
  { label: 'overage_messages_amount', key: 'overage_messages_amount' },
  { label: 'overage_messages_count', key: 'overage_messages_count' },
  { label: 'overage_data_amount', key: 'overage_data_amount' },
  { label: 'overage_data_ko', key: 'overage_data_ko' },
  {
    label: 'overage_voice_special_amount',
    key: 'overage_voice_special_amount'
  },
  {
    label: 'overage_voice_special_seconds',
    key: 'overage_voice_special_seconds'
  },
  {
    label: 'overage_messages_special_amount',
    key: 'overage_messages_special_amount'
  },
  {
    label: 'overage_messages_special_count',
    key: 'overage_messages_special_count'
  },
  {
    label: 'overage_voice_international_amount',
    key: 'overage_voice_international_amount'
  },
  {
    label: 'overage_voice_international_seconds',
    key: 'overage_voice_international_seconds'
  },
  {
    label: 'overage_messages_international_amount',
    key: 'overage_messages_international_amount'
  },
  {
    label: 'overage_messages_international_count',
    key: 'overage_messages_international_count'
  },
  {
    label: 'overage_data_international_amount',
    key: 'overage_data_international_amount'
  },
  {
    label: 'overage_data_international_ko',
    key: 'overage_data_international_ko'
  }
];
