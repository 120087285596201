import moment from 'moment';
import numeral from 'numeral';
import { green } from '@ant-design/colors';

const chartsElementsReducer = (state, action) => {
  const { data, timeScale, t, unit } = action.payload;

  let tmpData = [];
  const monthList = [];
  const fieldList = [];
  const startingMonth = moment(timeScale[0]);
  // eslint-disable-next-line prefer-destructuring
  const endingMonth = timeScale[1];
  while (startingMonth <= endingMonth) {
    monthList.push(startingMonth.format('YYYY-MM')); // Set list of months readable by moment
    fieldList.push(startingMonth.format('MM-YYYY')); // Set Chart fields
    startingMonth.add(1, 'month');
  }

  const formatNumbToTime = (numb) => {
    const hours = moment.duration(numb, 'seconds').asHours();
    const minutes = moment
      .duration(hours - Math.floor(hours), 'hours')
      .minutes();
    return `${Math.floor(hours)}h${minutes < 10 ? '0' : ''}${minutes}`;
  };

  const scalePadding = (d) => {
    const lineCounts = d.map((e) => e.count);
    const max = Math.max(...lineCounts);
    const min = Math.min(...lineCounts);
    const diff = max - min;
    const totalScale = diff / 0.2;
    const padding = totalScale * 0.4;

    return { min: min - padding, max: max + padding };
  };

  switch (action.type) {
    case 'assets_seniority':
      monthList.forEach((month) => {
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          count: data[month] || 0
        });
      });
      return {
        data: tmpData,
        fields: fieldList,
        xAxis: 'month',
        yAxis: 'count',
        customisedPadding: [20, 50, 100, 70],
        position: 'month*count',
        geomType: 'intervalStack',
        color: '#42146f',
        cols: {
          count: {
            formatter: (v) => `${numeral(v).format('0,0')}`
          }
        },
        customTooltip: [
          'count',
          (c) => ({
            name: t(`home.dash.legends.seniority`),
            value: numeral(c).format('0,0')
          })
        ]
      };
    case 'sinister_map':
      return {
        selectedZones: data.map(
          ({ name, totalAssetsInRegion, totalSinisterInRegion }) => {
            let color = '#7FD4B4';
            if (
              totalSinisterInRegion &&
              totalAssetsInRegion &&
              totalSinisterInRegion[0] &&
              totalAssetsInRegion[0]
            ) {
              if (
                totalSinisterInRegion[0].total / totalAssetsInRegion[0].count >
                0.04
              )
                color = 'yellow';
              if (
                totalSinisterInRegion[0].total / totalAssetsInRegion[0].count >
                0.06
              )
                color = 'orange';
              if (
                totalSinisterInRegion[0].total / totalAssetsInRegion[0].count >
                0.08
              )
                color = 'red';
              return {
                name,
                color,
                tooltip: `${name} - ${(
                  (totalSinisterInRegion[0].total /
                    totalAssetsInRegion[0].count) *
                  100
                ).toFixed(2)}%`
              };
            }
            return {};
          }
        ),
        disabledPress: true
      };
    case 'assetsQuality':
      return {
        data,
        type: 'percent',
        field: 'value',
        dimension: 'grade',
        groupBy: ['month'],
        xAxis: 'month',
        yAxis: 'value',
        geomType: 'intervalStack',
        position: 'month*value',
        color: 'grade',
        cols: {
          value: {
            min: 0,
            formatter(val) {
              return `${val} %`;
            }
          }
        }
      };
    case 'top_overage_data':
    case 'top_overage_voice':
    case 'top_overage_special':
      tmpData = Object.values(data)
        .reverse()
        .map(({ _id, overage }) => ({
          phone_number: _id,
          overage
        }));
      return {
        data: tmpData,
        type: 'fold',
        fields: ['overage'],
        key: 'category',
        value: 'amount',
        xAxis: 'phone_number',
        yAxis: 'amount',
        position: 'phone_number*amount',
        cols: {
          amount: {
            formatter: (v) =>
              unit === 'amount'
                ? `${numeral(v).format('0,0')}€`
                : formatNumbToTime(v)
          },
          phone_number: {
            formatter: (v) => `0${v}`
          }
        },
        customTooltip: [
          'category*amount',
          (category, amount) => ({
            name: t(`home.dash.legends.${category}`),
            value:
              unit === 'amount'
                ? `${numeral(amount).format('0,0.00')}€`
                : formatNumbToTime(amount)
          })
        ],
        color: [
          'category',
          () => {
            if (action.type === 'top_overage_data') return '#4FAAEB';
            if (action.type === 'top_overage_special') return '#9d4feb';
            return '#1fd000';
          }
        ],
        geomType: 'intervalStack',
        customisedPadding: [20, 60, 50, 100]
      };
    case 'monthly_costs':
    case 'overages_evolution':
      tmpData = Object.entries(data || {}).map((provider) => {
        const figures = {};
        monthList.forEach((month) => {
          figures[moment(month).format('MM-YYYY')] = provider[1][month] || 0;
        });
        return {
          provider: provider[0],
          ...figures
        };
      });

      return {
        data: tmpData,
        fields: fieldList,
        type: 'fold',
        key: 'month',
        value: 'amount',
        xAxis: 'month',
        yAxis: 'amount',
        customisedPadding: [20, 50, 100, 70],
        position: 'month*amount',
        geomType: 'intervalStack',
        cols: {
          amount: {
            formatter: (v) => `${numeral(v).format('0,0')}€`
          }
        },
        customTooltip: [
          'provider*amount',
          (name, amount) => ({
            name: t(`home.dash.legends.${name}`),
            value: `${numeral(amount).format('0,0.00')}€`
          })
        ],
        color: [
          'provider',
          (value) => {
            if (value === 'SFR') return '#e22d2c';
            if (value === 'BOUYGUES') return '#4f9fcd';
            if (value === 'ORANGE') return '#e86229';
            if (value === 'data') return '#4FAAEB';
            if (value === 'messages') return '#fdd11c';
            if (value === 'voice') return '#1fd000';
            return '#292ce8';
          }
        ]
      };
    case 'count_unused_line':
      monthList.forEach((month) => {
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          count: data[month] || 0
        });
      });
      return {
        data,
        xAxis: 'date',
        yAxis: 'count',
        customisedPadding: [20, 50, 160, 70],
        position: 'date*count',
        geomType: 'intervalStack',
        color: [
          'access_type',
          (a) => {
            return a === 'access_type' ? '#3da1ff' : undefined;
          }
        ],
        cols: {
          count: {
            formatter: (v) => `${numeral(v).format('0,0')}`
          }
        },
        customTooltip: [
          'count*access_type',
          (c, a) => ({
            name: a || 'N/R',
            value: numeral(c).format('0,0')
          })
        ],
        noCustomLegend: true
      };
    case 'stock_in_evolution':
      tmpData = Object.entries(data || []).map((genre) => {
        const figures = {};
        monthList.forEach((month) => {
          figures[moment(month).format('MM-YYYY')] = genre[1][month] || 0;
        });
        return {
          name: genre[0],
          ...figures
        };
      });
      return {
        data: tmpData,
        fields: fieldList,
        type: 'fold',
        customisedPadding: [20, 30, 130, 60],
        key: 'month',
        value: 'amount',
        xAxis: 'month',
        yAxis: 'amount',
        position: 'month*amount',
        geomType: 'intervalStack',
        color: 'name',
        noCustomLegend: true,
        customTooltip: [
          'name*amount',
          (n, a) => ({
            name: n,
            value: numeral(a).format('0,0')
          })
        ]
      };
    case 'assets_by_category':
      tmpData = Object.entries(data || [])
        .filter(([g]) => g !== 'undefined')
        .map((genre) => {
          const figures = {};
          let total = 0;
          Object.entries(genre[1])
            .filter(([key]) => key !== 'total')
            .forEach((el) => {
              if (moment(el[0]) < timeScale[0] || el[0] === 'null') {
                total += el[1];
              }
            });
          monthList.forEach((month) => {
            total += genre[1][month] || 0;
            figures[moment(month).format('MM-YYYY')] = total;
          });
          return {
            name: genre[0],
            ...figures
          };
        });
      return {
        data: tmpData,
        fields: fieldList,
        customisedPadding: [20, 30, 130, 60],
        type: 'fold',
        key: 'month',
        value: 'amount',
        xAxis: 'month',
        yAxis: 'amount',
        position: 'month*amount',
        geomType: 'intervalStack',
        color: 'name',
        noCustomLegend: true,
        customTooltip: [
          'name*amount',
          (n, a) => ({
            name: n,
            value: numeral(a).format('0,0')
          })
        ]
      };
    case 'consumptions_evolution':
      monthList.forEach((month) => {
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          voice: data.voice ? data.voice[month] : 0,
          data: data.data ? data.data[month] : 0
        });
      });
      return {
        data: tmpData,
        type: 'fold',
        fields: fieldList,
        key: 'category',
        value: 'amount',
        xAxis: 'month',
        position: 'month*data',
        position2: 'month*voice',
        color: '#4FAAEB',
        color2: '#1fd000',
        cols: {
          data: {
            formatter: (v) => numeral(v * 1000).format('0.0 b')
          },
          voice: {
            formatter: (v) => formatNumbToTime(v)
          }
        },
        customTooltip: [
          'data',
          (d) => ({
            name: t(`home.dash.legends.data`),
            value: numeral(d * 1000).format('0.0 b')
          })
        ],
        customTooltip2: [
          'voice',
          (d) => ({
            name: t(`home.dash.legends.voice`),
            value: formatNumbToTime(d)
          })
        ],
        geomType: 'line',
        geomType2: 'line',
        geomSize: 2,
        geomSize2: 2
      };
    case 'data_consumption_evolution':
      return {
        data,
        xAxis: 'date',
        position: 'date*consumption',
        position2: 'date*overage',
        yAxis2: 'overage',
        customisedPadding: [20, 90, 110, 60],
        color: ['device'],
        color2: ['#1a1a1a'],
        cols: {
          consumption: {
            formatter: (v) => numeral(v * 1000).format('0 b')
          },
          overage: {
            formatter: (v) => numeral(v * 1000).format('0 b'),
            alias: t('home.dash.legends.overage')
          },
          date: {
            formatter: (v) => moment(v).format('MM-YYYY')
          }
        },
        customTooltip: [
          'device*consumption',
          (d, c) => ({
            name: t(`home.dash.legends.${d}`),
            value: numeral(c * 1000).format('0.0 b')
          })
        ],
        customTooltip2: [
          'overage',
          (o) => ({
            name: t(`home.dash.legends.overage`),
            value: numeral(o * 1000).format('0.0 b')
          })
        ],
        geomType: 'intervalStack',
        geomType2: 'line',
        geomSize2: 2
      };
    case 'voice_consumption_evolution':
      return {
        data,
        xAxis: 'date',
        position: 'date*consumption',
        position2: 'date*overage',
        yAxis2: 'overage',
        customisedPadding: [20, 120, 110, 80],
        titleOffset: 100,
        color: ['device'],
        color2: ['#1a1a1a'],
        cols: {
          consumption: {
            formatter: (v) => formatNumbToTime(v)
          },
          overage: {
            formatter: (v) => formatNumbToTime(v),
            alias: t('home.dash.legends.overage')
          },
          date: {
            formatter: (v) => moment(v).format('MM-YYYY')
          }
        },
        customTooltip: [
          'device*consumption',
          (d, c) => ({
            name: t(`home.dash.legends.${d}`),
            value: formatNumbToTime(c)
          })
        ],
        customTooltip2: [
          'overage',
          (o) => ({
            name: t(`home.dash.legends.overage`),
            value: formatNumbToTime(o)
          })
        ],
        geomType: 'intervalStack',
        geomType2: 'line',
        geomSize2: 2
      };
    case 'fixed_variable_cost':
      return {
        data,
        xAxis: 'date',
        position: 'date*amount',
        position2: 'date*count',
        yAxis2: 'count',
        customisedPadding: [20, 80, 100, 70],
        color: ['category'],
        color2: ['#1a1a1a'],
        cols: {
          amount: {
            formatter: (v) => `${numeral(v).format('0,0')}€`
          },
          count: {
            formatter: (v) => `${numeral(v).format('0,0')}`,
            alias: t('home.dash.legends.lineCount'),
            min: data && scalePadding(data).min,
            max: data && scalePadding(data).max
          },
          date: {
            formatter: (v) => moment(v).format('MM-YYYY')
          }
        },
        customTooltip: [
          'category*amount',
          (d, c) => ({
            name: t(`home.dash.legends.${d}`),
            value: `${numeral(c).format('0,0.00')}€`
          })
        ],
        customTooltip2: [
          'count',
          (o) => ({
            name: t(`home.dash.legends.lineCount`),
            value: `${numeral(o).format('0,0')}`
          })
        ],
        geomType: 'intervalStack',
        geomType2: 'line',
        geomSize2: 2
      };
    case 'sinister_cost_and_profit':
      monthList.forEach((month) => {
        const d = data.filter(({ _id }) => _id === month);
        const cost = d.find((el) => el.cost);
        const gain = d.find((el) => el.gain);
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          cost: (cost || {}).cost || 0,
          gain: (gain || {}).gain || 0
        });
      });
      return {
        data: tmpData,
        fields: ['cost', 'gain'],
        type: 'fold',
        key: 'costType',
        value: 'amount',
        xAxis: 'month',
        yAxis: 'amount',
        position: 'month*amount',
        /* cols: {
          amount: {
            formatter: (v) => `${numeral(v).format('0,0')}€`
          }
        }, */
        customTooltip: [
          'costType*amount',
          (c, a) => ({
            name: t(`home.dash.legends.${c}`),
            value: `${numeral(a).format('0,0.00')}€`
          })
        ],
        color: 'costType',
        geomType: 'line',
        withArea: true,
        geomSize: 2
      };
    case 'contract_cost_evolution':
      monthList.forEach((month) => {
        const d = data.find(({ _id }) => _id === month);
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          cost: (d || {}).amount
        });
      });
      return {
        data: tmpData,
        fields: ['cost'],
        type: 'fold',
        key: 'costType',
        value: 'amount',
        xAxis: 'month',
        yAxis: 'amount',
        position: 'month*amount',
        cols: {
          amount: {
            formatter: (v) => `${numeral(v).format('0,0')}€`,
            min: 0
          }
        },
        customTooltip: [
          'costType*amount',
          (c, a) => ({
            name: t(`home.dash.legends.${c}`),
            value: `${numeral(a).format('0,0.00')}€`
          })
        ],
        color: 'costType',
        geomType: 'line',
        withArea: true,
        geomSize: 2
      };
    case 'resale_plan': {
      let maxCount = 0;
      monthList.forEach((month) => {
        const d = data.count && data.count.find(({ _id }) => _id === month);
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          count: (d || {}).count,
          average:
            (data || {}).average &&
            (data || {}).average.length &&
            (data || {}).average[0].average
        });
        if (maxCount < (d || {}).count) maxCount = (d || {}).count;
      });
      maxCount = Math.round((maxCount * 1.1) / 100) * 100;

      return {
        data: tmpData,
        fields: ['count'],
        type: 'fold',
        key: 'count',
        value: 'count',
        xAxis: 'month',
        yAxis: 'count',
        position: 'month*count',
        cols: {
          count: {
            formatter: (v) => `${numeral(v).format('0,0')}`,
            max: maxCount,
            maxLimit: maxCount,
            min: 0
          },
          average: {
            max: maxCount,
            maxLimit: maxCount,
            min: 0
          }
        },
        customTooltip: [
          'count',
          (c) => ({
            name: t(`home.dash.legends.assetQuantity`),
            value: `${numeral(c).format('0,0')}`
          })
        ],
        geomType: 'intervalStack',
        geomType2: 'line',
        color2: '#007cba',
        position2: 'month*average',
        customTooltip2: [
          'average',
          (a) => ({
            name: t(`home.dash.legends.average`),
            value: `${numeral(a).format('0,0.00')}`
          })
        ]
      };
    }
    case 'stock_repartition':
      return {
        markers: data.map(({ name, stock, coordinates, totalAssets }) => {
          let r = stock.length ? (stock[0].count / totalAssets) * 20 : 0;
          if (r < 0.8 && r !== 0) r = 0.8;
          if (r > 4) r = 4;
          return {
            name,
            stock: stock.length ? stock[0].count : 0,
            coordinates,
            r,
            fill: green.primary
          };
        }),
        disabledHover: true,
        disabledPress: true,
        zoneTooltip: false
      };
    case 'carbone_impact_PC_evolution': {
      monthList.forEach((month) => {
        const d = data && data.find(({ _id }) => _id === month);
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          count: (d || {}).count || 0
        });
      });

      return {
        data: tmpData,
        fields: ['count'],
        type: 'fold',
        key: 'count',
        value: 'count',
        xAxis: 'month',
        yAxis: 'count',
        yTitle: {
          offset: 50,
          textStyle: {
            fontSize: '12',
            textAlign: 'center',
            fill: '#999',
            fontWeight: 'bold'
          }
        },
        position: 'month*count',
        cols: {
          count: {
            formatter: (v) => `${numeral(v / 1000).format('0,0')}k`,
            min: 0,
            alias: t('home.dash.carbone_impact_PC_evolution.yTitle')
          }
        },
        customTooltip: [
          'count',
          (c) => ({
            name: t(`home.dash.carbone_impact_PC_evolution.tooltip`),
            value: `${numeral(c).format('0,0')}`
          })
        ],
        geomType: 'line',
        withArea: true,
        geomSize: 2,
        customisedPadding: [20, 50, 80, 70]
      };
    }
    case 'carbone_impact_others_evolution': {
      monthList.forEach((month) => {
        const d = data && data.find(({ _id }) => _id === month);
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          count: (d || {}).count || 0
        });
      });

      return {
        data: tmpData,
        fields: ['count'],
        type: 'fold',
        key: 'count',
        value: 'count',
        xAxis: 'month',
        yAxis: 'count',
        yTitle: {
          offset: 50,
          textStyle: {
            fontSize: '12',
            textAlign: 'center',
            fill: '#999',
            fontWeight: 'bold'
          }
        },
        position: 'month*count',
        cols: {
          count: {
            formatter: (v) => `${numeral(v / 1000).format('0,0')}k`,
            min: 0,
            alias: t('home.dash.carbone_impact_PC_evolution.yTitle')
          }
        },
        customTooltip: [
          'count',
          (c) => ({
            name: t(`home.dash.carbone_impact_PC_evolution.tooltip`),
            value: `${numeral(c).format('0,0')}`
          })
        ],
        geomType: 'line',
        withArea: true,
        geomSize: 2,
        customisedPadding: [20, 50, 80, 70]
      };
    }
    case 'carbone_impact_total_evolution': {
      monthList.forEach((month) => {
        const d = data && data.find(({ _id }) => _id === month);
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          count: (d || {}).count || 0
        });
      });

      return {
        data: tmpData,
        fields: ['count'],
        type: 'fold',
        key: 'count',
        value: 'count',
        xAxis: 'month',
        yAxis: 'count',
        yTitle: {
          offset: 50,
          textStyle: {
            fontSize: '12',
            textAlign: 'center',
            fill: '#999',
            fontWeight: 'bold'
          }
        },
        position: 'month*count',
        cols: {
          count: {
            formatter: (v) => `${numeral(v / 1000).format('0,0')}k`,
            min: 0,
            alias: t('home.dash.carbone_impact_PC_evolution.yTitle')
          }
        },
        customTooltip: [
          'count',
          (c) => ({
            name: t(`home.dash.carbone_impact_PC_evolution.tooltip`),
            value: `${numeral(c).format('0,0')}`
          })
        ],
        geomType: 'line',
        withArea: true,
        geomSize: 2,
        customisedPadding: [20, 50, 80, 70]
      };
    }
    case 'certification_rate': {
      return {
        data,
        radius: 0.75,
        innerRadius: 0.6,
        field: 'rate',
        type: 'percent',
        dimension: 'certified',
        xAxis: 'rate',
        position: 'rate',
        color: 'certified',
        cols: {
          rate: {
            formatter: (v) => `${numeral(v).format('0,0.0')}%`
          }
        },
        customTooltip: [
          'certified*rate',
          (certified, v) => ({
            name: t(`home.dash.certification_rate.legends.${certified}`),
            value: `${numeral(v).format('0,0.0')}%`
          })
        ],
        customisedPadding: [50, 50, 50, 50],
        labelFormatter: (val, d) =>
          `${t(
            `home.dash.certification_rate.legends.${d.point.certified}`
          )}: ${val}`
      };
    }
    case 'medals_repartition': {
      return {
        data,
        radius: 0.75,
        innerRadius: 0.6,
        field: 'count',
        type: 'percent',
        dimension: 'medal',
        xAxis: 'count',
        position: 'count',
        color: 'medal',
        cols: {
          count: {
            formatter: (v) => `${numeral(v).format('0,0.0')}%`
          }
        },
        customTooltip: [
          'medal*count',
          (medal, v) => ({
            name: t(`home.dash.medals_repartition.legends.${medal}`),
            value: `${numeral(v).format('0,0.0')}%`
          })
        ],
        customisedPadding: [50, 50, 50, 50],
        labelFormatter: (val, d) =>
          `${t(
            `home.dash.medals_repartition.legends.${d.point.medal}`
          )}: ${val}`,
        itemFormatter: (item) =>
          t(`home.dash.medals_repartition.legends.${item}`),
        withLegend: true
      };
    }
    case 'medals_per_type': {
      return {
        data,
        cols: {
          count: {
            formatter: (v) => `${numeral(v).format('0,0.0')}%`
          }
        }
      };
    }
    case 'carbon_footprint_per_type': {
      return {
        data: data.filter((d) => d.average_footprint),
        radius: 0.75,
        innerRadius: 0.5,
        field: 'average_footprint',
        type: 'percent',
        dimension: 'genre',
        as: 'percent',
        xAxis: 'average_footprint',
        position: 'percent',
        color: 'genre',
        cols: {
          count: {
            formatter: (v) => `${numeral(v).format('0,0.0')}%`
          }
        },
        customTooltip: [
          'genre*percent',
          (genre, v) => ({
            name: t(`home.dash.legends.${genre}`),
            value: `${numeral(v * 100).format('0,0.0')}%`
          })
        ],
        customisedPadding: [50, 120, 100, 120],
        labelFormatter: (val, d) =>
          `${d.point.genre}: ${(val * 100).toFixed(2)}%`,
        withLegend: true
      };
    }
    case 'carbon_footprint_per_type_macro': {
      return {
        data: data.filter((d) => d.average_footprint),
        radius: 0.75,
        innerRadius: 0.5,
        field: 'average_footprint',
        type: 'percent',
        dimension: 'genre',
        as: 'percent',
        xAxis: 'average_footprint',
        position: 'percent',
        color: 'genre',
        cols: {
          count: {
            formatter: (v) => `${numeral(v).format('0,0.0')}%`
          }
        },
        customTooltip: [
          'genre*percent',
          (genre, v) => ({
            name: genre,
            value: `${numeral(v * 100).format('0,0.0')}%`
          })
        ],
        customisedPadding: [50, 120, 100, 120],
        labelFormatter: (val, d) =>
          `${d.point.genre}: ${(val * 100).toFixed(2)}%`,
        withLegend: true
      };
    }
    case 'carbone_impact_fabrication_per_brand_making': {
      tmpData = [];
      data.forEach((element) => {
        tmpData.push({
          brand: element._id,
          value: element.value
        });
      });
      return {
        data: tmpData.sort((a, b) => a.value - b.value),
        fields: ['value'],
        type: 'fold',
        key: 'value',
        value: 'value',
        xAxis: 'brand',
        yAxis: 'value',
        yTitle: {
          offset: 50,
          textStyle: {
            fontSize: '12',
            textAlign: 'center',
            fill: '#999',
            fontWeight: 'bold'
          }
        },
        position: 'brand*value',
        cols: {
          value: {
            formatter: (v) => `${numeral(v).format('0,0')}`,
            min: 0,
            alias: t(
              'home.dash.top_carbone_impact_fabrication_per_brand_making.yTitle'
            )
          }
        },
        customTooltip: [
          'value',
          (c) => ({
            name: t(
              `home.dash.top_carbone_impact_fabrication_per_brand_making.tooltip`
            ),
            value: `${numeral(c).format('0,0[.]0')}`
          })
        ],
        geomType: 'interval',
        color: '#ee6600',
        customisedPadding: [20, 50, 80, 60]
      };
    }
    case 'carbone_impact_fabrication_per_brand_making_macro': {
      tmpData = [];
      data.forEach((element) => {
        tmpData.push({
          brand: element._id,
          value: element.value
        });
      });
      return {
        data: tmpData.sort((a, b) => a.value - b.value),
        fields: ['value'],
        type: 'fold',
        key: 'value',
        value: 'value',
        xAxis: 'brand',
        yAxis: 'value',
        yTitle: {
          offset: 50,
          textStyle: {
            fontSize: '12',
            textAlign: 'center',
            fill: '#999',
            fontWeight: 'bold'
          }
        },
        position: 'brand*value',
        cols: {
          value: {
            formatter: (v) => `${numeral(v / 1000).format('0,0')}K`,
            min: 0,
            alias: t(
              'home.dash.top_carbone_impact_fabrication_per_brand_making.yTitle'
            )
          }
        },
        customTooltip: [
          'value',
          (c) => ({
            name: t(
              `home.dash.top_carbone_impact_fabrication_per_brand_making.tooltip`
            ),
            value: `${numeral(c).format('0,0')}`
          })
        ],
        geomType: 'interval',
        color: '#ee6600',
        customisedPadding: [20, 50, 80, 60]
      };
    }
    case 'carbone_impact_utilisation_per_brand_making': {
      tmpData = [];
      let valeur_couleur = 0;
      data.forEach((element) => {
        valeur_couleur += element.value;
        tmpData.push({
          brand: element._id,
          value: element.value
        });
      });
      valeur_couleur /= 10;
      return {
        data: tmpData.reverse(),
        fields: ['value'],
        type: 'fold',
        key: 'value',
        value: 'value',
        xAxis: 'brand',
        yAxis: 'value',
        yTitle: {
          offset: 50,
          textStyle: {
            fontSize: '12',
            textAlign: 'center',
            fill: '#999',
            fontWeight: 'bold'
          }
        },
        position: 'brand*value',
        cols: {
          value: {
            formatter: (v) => `${v}`,
            min: 0,
            alias: t(
              'home.dash.top_carbone_impact_utilisation_per_brand_making.yTitle'
            )
          }
        },
        customTooltip: [
          'value',
          (c) => ({
            name: t(
              `home.dash.top_carbone_impact_utilisation_per_brand_making.tooltip`
            ),
            value: `${numeral(c).format('0.0')}`
          })
        ],
        geomType: 'interval',
        color: [
          'value',
          (value) => {
            if (value < valeur_couleur / 2) return '#b3e8b7';
            if (value < valeur_couleur) return '#83c989';
            if (value < (3 * valeur_couleur) / 2) return '#63b06a';
            if (value < 2 * valeur_couleur) return '#2f8739';
            return '#048012';
          }
        ],
        customisedPadding: [20, 50, 80, 60]
      };
    }
    case 'recycled_weight_evolution': {
      monthList.forEach((month) => {
        const d = data && data.find(({ _id }) => _id === month);
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          count: (d || {}).count || 0
        });
      });
      return {
        data: tmpData,
        fields: ['count'],
        type: 'fold',
        key: 'count',
        value: 'count',
        xAxis: 'month',
        yAxis: 'count',
        yTitle: {
          offset: 45,
          textStyle: {
            fontSize: '12',
            textAlign: 'center',
            fill: '#999',
            fontWeight: 'bold'
          }
        },
        position: 'month*count',
        cols: {
          count: {
            formatter: (v) => `${numeral(v).format('0,0')}`,
            min: 0,
            alias: t('home.dash.recycled_weight_evolution.yTitle')
          }
        },
        customTooltip: [
          'count',
          (c) => ({
            name: t(`home.dash.recycled_weight_evolution.tooltip`),
            value: `${numeral(c).format('0,0')}`
          })
        ],
        geomType: 'interval',
        withArea: true,
        customisedPadding: [20, 50, 80, 60]
      };
    }
    case 'recycled_vs_reused':
      monthList.forEach((month) => {
        const d = data.filter(({ _id }) => _id === month);
        const recycled = d.find((el) => el.recycled);
        const reused = d.find((el) => el.reused);
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          recycled: (recycled || {}).recycled || 0,
          reused: (reused || {}).reused || 0
        });
      });
      return {
        data: tmpData,
        fields: ['recycled', 'reused'],
        type: 'fold',
        key: 'costType',
        value: 'amount',
        xAxis: 'month',
        yAxis: 'amount',
        position: 'month*amount',
        customTooltip: [
          'costType*amount',
          (c, a) => ({
            name: t(`home.dash.legends.${c}`),
            value: `${numeral(a).format('0,0')}`
          })
        ],
        color: 'costType',
        geomType: 'line',
        geomSize: 2
      };
    case 'valorisation_evolution': {
      monthList.forEach((month) => {
        const d = data && data.find(({ _id }) => _id === month);
        tmpData.push({
          month: moment(month).format('MM-YYYY'),
          count: (d || {}).count || 0
        });
      });
      return {
        data: tmpData,
        fields: ['count'],
        type: 'fold',
        key: 'count',
        value: 'count',
        xAxis: 'month',
        yAxis: 'count',
        yTitle: {
          offset: 45,
          textStyle: {
            fontSize: '12',
            textAlign: 'center',
            fill: '#999',
            fontWeight: 'bold'
          }
        },
        position: 'month*count',
        cols: {
          count: {
            formatter: (v) => `${numeral(v / 1000).format('0,0')}k`,
            min: 0,
            alias: ' '
          }
        },
        customTooltip: [
          'count',
          (c) => ({
            name: t(`home.dash.valorisation_evolution.tooltip`),
            value: `${numeral(c).format('0,0')}`
          })
        ],
        geomType: 'interval',
        withArea: true,
        customisedPadding: [20, 50, 80, 60]
      };
    }
    case 'stock_out_quality_and_quantity':
      return {};
    default:
      return {};
  }
};

export default chartsElementsReducer;
