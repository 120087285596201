import React from 'react';
import { DatePicker, Divider, Form, Select, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const IncidentDetailsForm = ({
  ticketType,
  getFieldDecorator,
  isPeopleLoading,
  getCompanyPeople,
  companyPeople
}) => {
  const { t } = useTranslation();
  return (
    ['THEFT', 'LOST', 'DAMAGE'].includes(ticketType) && (
      <>
        <Divider orientation="left">
          {t('tickets.fields.divider.incidentDetails')}
        </Divider>
        <Form.Item label={t('tickets.fields.incident_date')}>
          {getFieldDecorator('incident_date', {
            rules: [
              {
                required: ['THEFT', 'LOST', 'DAMAGE'].includes(ticketType),
                message: t('global.form.requiredMessage')
              }
            ]
          })(<DatePicker />)}
        </Form.Item>
        <Form.Item label={t('tickets.fields.incident_user')}>
          {getFieldDecorator('incident_user', {
            rules: [
              {
                required: ['THEFT', 'LOST', 'DAMAGE'].includes(ticketType),
                message: t('global.form.requiredMessage')
              }
            ]
          })(
            <Select
              allowClear
              notFoundContent={null}
              loading={isPeopleLoading}
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(value) => getCompanyPeople(value)}
              style={{ width: 300 }}
            >
              {companyPeople.map(({ _id, first_name, last_name, job }) => (
                <Option value={_id} key={_id}>
                  {`${first_name} ${last_name} - ${job || 'N/C'}`}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t('tickets.fields.compensation_amount')}>
          {getFieldDecorator('compensation_amount', {})(<InputNumber />)}
        </Form.Item>
        <Form.Item label={t('tickets.fields.partial_compensation_amount')}>
          {getFieldDecorator('partial_compensation_amount', {})(
            <InputNumber />
          )}
        </Form.Item>
        <Form.Item label={t('tickets.fields.compensation_reception_date')}>
          {getFieldDecorator('compensation_reception_date', {})(<DatePicker />)}
        </Form.Item>
        <Form.Item label={t('tickets.fields.deductible_amount_reception_date')}>
          {getFieldDecorator('deductible_amount_reception_date', {})(
            <DatePicker />
          )}
        </Form.Item>
      </>
    )
  );
};

IncidentDetailsForm.propTypes = {
  ticketType: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  isPeopleLoading: PropTypes.bool.isRequired,
  getCompanyPeople: PropTypes.func.isRequired,
  companyPeople: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    job: PropTypes.string
  }).isRequired
};

export default IncidentDetailsForm;
