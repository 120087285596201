import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { PageHeader, Icon } from 'antd';

const PageHeaderCustom = ({
  title,
  extra,
  pathOverride,
  withBreadCrumb,
  children
}) => {
  const { type } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const splitPathname = pathname.split('/');
  const history = useHistory();
  const dynamicRoutes = [
    {
      path: '/',
      breadcrumbName: <Icon type="home" />
    },
    ...(pathOverride || (splitPathname[1] && splitPathname[1] !== '')
      ? [
          {
            path: pathOverride ? pathOverride.path : `/${splitPathname[1]}`,
            breadcrumbName: pathOverride
              ? pathOverride.name
              : t(`${splitPathname[1]}.title`)
          }
        ]
      : []),
    ...((splitPathname[2] && splitPathname[2] !== '') || type
      ? [
          {
            path: type
              ? `/${splitPathname[1]}/${type}`
              : `/${splitPathname[2]}`,
            breadcrumbName: type
              ? t(`${type}.title`)
              : t(`breadcrumbs.${splitPathname[2]}`)
          }
        ]
      : []),
    ...(type && splitPathname[3]
      ? [{ breadcrumbName: t(`breadcrumbs.${splitPathname[3]}`) }]
      : [])
  ];

  const itemRender = (
    // eslint-disable-next-line react/prop-types
    route,
    params,
    routesList
  ) => {
    const { path, breadcrumbName, state, search } = route;
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={path}>{breadcrumbName}</span>
    ) : (
      <Link key={path} to={{ pathname: path, state, search }}>
        {breadcrumbName}
      </Link>
    );
  };

  return (
    <PageHeader
      breadcrumb={withBreadCrumb ? { routes: dynamicRoutes, itemRender } : {}}
      title={title}
      style={{
        position: 'fixed',
        zIndex: 8,
        width: 'calc(100% - 256px)'
      }}
      onBack={() => history.goBack()}
      ghost={false}
      extra={extra}
    >
      {children}
    </PageHeader>
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.string.isRequired,
  extra: PropTypes.element,
  pathOverride: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string
  }),
  withBreadCrumb: PropTypes.bool
};

PageHeaderCustom.defaultProps = {
  extra: null,
  pathOverride: null,
  withBreadCrumb: true
};

export default PageHeaderCustom;
