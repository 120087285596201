import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ResourceLandingLayout } from '../../layouts';

const ListPeople = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'id_number',
      key: 'id_number'
    },
    {
      label: 'site',
      key: 'site'
    },
    {
      label: 'company',
      key: 'company.name'
    },
    {
      label: 'job',
      key: 'job'
    },
    {
      label: 'job_category',
      key: 'job_category'
    },
    {
      label: 'contract_type',
      key: 'contract_type'
    }
  ];

  return (
    <ResourceLandingLayout
      customActionColumn
      withCreateButton={false}
      withMenu={false}
      resourceName="people"
      populate="company"
      exportUrl="people?populate=company"
      headers={headers}
      columns={[
        {
          title: t('people.list.column.employeeNumber'),
          dataIndex: 'id_number',
          sorter: true
        },
        {
          title: t('people.list.column.first_name'),
          key: 'last_name',
          sorter: true,
          render: (person) => (
            <Typography>
              {`${person.first_name || '-'} ${person.last_name || '-'}`}
            </Typography>
          )
        },
        {
          title: t('people.list.column.job_type'),
          dataIndex: 'job_type',
          sorter: true
        }
      ]}
    />
  );
};

export default ListPeople;
