const headers = [
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'number',
    key: 'number'
  },
  {
    label: 'amendment_number',
    key: 'amendment_number'
  },
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'start_date',
    key: 'start_date'
  },
  {
    label: 'end_date',
    key: 'end_date'
  },
  {
    label: 'duration',
    key: 'duration'
  },
  {
    label: 'periodicity',
    key: 'periodicity'
  },
  {
    label: 'linear',
    key: 'linear'
  },
  {
    label: 'refinancer',
    key: 'refinancer.name'
  },
  {
    label: 'residual_value',
    key: 'residual_value'
  },
  {
    label: 'EUA',
    key: 'EUA'
  },
  {
    label: 'amount_EUA',
    key: 'amount_EUA'
  },
  {
    label: 'termination_delay',
    key: 'termination_delay'
  },
  {
    label: 'periodicity_extension',
    key: 'periodicity_extension'
  },
  {
    label: 'cession_rate',
    key: 'cession_rate'
  },
  {
    label: 'client_rate',
    key: 'client_rate'
  },
  {
    label: 'suppliers',
    key: 'suppliers'
  },
  {
    label: 'customer',
    key: 'customer.name'
  },
  {
    label: 'annual_total_amount',
    key: 'annual_total_amount'
  },
  {
    label: 'sinister_cost',
    key: 'sinister_cost'
  },
  {
    label: 'total_amount_excluding_taxes',
    key: 'total_amount_excluding_taxes'
  },
  {
    label: 'VAT',
    key: 'VAT'
  },
  {
    label: 'total_amount',
    key: 'total_amount'
  }
];

export default headers;
