import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Tag } from 'antd';
import moment from 'moment';
import headers from './headers';
import '../../layouts/TableLayout/style.css';
import { contractTypeColor } from '../../utils';
import { ResourceLandingLayout } from '../../layouts';

const ListContracts = () => {
  const { t } = useTranslation();

  return (
    <ResourceLandingLayout
      customActionColumn
      withCreateButton={false}
      headers={headers}
      resourceName="contracts"
      extraQuery="!amendment_number"
      columns={[
        {
          title: t('contracts.list.column.number'),
          dataIndex: 'number',
          sorter: true
        },
        {
          title: t('contracts.list.column.type'),
          dataIndex: 'type',
          sorter: true,
          render: (type) => (
            <Tag color={contractTypeColor[type]}>
              {t(`contracts.form.options.type.${type}`).toUpperCase()}
            </Tag>
          )
        },
        {
          title: t('contracts.list.column.dates'),
          key: 'start_date',
          sorter: true,
          render: (record) => (
            <Typography>
              {`${moment(record.start_date).format('LL') || '-'} - ${moment(
                record.end_date
              ).format('LL') || '-'}`}
            </Typography>
          )
        }
      ]}
    />
  );
};

export default ListContracts;
