/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Collapse,
  DatePicker,
  Icon,
  Input,
  message,
  Row,
  Table,
  Tag,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import { assetsStatusColor } from '../../utils';

const { Text } = Typography;
const { Column } = Table;
const { Panel } = Collapse;
const { Search } = Input;
const { RangePicker } = DatePicker;

const AssetsTab = ({ contractId, history }) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [assets, setAssets] = useState([]);
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });
  const [rangeDates, setRangeDates] = useState([]);

  const fetchAssets = async (page = pagination, value, dates) => {
    const { pageSize, current } = page;
    const populate = '&populate=people,phone_contract';

    try {
      let result = {};
      if (dates || rangeDates.length > 0) {
        result = await dispatchAPI('GET', {
          url: `/assets?contract=${contractId}&replacement_date>=${
            dates ? dates[0] : rangeDates[0]
          }&replacement_date<=${
            dates ? dates[1] : rangeDates[1]
          }&limit=${pageSize}&skip=${(current - 1) * pageSize}${populate}`
        });
      } else if (value) {
        result = await dispatchAPI('GET', {
          url: `/assets?contract=${contractId}&filter={"$text": {"$search":"${value}"}}&limit=${pageSize}&skip=${(current -
            1) *
            pageSize}${populate}`
        });
      } else {
        result = await dispatchAPI('GET', {
          url: `/assets?contract=${contractId}&limit=${pageSize}&skip=${(current -
            1) *
            pageSize}${populate}`
        });
      }
      // eslint-disable-next-line no-underscore-dangle
      setAssets(result.data.map((asset) => ({ ...asset, key: asset._id })));
      setPagination({
        ...page,
        total: parseInt(result.headers['x-total-count'], 10)
      });
    } catch (e) {
      console.log(e);
      message.error(e.message);
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    fetchAssets(page);
  };

  const onDateChange = (date, dateString) => {
    setRangeDates(dateString);
    fetchAssets({ ...pagination, page: 1 }, null, dateString);
  };

  useEffect(() => {
    setRangeDates([]);
    fetchAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Collapse
        style={{ margin: '20px 0' }}
        expandIcon={() => (
          <Typography>
            Options
            <Icon
              style={{ marginLeft: '10px', fontSize: '18px' }}
              type="setting"
            />
          </Typography>
        )}
        expandIconPosition="right"
        bordered={false}
      >
        <Panel key="options" style={{ border: '0', cursor: 'default' }}>
          <Row gutter={36}>
            <Col offset={2} span={8}>
              <Row>
                <Text strong style={{ marginBottom: '10px' }}>
                  Replacement Date range:
                </Text>
              </Row>
              <Row>
                <RangePicker
                  style={{ width: '100%' }}
                  onChange={onDateChange}
                />
              </Row>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Row style={{ margin: '10px 0' }} gutter={16}>
        <Col offset={16} span={8}>
          <Search
            placeholder="Type your keyword"
            onSearch={(value) => fetchAssets(1, value)}
            style={{ width: '100%', float: 'right' }}
          />
        </Col>
      </Row>
      <Table
        rowClassName="rowStyle"
        onRow={({ type, key }) => ({
          onClick: () => history.push(`/assets/${type}/show/${key}`)
        })}
        dataSource={assets}
        loading={isLoading}
        onChange={handlePageChange}
        pagination={pagination}
      >
        <Column
          title={t('assets.list.column.identifier')}
          key="nserie"
          render={(asset) => asset.serial_number || asset.phone_Number}
        />
        <Column
          title={t('assets.list.column.type')}
          key="type"
          render={(asset) => asset.type}
        />
        <Column
          title={t('assets.list.column.label')}
          key="label"
          render={({ description, phone_contract }) =>
            description || (phone_contract ? phone_contract.name : '-')
          }
        />
        <Column
          title={t('assets.list.column.status')}
          dataIndex="status"
          render={(status) => (
            <Tag color={assetsStatusColor[status]}>{status || ' - '}</Tag>
          )}
        />
        <Column
          title={t('assets.list.column.people')}
          dataIndex="people"
          render={(people) =>
            people
              ? `${people.first_name || ' - '} ${people.last_name || ' - '}`
              : ' - '
          }
        />
      </Table>
    </>
  );
};

AssetsTab.propTypes = {
  contractId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default AssetsTab;
