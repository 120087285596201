import React, { useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Tooltip, Coord, Label, View, Legend } from 'bizcharts';
import axios from 'axios';
import DataSet from '@antv/data-set';
import { Empty, Icon, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import chartsElementsReducer from '../chartsElementsReducer';
import useAuthContext from '../../../contexts/AuthContext';
import useFiltersContext from '../context/DashboardContext';
import useLanguageContext from '../../../contexts/LanguageContext';

const ThreelevelsPieChart = ({ title, purpose }) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const {
    timeScale,
    filters: { provider, device, subsidiaries, genre, owner }
  } = useFiltersContext();
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { endPoint, resource } = purpose || {};
  const [chartsElements, dispatch] = useReducer(chartsElementsReducer, {
    isLoading: false
  });
  const { DataView } = DataSet;

  let CancelToken;
  let cancel;

  const { data, cols } = chartsElements;

  const formatData = useCallback(async () => {
    CancelToken = axios.CancelToken;
    const fetchData = async () => {
      setIsLoading(true);
      let results;

      let providerUrl;
      if (provider) providerUrl = `&provider=${provider}`;

      let deviceUrl;
      if (device.length) deviceUrl = `&devices=${device}`;

      let genreUrl;
      if (genre.length) genreUrl = `&genre=${genre}`;

      let ownerUrl;
      if (owner) ownerUrl = `&owner=${owner}`;

      let subsidiariesUrl;
      if (subsidiaries.length)
        subsidiariesUrl = `&subsidiaries=${subsidiaries}`;

      try {
        results = await dispatchAPI('GET', {
          url: `/dashboards/${resource}/${endPoint}?start_date=${
            timeScale[0]
          }&end_date=${timeScale[1]}${providerUrl || ''}${ownerUrl ||
            ''}${deviceUrl || ''}${genreUrl || ''}${subsidiariesUrl || ''}`,
          cancelToken: new CancelToken((c) => {
            cancel = c;
          })
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        if (axios.isCancel(e)) {
          return [];
        }
      }
      setIsLoading(false);
      return (results || {}).data;
    };
    dispatch({
      type: endPoint,
      payload: {
        data: (await fetchData()) || [],
        t,
        timeScale
      }
    });
  }, [
    resource,
    endPoint,
    timeScale,
    device,
    genre,
    owner,
    subsidiaries,
    provider,
    locale
  ]);

  useEffect(() => {
    formatData();
    return () => {
      if (cancel) cancel();
    };
  }, [formatData]);

  const dv = new DataView();
  dv.source(data || []).transform({
    type: 'percent',
    field: 'count',
    dimension: 'epeat_medal',
    as: 'percent'
  });

  const dv1 = new DataView();
  dv1.source(data || []).transform({
    type: 'percent',
    field: 'count',
    dimension: 'genre',
    as: 'percent'
  });
  const dv2 = new DataView();
  dv2.source(data || []).transform({
    type: 'percent',
    field: 'count',
    dimension: 'brand',
    as: 'percent'
  });

  return (
    <>
      <Typography.Title style={{ textAlign: 'center' }} level={4}>
        {title}
      </Typography.Title>
      <LazyLoad
        debounce={500}
        height={100}
        placeholder={
          <Spin size="large" spinning>
            <div style={{ height: 500, width: '100%' }} />
          </Spin>
        }
      >
        <Spin spinning={isLoading} size="large">
          <Chart
            data={dv}
            scale={cols}
            padding={[45, 45, 45, 45]}
            forceFit
            height={450}
            placeholder={
              <Empty
                style={{ height: '100%' }}
                description={t('home.nodata')}
                imageStyle={{ height: '90%' }}
                image={
                  <Icon
                    style={{ fontSize: 350, color: '#E0E0E0' }}
                    type="pie-chart"
                  />
                }
              />
            }
          >
            <Coord type="theta" radius={0.35} />
            <Tooltip
              showTitle={false}
              itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{certifications}: {value}</li>'
            />
            <Geom
              type="intervalStack"
              position="percent"
              color="epeat_medal"
              tooltip={[
                'epeat_medal*percent',
                (item, percent) => ({
                  certifications: item,
                  value: `${(percent * 100).toFixed(2)}%`
                })
              ]}
              style={{
                lineWidth: 1,
                stroke: '#fff'
              }}
              select={false}
            >
              <Label content="epeat_medal" offset={-20} />
            </Geom>
            <Legend position="bottom" />
            <View data={dv1} scale={cols}>
              <Coord type="theta" radius={0.7} innerRadius={0.35 / 0.7} />
              <Geom
                type="intervalStack"
                position="percent"
                color="epeat_medal"
                tooltip={[
                  'genre*percent',
                  (item, percent) => ({
                    certifications: item.split('-')[0],
                    value: `${(percent * 100).toFixed(2)}%`
                  })
                ]}
                style={{
                  lineWidth: 1,
                  stroke: '#fff'
                }}
                select={false}
              >
                <Label
                  content="genre"
                  formatter={(text) => `${text.split('-')[0]}`}
                  offset={-10}
                />
              </Geom>
            </View>
            <View data={dv2} scale={cols}>
              <Coord type="theta" radius={1} innerRadius={0.7 / 1} />
              <Geom
                type="intervalStack"
                position="percent"
                opacity={0.6}
                color="epeat_medal"
                tooltip={[
                  'brand*percent',
                  (item, percent) => ({
                    certifications: item.split('-')[0],
                    value: `${(percent * 100).toFixed(2)}%`
                  })
                ]}
                style={{
                  lineWidth: 1,
                  stroke: '#fff'
                }}
                select={false}
              >
                <Label
                  content="brand"
                  formatter={(text) => `${text.split('-')[0]}`}
                  offset={-10}
                />
              </Geom>
            </View>
          </Chart>
        </Spin>
      </LazyLoad>
    </>
  );
};
ThreelevelsPieChart.propTypes = {
  title: PropTypes.string.isRequired,
  purpose: PropTypes.shape({}).isRequired
};
export default ThreelevelsPieChart;
