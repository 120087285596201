import React from 'react';
import { useTranslation } from 'react-i18next';

const usePwdPattern = () => {
  const { t } = useTranslation();

  return [
    {
      pattern: new RegExp(/(?=.*[0-9])/),
      message: <p style={{ margin: 0, padding: 0 }}>{t('login.pwdDigit')}</p>
    },
    {
      pattern: new RegExp(/(?=.*[a-z])/),
      message: (
        <p style={{ margin: 0, padding: 0 }}>{t('login.pwdLowerCaseChar')}</p>
      )
    },
    {
      pattern: new RegExp(/(?=.*[A-Z])/),
      message: (
        <p style={{ margin: 0, padding: 0 }}>{t('login.pwdUpperCaseChar')}</p>
      )
    },
    {
      pattern: new RegExp(/(?=.*[!@#$%^&*])/),
      message: (
        <p style={{ margin: 0, padding: 0 }}>{t('login.pwdSpecialChar')}</p>
      )
    },
    {
      pattern: new RegExp(/.{8,32}/),
      message: <p style={{ margin: 0, padding: 0 }}>{t('login.pwdLength')}</p>
    }
  ];
};

export default usePwdPattern;
