import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DatePickerFooter = ({ dateField, handleDateFieldChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Radio.Group
        buttonStyle="solid"
        size="small"
        value={dateField}
        onChange={(e) => handleDateFieldChange(e.target.value)}
      >
        <Radio.Button value="entry_date">
          {t(`header.menu.filteredTab.entryDateAssets`)}
        </Radio.Button>
        <Radio.Button value="exit_date">
          {t(`header.menu.filteredTab.exitDateAssets`)}
        </Radio.Button>
        <Radio.Button value="delivery_date">
          {t(`header.menu.filteredTab.deliveryDateAssets`)}
        </Radio.Button>
        <Radio.Button value="replacement_date">
          {t(`header.menu.filteredTab.replacementDateAssets`)}
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

DatePickerFooter.propTypes = {
  dateField: PropTypes.string.isRequired,
  handleDateFieldChange: PropTypes.func.isRequired
};

export default DatePickerFooter;
