import uuid from 'uuid/v1';
import { message } from 'antd';
import PropTypes from 'prop-types';

export const defaultLoading = {
  orderIsLoading: false,
  companiesAreLoading: false,
  labelsAreLoading: false,
  contractsAreLoading: false,
  catalogIsLoading: false
};
export const defaultFieldsLoading = (loading) => {
  const {
    labelsAreLoading,
    typesAreLoading,
    companiesAreLoading,
    contractsAreLoading
  } = loading;
  return {
    labels: labelsAreLoading,
    types: typesAreLoading,
    companies: companiesAreLoading,
    contracts: contractsAreLoading
  };
};
export const defaultCalculatedValues = {
  article: null,
  quantity: 0,
  price: 0,
  vat: 20,
  discount: 0
};
export const spinStyle = {
  textAlign: 'center',
  borderRadius: '4px',
  marginBottom: '20px',
  padding: '30px 50px',
  margin: '20px 0',
  width: '100%',
  height: '100%'
};

export const makeArticle = (value) => {
  return {
    articleLabel: `addArticle.articles.articleLabel.${value}`,
    quantityLabel: `addArticle.articles.quantityLabel.${value}`,
    priceLabel: `addArticle.articles.priceLabel.${value}`,
    vatLabel: `addArticle.articles.vatLabel.${value}`,
    discountLabel: `addArticle.articles.discountLabel.${value}`,
    descriptionLabel: `addArticle.articles.descriptionLabel.${value}`,
    articleId: uuid()
  };
};
export const articlesSummer = (fieldsValue) => {
  const articlesTmp = fieldsValue.addArticle.articles;
  const { quantityLabel, priceLabel, vatLabel, discountLabel } = articlesTmp;
  const qty = Object.values(articlesTmp)[1].reduce((tt, num) => tt + num);
  let price = 0;
  let vat = 0;
  let discount = 0;
  quantityLabel.forEach((art, idx) => {
    price += art * priceLabel[idx];
    discount +=
      priceLabel[idx] * quantityLabel[idx] * ((discountLabel[idx] || 0) / 100);
    vat +=
      // price
      (priceLabel[idx] * quantityLabel[idx] -
        // discount
        priceLabel[idx] *
          quantityLabel[idx] *
          ((discountLabel[idx] || 0) / 100)) *
      // vat ratio
      (vatLabel[idx] / 100);
  });

  return {
    total_quantity: qty || 0,
    total_ht_amount: parseFloat(price.toFixed(2)) || 0,
    tva_amount: parseFloat(vat.toFixed(2)) || 0,
    total_discount: parseFloat(discount.toFixed(2)) || 0,
    ttc_amount: parseFloat((price + vat).toFixed(2)) || 0
  };
};
export const handleSubmitUtil = (
  validateFieldsAndScroll,
  dispatchAPI,
  history,
  id,
  t
) => {
  const createOrder = async (values) => {
    const body = { ...values };
    try {
      await dispatchAPI('POST', { url: '/orders', body });
      message.success(t('orders.successCreation'));
      history.goBack();
    } catch (e) {
      message.error('One or more fields are not valid');
    }
  };
  const updateOrder = async (values) => {
    const body = {
      ...values
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/orders/${id}`,
        body
      });
      message.success(t('orders.successEdit'));
      history.goBack();
    } catch (e) {
      message.error('One or more fields are not valid');
    }
  };

  validateFieldsAndScroll(async (err, values) => {
    if (!err) {
      const { addArticle, ...rest } = values;
      const artcls = [];
      if (addArticle) {
        const {
          articleLabel,
          priceLabel,
          vatLabel,
          discountLabel,
          descriptionLabel,
          quantityLabel
        } = addArticle.articles;
        for (let i = 0; i < articleLabel.length; i += 1) {
          artcls.push({
            catalog_label: articleLabel[i],
            unit_price: priceLabel[i],
            VAT: vatLabel[i],
            discount: discountLabel[i],
            description: descriptionLabel[i],
            quantity: quantityLabel[i]
          });
        }
        const orderValues = {
          ...rest,
          articles: artcls
        };
        if (id) {
          await updateOrder(orderValues);
        } else if (!id) {
          await createOrder(orderValues);
        }
      } else {
        message.error(t('orders.form.noArticles'));
      }
    }
  });
};

export const defaultProps = {
  initialType: null,
  id: null,
  history: null,
  seller: null,
  buyer: null,
  total: null
};

export const PropTypesDeclaration = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    getFieldProps: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  initialType: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  seller: PropTypes.string,
  buyer: PropTypes.string,
  total: PropTypes.number,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  formItemLayout: PropTypes.shape({}).isRequired,
  tailFormItemLayout: PropTypes.shape({}).isRequired
};
