import React from 'react';
import { Divider, Form, Select, Input, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noLabelLayout } from './utils';

const { Option } = Select;
const ContactForm = ({
  peopleId,
  getFieldDecorator,
  isPeopleLoading,
  getCompanyPeople,
  companyPeople
}) => {
  const { t } = useTranslation();
  const prefixSelector = getFieldDecorator(
    'contact.phone_number.country_code',
    {
      initialValue: '+33'
    }
  )(
    <Select style={{ width: 70 }}>
      <Option value="+33">+33</Option>
    </Select>
  );
  return (
    <>
      <Divider orientation="left">
        {t('tickets.fields.divider.contact')}
      </Divider>
      <Form.Item label={t('tickets.fields.contact')}>
        {getFieldDecorator('peopleId')(
          <Select
            allowClear
            notFoundContent={null}
            loading={isPeopleLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={(value) => getCompanyPeople(value)}
            style={{ width: 300 }}
          >
            {companyPeople.map(({ _id, first_name, last_name, job }) => (
              <Option value={_id} key={_id}>
                {`${first_name} ${last_name} - ${job || 'N/C'}`}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('tickets.fields.name')}>
        {getFieldDecorator('contact.name', {
          rules: [{ required: true, message: t('global.form.requiredMessage') }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('tickets.fields.phoneNumber')}>
        {getFieldDecorator('contact.phone_number.number', {
          rules: [{ required: true, message: t('global.form.requiredMessage') }]
        })(<Input addonBefore={prefixSelector} />)}
      </Form.Item>
      <Form.Item label={t('tickets.fields.email')}>
        {getFieldDecorator('contact.email', {
          rules: [
            { required: true, message: t('global.form.requiredMessage') },
            { type: 'email', message: t('global.form.typeMessage') }
          ]
        })(<Input />)}
      </Form.Item>
      {peopleId && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Form.Item {...noLabelLayout}>
          {getFieldDecorator('updatePeople', {
            valuePropName: 'checked'
          })(<Checkbox>{t(`tickets.fields.updateContact`)}</Checkbox>)}
        </Form.Item>
      )}
      <Form.Item label={t('tickets.fields.comment')}>
        {getFieldDecorator('comment')(<Input.TextArea rows={6} />)}
      </Form.Item>
    </>
  );
};

ContactForm.propTypes = {
  peopleId: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  isPeopleLoading: PropTypes.bool.isRequired,
  getCompanyPeople: PropTypes.func.isRequired,
  companyPeople: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      job: PropTypes.string
    })
  ).isRequired
};

export default ContactForm;
