import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { message, Table, Typography, Tag, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import RelatedItems from './RelatedItems';
import DescriptionList from '../../components/descriptionList/DescriptionList';
import list from './informationList';
import UploadDrawer from '../../components/uploadDrawer';
import { contractTypeColor } from '../../utils';
import { ContentCustom, PageHeaderCustom } from '../../components';

const { Column } = Table;

const ShowContract = () => {
  const history = useHistory();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { contractList } = list;
  const [contract, setContract] = useState([]);
  const [invoices, setInvoices] = useState(null);
  const [parties, setParties] = useState(null);
  const [badgeCount, setBadgeCount] = useState(0);
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true
  });
  const amendment = !!contract.amendment_number;

  const getContract = async (contractId = id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/${contractId}?populate=assets,suppliers,customer,leaser,files,amendments,invoices`
      });
      setContract(data);
      setInvoices(data.invoices);
      setParties({
        ...parties,
        customer: data.customer,
        leaser: data.leaser,
        suppliers: data.suppliers
      });
      if (data.files && data.files.length) {
        setBadgeCount(data.files.length);
      } else setBadgeCount(0);
    } catch (e) {
      if (e.response) {
        message.error('Wrong username or password.');
      } else if (e.request) {
        message.error('Network error. Please check your Internet connexion.');
      } else {
        message.error('Oops, something bad happens.');
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      (async () => {
        await getContract();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <PageHeaderCustom title={t('contracts.showContract.title')} />
      <ContentCustom padding={false}>
        <div
          style={{
            padding: 24,
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            width: '100%'
          }}
        >
          <Skeleton loading={isLoading} paragraph={{ rows: 9 }} active>
            <DescriptionList data={contractList(contract, t)} />
          </Skeleton>
          <UploadDrawer
            badgeCount={badgeCount}
            getResource={getContract}
            resourceFiles={contract.files}
            resourceId={id}
            resourceName="contracts"
          />
        </div>
      </ContentCustom>
      {!amendment && (
        <ContentCustom marginTop={8}>
          <Typography.Title level={4}>
            {t('contracts.showContract.amendmentList')}
          </Typography.Title>
          <Skeleton
            active
            title={false}
            loading={isLoading}
            paragraph={{
              rows: 8,
              width: [
                '100%',
                '100%',
                '100%',
                '100%',
                '100%',
                '100%',
                '100%',
                '100%'
              ]
            }}
          >
            <Table
              rowClassName="rowStyle"
              dataSource={contract.amendments}
              loading={isLoading}
              pagination={pagination}
              onRow={({ _id }) => ({
                onClick: () => history.push(`/contracts/show/${_id}`)
              })}
              onChange={(page) => {
                setPagination(page);
              }}
              rowKey="_id"
            >
              <Column
                title={t('contracts.list.column.amendmentNumber')}
                dataIndex="amendment_number"
                sorter={(a, b) => a.amendment_number - b.amendment_number}
                sortDirections={['ascend', 'descend']}
              />
              <Column
                title={t('contracts.list.column.type')}
                dataIndex="type"
                sorter={(a, b) => a.type.localeCompare(b.type)}
                sortDirections={['ascend', 'descend']}
                render={(type) => (
                  <Tag color={contractTypeColor[type]}>
                    {t(`contracts.form.options.type.${type}`).toUpperCase()}
                  </Tag>
                )}
              />
              <Column
                title={t('contracts.list.column.dates')}
                key="dates"
                sorter={(a, b) =>
                  moment(a.start_date).unix() - moment(b.start_date).unix()
                }
                sortDirections={['ascend', 'descend']}
                render={({ start_date, end_date }) => (
                  <Typography>
                    {`${moment(start_date).format('LL') || '-'} - ${moment(
                      end_date
                    ).format('LL') || '-'}`}
                  </Typography>
                )}
              />
            </Table>
          </Skeleton>
        </ContentCustom>
      )}
      <RelatedItems
        history={history}
        contractId={id}
        invoices={invoices}
        parties={parties}
      />
    </>
  );
};

export default ShowContract;
