import React from 'react';
import { Icon, Spin } from 'antd';
import { LanguageContextProvider } from '../contexts/LanguageContext';
import { AuthContextProvider } from '../contexts/AuthContext';
import { ThemeContextProvider } from '../contexts/ThemeContext';
import { TicketContextProvider } from '../contexts/TicketContext';
import Router from '../routes/Router';

Spin.setDefaultIndicator(<Icon type="loading" spin />);

const App = () => (
  <LanguageContextProvider>
    <ThemeContextProvider>
      <AuthContextProvider>
        <TicketContextProvider>
          <Router />
        </TicketContextProvider>
      </AuthContextProvider>
    </ThemeContextProvider>
  </LanguageContextProvider>
);

export default App;
