import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PageHeaderCustom } from '../../components';
import OrderForm from './OrderForm';

const EditAndCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 10 },
      xl: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 12 },
      lg: { span: 14 },
      xl: { span: 6 }
    }
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 10,
        offset: 8
      }
    }
  };

  return (
    <>
      <PageHeaderCustom title={t(`orders.create.orderInfo`)} />
      <OrderForm
        id={id}
        formItemLayout={formItemLayout}
        tailFormItemLayout={tailFormItemLayout}
        history={history}
      />
    </>
  );
};

export default EditAndCreate;
