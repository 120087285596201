import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Typography } from 'antd';
import Indicator from './miniDashs/Indicator';
import ChartDash from './dashs/ChartDash';
import MapDash from './dashs/MapDash';
import GaugePerformance from './miniDashs/GaugePerformance';
import RACITable from './RACITable';
import MiniDashDisplayContainer from './displayContainers/miniDashDisplayContainer';
import DashDisplayContainer from './displayContainers/DashDisplayContainer';
import ITFilters from './ITFilters';

const ITDashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <ITFilters />
      <Divider orientation="center">
        <Typography.Title level={2}>
          {t('dashboard.it.finance')}
        </Typography.Title>
      </Divider>
      <MiniDashDisplayContainer>
        <Indicator
          title={t('home.miniDash.leasing.leasingFigures.title')}
          purpose={{ resource: 'IT', endPoint: 'leasing_key_features' }}
        />
        {/* <Indicator */}
        {/*  title={t('home.miniDash.assignment.renewal_volume.title')} */}
        {/*  purpose={{ resource: 'IT', endPoint: 'renewal_volume' }} */}
        {/* /> */}
        <Indicator
          title={t('home.miniDash.assignment.valorisation_returns.title')}
          purpose={{ resource: 'IT', endPoint: 'valorisation_returns' }}
        />
        <GaugePerformance
          title={t('home.miniDash.leaser.leaserPerformance')}
          purpose={{ resource: 'IT', endPoint: 'leaser_performance' }}
        />
        <Indicator
          title={t('home.miniDash.assignment.investments_amount.title')}
          purpose={{ resource: 'IT', endPoint: 'investments_amount' }}
        />
        <Indicator
          title={t('home.miniDash.assignment.sinister_rate.title')}
          purpose={{ resource: 'IT', endPoint: 'sinister_rate' }}
        />
        <Indicator
          title={t('home.miniDash.assignment.sinisterQuantity.title')}
          purpose={{ resource: 'IT', endPoint: 'sinister_count' }}
        />
        <GaugePerformance
          title={t('home.miniDash.leaser.clientPerformance')}
          purpose={{ resource: 'IT', endPoint: 'client_performance' }}
        />
      </MiniDashDisplayContainer>
      <DashDisplayContainer>
        <ChartDash
          title={t('home.dash.histoJoined.title.sinister_cost_and_profit')}
          purpose={{ resource: 'IT', endPoint: 'sinister_cost_and_profit' }}
        />
        <ChartDash
          title={t('home.dash.histoJoined.title.contract_cost_evolution')}
          purpose={{ resource: 'IT', endPoint: 'contract_cost_evolution' }}
        />
        <MapDash
          title={t('home.dash.map.title.sinistrality')}
          purpose={{ resource: 'IT', endPoint: 'sinister_map' }}
        />
        {/* <ChartDash */}
        {/*  title={t('home.dash.linear.title.incidents')} */}
        {/*  purpose={{ endPoint: 'sinisterCost' }} */}
        {/*  actionPayload={{ data: sinister_cost, timeScale, t }} */}
        {/* /> */}
        {/* <MapDash title={t('home.dash.map.title.usersLocation')} /> */}
        {/* <ChartDash */}
        {/*  title={t('home.dash.histoJoined.title.leasingExit')} */}
        {/*  purpose={{ endPoint: 'assetsLeased' }} */}
        {/*  actionPayload={{ data: {}, timeScale }} */}
        {/* /> */}
        {/* <ChartDash */}
        {/*  title={t('home.dash.fixedHisto.title.assetsQuality')} */}
        {/*  purpose={{ endPoint: 'assetsQuality' }} */}
        {/*  actionPayload={{ data: itData.qualityDataFixedHisto }} */}
        {/* /> */}
      </DashDisplayContainer>
      <Divider orientation="center" style={{ marginTop: 48 }}>
        <Typography.Title level={2}>
          {t('dashboard.it.operations')}
        </Typography.Title>
      </Divider>
      <MiniDashDisplayContainer>
        <Indicator
          title={t('home.miniDash.assignment.total_assets_and_people.title')}
          purpose={{ resource: 'IT', endPoint: 'total_assets_and_people' }}
        />
        <Indicator
          title={t('home.miniDash.assignment.average_lifespan.title')}
          purpose={{ resource: 'IT', endPoint: 'average_lifespan' }}
        />
        <Indicator
          title={t('home.miniDash.assignment.assets_stock.title')}
          purpose={{ resource: 'IT', endPoint: 'assets_stock' }}
        />
        <Indicator
          title={t('home.miniDash.assignment.affectation.title')}
          purpose={{ resource: 'IT', endPoint: 'affectation' }}
        />
      </MiniDashDisplayContainer>
      <DashDisplayContainer>
        <ChartDash
          title={t('home.dash.histoJoined.title.leasing')}
          purpose={{ resource: 'IT', endPoint: 'assets_by_category' }}
        />
        <ChartDash
          title={t('home.dash.histoJoined.title.leasingEntry')}
          purpose={{ resource: 'IT', endPoint: 'stock_in_evolution' }}
        />
        <ChartDash
          title={t('home.dash.histoJoined.title.assets_seniority')}
          purpose={{ resource: 'IT', endPoint: 'assets_seniority' }}
        />
      </DashDisplayContainer>
      <Divider orientation="center" style={{ marginTop: 48 }}>
        <Typography.Title level={2}>
          {t('dashboard.it.performance')}
        </Typography.Title>
      </Divider>
      <MiniDashDisplayContainer>
        <Indicator
          title={t(
            'home.miniDash.assignment.constructor_production_duration.title'
          )}
          purpose={{
            resource: 'IT',
            endPoint: 'constructor_production_duration'
          }}
        />
        <Indicator
          title={t(
            'home.miniDash.assignment.volume_asset_to_be_returned.title'
          )}
          purpose={{
            resource: 'IT',
            endPoint: 'volume_asset_to_be_returned'
          }}
        />
        <Indicator
          title={t('home.miniDash.assignment.volume_asset_returned.title')}
          purpose={{
            resource: 'IT',
            endPoint: 'volume_asset_returned'
          }}
        />
      </MiniDashDisplayContainer>
      <DashDisplayContainer>
        <ChartDash
          title={t('home.dash.histoJoined.title.resale_plan')}
          purpose={{
            resource: 'IT',
            endPoint: 'resale_plan'
          }}
        />
        <MapDash
          title={t('home.dash.map.title.stock_location')}
          purpose={{
            resource: 'IT',
            endPoint: 'stock_repartition'
          }}
        />
        {/* <ChartDash */}
        {/*  title={t( */}
        {/*    'home.dash.histoJoined.title.stock_out_quality_and_quantity' */}
        {/*  )} */}
        {/*  purpose={{ */}
        {/*    resource: 'IT', */}
        {/*    endPoint: 'stock_out_quality_and_quantity' */}
        {/*  }} */}
        {/* /> */}
      </DashDisplayContainer>
      <Row style={{ marginTop: '50px' }}>
        <RACITable data={[]} />
      </Row>
    </>
  );
};

export default ITDashboard;
