import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Typography,
  Row,
  Col,
  Statistic,
  Spin,
  Progress,
  Icon,
  Empty
} from 'antd';
import { red, green, yellow } from '@ant-design/colors';

const IndicatorBody = ({ indicatorElements, isLoading, config, endPoint }) => {
  const {
    invertedProgress,
    percent,
    number,
    color,
    suffix,
    label,
    number1,
    number2,
    label1,
    label2,
    trend1,
    trend1Colored,
    trend2,
    trend2Colored,
    suffix1,
    suffix2,
    color1,
    color2,
    invertedTrendColor
  } = indicatorElements;

  let strokeColor;
  if (invertedProgress) {
    strokeColor = red.primary;
    if (percent < 50) strokeColor = yellow.primary;
    if (percent < 20) strokeColor = green.primary;
  } else {
    strokeColor = green.primary;
    if (percent < 66) strokeColor = yellow.primary;
    if (percent < 33) strokeColor = red.primary;
  }

  const generateTrendArrow = (trend, withColor = true) => {
    const fontSize = 14;
    let trendColor;
    if (withColor) {
      if (endPoint === 'total_assets_and_people') {
        if (trend > 0) trendColor = red.primary;
        if (trend < 0) trendColor = green.primary;
      } else {
        if (trend > 0)
          trendColor = invertedTrendColor ? red.primary : green.primary;
        if (trend < 0)
          trendColor = invertedTrendColor ? green.primary : red.primary;
      }
    }
    if (trend > 0)
      return (
        <>
          <Typography.Text
            style={{
              color: trendColor,
              fontSize
            }}
          >
            {`+${numeral(trend).format('0%')} `}
          </Typography.Text>
          <Icon type="rise" style={{ color: trendColor }} />
        </>
      );
    if (trend < 0)
      return (
        <>
          <Typography.Text
            style={{
              color: trendColor,
              fontSize
            }}
          >
            {`${numeral(trend).format('0%')} `}
          </Typography.Text>
          <Icon type="fall" style={{ color: trendColor }} />
        </>
      );
    return '';
  };

  return (
    <Row style={{ marginTop: '20px' }}>
      <Spin spinning={isLoading}>
        {percent ? (
          <div>
            {percent && (number || number === 0) ? (
              <Row>
                <Col span={12}>
                  <Progress
                    width={65}
                    type="dashboard"
                    percent={percent}
                    strokeColor={strokeColor}
                    format={(p) => {
                      if (p < 100) return `${p.toFixed(1)}%`;
                      return <Icon type="check" />;
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title={label}
                    value={number}
                    valueStyle={{ color }}
                    prefix={trend2 && generateTrendArrow(trend2, trend2Colored)}
                    suffix={suffix}
                  />
                </Col>
              </Row>
            ) : (
              <Empty imageStyle={{ height: 50 }} />
            )}
          </div>
        ) : (
          <div>
            {number1 || number2 ? (
              <Row>
                <Col span={label2 || suffix2 ? 12 : 24}>
                  <Statistic
                    title={label1}
                    value={number1}
                    valueStyle={{
                      color: color1
                    }}
                    prefix={
                      trend1 ? generateTrendArrow(trend1, trend1Colored) : ''
                    }
                    suffix={suffix1}
                  />
                </Col>
                <Col span={label2 || suffix2 ? 12 : 0}>
                  <Statistic
                    title={label2}
                    value={config ? config.number2 / 1000 : number2}
                    valueStyle={{ color: color2 }}
                    prefix={
                      trend2 ? generateTrendArrow(trend2, trend2Colored) : ''
                    }
                    suffix={suffix2}
                  />
                </Col>
              </Row>
            ) : (
              <Empty imageStyle={{ height: 35 }} />
            )}
          </div>
        )}
      </Spin>
    </Row>
  );
};

IndicatorBody.propTypes = {
  endPoint: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    number2: PropTypes.number
  }).isRequired,
  indicatorElements: PropTypes.shape({
    number: PropTypes.number,
    number1: PropTypes.number,
    number2: PropTypes.number,
    label: PropTypes.string,
    label1: PropTypes.string,
    label2: PropTypes.string,
    color: PropTypes.string,
    color1: PropTypes.string,
    color2: PropTypes.string,
    percent: PropTypes.number.isRequired,
    invertedProgress: PropTypes.bool.isRequired,
    invertedTrendColor: PropTypes.bool.isRequired,
    suffix: PropTypes.string.isRequired,
    suffix1: PropTypes.string.isRequired,
    suffix2: PropTypes.string.isRequired,
    trend1: PropTypes.number.isRequired,
    trend2: PropTypes.number.isRequired,
    trend1Colored: PropTypes.bool.isRequired,
    trend2Colored: PropTypes.bool.isRequired
  }).isRequired
};

export default IndicatorBody;
