import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DatePickerFooter = ({
  dateField,
  handleDateFieldChange,
  disabledPaymentDateRange,
  setDisabledPayment
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Radio.Group
        buttonStyle="solid"
        size="small"
        value={dateField}
        onChange={(e) => handleDateFieldChange(e.target.value)}
      >
        <Radio.Button
          value="emission_date"
          defaultChecked
          onChange={() => setDisabledPayment(false)}
        >
          {t(`header.menu.filteredTab.emissionDateInvoices`)}
        </Radio.Button>
        <Radio.Button
          value="payment_date"
          disabled={disabledPaymentDateRange}
          onChange={() => setDisabledPayment(true)}
        >
          {t(`header.menu.filteredTab.paymentDateInvoices`)}
        </Radio.Button>
        <Radio.Button
          value="order_date"
          onChange={() => setDisabledPayment(false)}
        >
          {t(`header.menu.filteredTab.orderDateInvoices`)}
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

DatePickerFooter.propTypes = {
  dateField: PropTypes.string.isRequired,
  handleDateFieldChange: PropTypes.func.isRequired,
  setDisabledPayment: PropTypes.func.isRequired,
  disabledPaymentDateRange: PropTypes.func.isRequired
};

export default DatePickerFooter;
