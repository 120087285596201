/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../contexts/AuthContext';
import AssetsPart from './AssetsPart';
import BaseTicketForm from './BaseTicketForm';
import {
  defaultPagination,
  defaultFilteredInfo,
  getAssetsUtil,
  setSite,
  setPeople,
  submitUtil,
  getCompanyPeopleUtil,
  getTicketsEnumsUtil,
  getAssetsEnumsUtil
} from './utils';

const TicketForm = ({
  formItemLayout,
  tailFormItemLayout,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldValue,
    setFieldsValue
  },
  history
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isSitesLoading, setIsSitesLoading] = useState(false);
  const [isPeopleLoading, setIsPeopleLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [ticketsEnumValues, setTicketsEnums] = useState(null);
  const [assetsEnumValues, setAssetsEnums] = useState([]);
  const [companySites, setCompanySites] = useState([]);
  const [companyPeople, setCompanyPeople] = useState([]);
  const [allAssets, setAllAssets] = useState([]);
  const siteId = getFieldValue('siteId');
  const peopleId = getFieldValue('peopleId');
  const ticketType = getFieldValue('type');
  const siteName = getFieldValue('site.name');
  const [pagination, setPagination] = useState(defaultPagination);
  const [filteredInfo, setFilteredInfo] = useState(defaultFilteredInfo);

  const getTicketsEnums = () => {
    getTicketsEnumsUtil(dispatchAPI, setTicketsEnums);
  };

  const getAssetsEnums = () => {
    getAssetsEnumsUtil(dispatchAPI, setAssetsEnums);
  };

  const getCompanySites = async (searchValue) => {
    setIsSitesLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${user.company}/site${
          searchValue ? `/search/${searchValue}` : ''
        }?limit=5`
      });
      setCompanySites(data);
    } catch (e) {
      console.error(e);
    }
    setIsSitesLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getCompanyPeople = (searchValue) => {
    getCompanyPeopleUtil(
      searchValue,
      setIsPeopleLoading,
      dispatchAPI,
      user,
      siteName,
      setCompanyPeople
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getAssets = (page = pagination, filters = filteredInfo, selectMany) => {
    getAssetsUtil(
      page,
      filters,
      selectMany,
      dispatchAPI,
      setAssets,
      setAllAssets,
      setPagination
    );
    setIsTableLoading(false);
  };

  const handleChange = async (page, filters) => {
    setPagination({ ...pagination, ...page });
    setIsTableLoading(true);
    setFilteredInfo(filters);
    await getAssets(page, filters);
  };

  useEffect(() => {
    (async () => {
      await getAssets();
      await getTicketsEnums();
      await getAssetsEnums();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    submitUtil(
      assets,
      validateFieldsAndScroll,
      setIsLoading,
      dispatchAPI,
      user,
      t,
      history
    );
  };

  const selectAssets = (keys) => {
    setAssets(keys);
  };

  const selectAllAssets = async (selected) => {
    if (selected) {
      setIsTableLoading(true);
      await getAssets(pagination, filteredInfo, selected);
    } else {
      setAssets([]);
    }
  };

  const getSiteAddress = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${user.company}/site`
      });
      return data.filter((s) => s._id === id);
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    if (siteId) {
      setIsSitesLoading(true);
      (async () => {
        const site = (await getSiteAddress(siteId))[0];
        setFieldsValue(setSite(site));
      })();
      setIsSitesLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  const getPeopleDetails = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/people/${id}`
      });
      return data;
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    if (peopleId) {
      setIsPeopleLoading(true);
      (async () => {
        const people = await getPeopleDetails(peopleId);
        setFieldsValue(setPeople(people));
      })();
      setIsPeopleLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleId]);

  const handleSearch = (selectedKeys, confirm) => {
    setFilteredInfo({ ...filteredInfo, serial_number: selectedKeys[0] });
    confirm();
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <BaseTicketForm
        ticketType={ticketType}
        ticketsEnumValues={ticketsEnumValues}
        getFieldDecorator={getFieldDecorator}
        isSitesLoading={isSitesLoading}
        getCompanySites={getCompanySites}
        companySites={companySites}
        siteId={siteId}
        isPeopleLoading={isPeopleLoading}
        getCompanyPeople={getCompanyPeople}
        companyPeople={companyPeople}
        peopleId={peopleId}
        setFieldsValue={setFieldsValue}
      />
      <AssetsPart
        tailFormItemLayout={tailFormItemLayout}
        handleChange={handleChange}
        handleSearch={handleSearch}
        allAssets={allAssets}
        isTableLoading={isTableLoading}
        pagination={pagination}
        assets={assets}
        selectAllAssets={selectAllAssets}
        assetsEnumValues={assetsEnumValues}
        filteredInfo={filteredInfo}
        isLoading={isLoading}
        selectAssets={selectAssets}
      />
    </Form>
  );
};

TicketForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func
  }).isRequired,
  onSuccess: PropTypes.func,
  formItemLayout: PropTypes.shape({}).isRequired,
  tailFormItemLayout: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func
  }).isRequired
};

TicketForm.defaultProps = {
  onSuccess: null
};

export default Form.create({ name: 'ticket_form' })(TicketForm);
