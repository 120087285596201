import React from 'react';
import { Menu, Icon, Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { SubMenu } = Menu;
const BasicLayoutMenu = ({
  user,
  path,
  setCollapsed,
  size,
  assetsSubMenu,
  mapAssetsSubMenu
}) => {
  const { t } = useTranslation();

  return (
    <Menu
      className="menu-sider"
      theme="dark"
      selectedKeys={[path]}
      defaultOpenKeys={[path.split('/')[1]]}
      mode="inline"
      onSelect={size.width < 992 ? () => setCollapsed(true) : () => {}}
    >
      <Menu.Item key="/">
        <NavLink to="/" className="nav-text">
          <Icon type="dashboard" />
          <span>{t('dashboard.title')}</span>
        </NavLink>
      </Menu.Item>
      {user.role === 'SUPER-USER' && (
        <Menu.Item key="/users">
          <NavLink to="/users" className="nav-text">
            <Icon type="user" />
            <span>{t('users.list.title')}</span>
          </NavLink>
        </Menu.Item>
      )}
      {user.role === 'SUPER-USER' && (
        <SubMenu
          key="companies"
          title={
            <span>
              <Icon type="shop" />
              <span>{t('companies.list.title')}</span>
            </span>
          }
        >
          <Menu.Item key="/leaser">
            <NavLink to="/leaser" className="nav-text">
              <span>{t('LEASER.list.title')}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/supplier">
            <NavLink to="/supplier" className="nav-text">
              <span>{t('SUPPLIER.list.title')}</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      )}
      {user.role === 'SUPER-USER' && (
        <Menu.Item key="/people">
          <NavLink to="/people" className="nav-text">
            <Icon type="contacts" />
            <span>{t('people.title')}</span>
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Item key="/contracts">
        <NavLink to="/contracts" className="nav-text">
          <Icon type="project" />
          <span>{t('contracts.list.title')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/invoices">
        <NavLink to="/invoices" className="nav-text">
          <Icon type="euro" />
          <span>{t('invoices.list.title')}</span>
        </NavLink>
      </Menu.Item>
      <SubMenu
        key="assets"
        title={
          <span>
            <Icon type="desktop" />
            <span>Assets</span>
            {!assetsSubMenu && <Spin style={{ marginLeft: '20px' }} />}
          </span>
        }
        disabled={!assetsSubMenu}
      >
        {mapAssetsSubMenu(assetsSubMenu)}
      </SubMenu>
      <Menu.Item key="/tickets">
        <NavLink to="/tickets" className="nav-text">
          <Icon type="file-protect" />
          <span>{t('tickets.list.title')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/orders">
        <NavLink to="/orders" className="nav-text">
          <Icon type="file-unknown" />
          <span>{t('orders.list.title')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/eProcurement" disabled>
        <NavLink to="/eProcurement" className="nav-text">
          <Icon type="shopping-cart" />
          <span>E-procurement</span>
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

BasicLayoutMenu.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string
  }).isRequired,
  size: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  setCollapsed: PropTypes.func.isRequired,
  assetsSubMenu: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  mapAssetsSubMenu: PropTypes.node.isRequired
};

export default BasicLayoutMenu;
