import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import './styles.css';
import img404 from '../../assets/images/404.svg';

const Exception = () => {
  return (
    <>
      <div className="exception">
        <div className="imgBlock">
          <div
            className="imgEle"
            style={{
              backgroundImage: `url(${img404})`
            }}
          />
        </div>
        <div>
          <h1>404</h1>
          <div className="desc">La page demandée est introuvable</div>
          <div className="actions">
            <Link to="/">
              <Button type="primary">Retour à l'accueil</Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exception;
