import React from 'react';
import { Button } from 'antd';
import SFRLogo from '../../assets/images/SFR_logo.png';
import OrangeLogo from '../../assets/images/Orange_logo.png';
import BouyguesLogo from '../../assets/images/Bouygues_logo.png';
import useTelecomContext from './context/DashboardContext';

const providerLogos = {
  SFR: SFRLogo,
  ORANGE: OrangeLogo,
  BOUYGUES: BouyguesLogo
};

const ProviderSelect = () => {
  const {
    filters: { provider },
    dispatchFilters
  } = useTelecomContext();

  return (
    <div style={{ marginBottom: 8 }}>
      {Object.entries(providerLogos).map(([p, logo]) => (
        <Button
          key={p}
          type="link"
          style={{
            padding: 0
          }}
          onClick={() =>
            dispatchFilters({ type: 'add', payload: { provider: p } })
          }
        >
          <img
            src={logo}
            alt="provider logo"
            style={{
              height: 40,
              margin: '0 8px',
              opacity: provider && provider !== p ? 0.5 : 1,
              boxShadow: provider && provider === p ? '0 0 10px' : ''
            }}
          />
        </Button>
      ))}
    </div>
  );
};

export default ProviderSelect;
