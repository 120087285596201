import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TicketForm from './TicketForm';
import { PageHeaderCustom } from '../../components';

const EditAndCreate = ({ history }) => {
  const { t } = useTranslation();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 10 },
      xl: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 12 },
      lg: { span: 14 },
      xl: { span: 6 }
    }
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 10,
        offset: 8
      }
    }
  };

  return (
    <>
      <PageHeaderCustom title={t(t(`tickets.create.ticketInfo`))} />
      <TicketForm
        formItemLayout={formItemLayout}
        tailFormItemLayout={tailFormItemLayout}
        history={history}
      />
    </>
  );
};

EditAndCreate.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

export default EditAndCreate;
