import React from 'react';
import { Icon, Radio, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ExtraOptions = ({
  filter,
  handleFilterChange,
  reset,
  setDisabledIncidentDateRange
}) => {
  const { t } = useTranslation();
  return (
    <Row style={{ marginTop: 8 }}>
      <Row>
        <Text strong style={{ marginBottom: 10, marginLeft: 8 }}>
          {t(`header.menu.filteredTab.invoicesFilter`)}
        </Text>
        <Icon style={{ marginLeft: 4 }} type="undo" onClick={reset} />
      </Row>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.open}
        onChange={(e) => handleFilterChange('open', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&status=OPEN">
          {t(`header.menu.filteredTab.openedTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.waiting}
        onChange={(e) => handleFilterChange('waiting', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&status=WAITING">
          {t(`header.menu.filteredTab.waitingTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.progress}
        onChange={(e) => handleFilterChange('progress', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&status=IN_PROGRESS">
          {t(`header.menu.filteredTab.inprogressTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.audited}
        onChange={(e) => handleFilterChange('audited', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&status=AUDITED">
          {t(`header.menu.filteredTab.auditedTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.refused}
        onChange={(e) => handleFilterChange('refused', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&status=REFUSED_BY_INSURER">
          {t(`header.menu.filteredTab.refusedTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.closed}
        onChange={(e) => handleFilterChange('closed', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&status=CLOSED">
          {t(`header.menu.filteredTab.closedTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.deleted}
        onChange={(e) => handleFilterChange('deleted', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&status=DELETED">
          {t(`header.menu.filteredTab.deletedTickets`)}
        </Radio.Button>
      </Radio.Group>
      <br />
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.collection}
        onChange={(e) => handleFilterChange('collection', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button
          value="&type=COLLECTION"
          onChange={() => setDisabledIncidentDateRange(true)}
        >
          {t(`header.menu.filteredTab.collectionTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.theft}
        onChange={(e) => handleFilterChange('theft', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button
          value="&type=THEFT"
          onChange={() => setDisabledIncidentDateRange(false)}
        >
          {t(`header.menu.filteredTab.theftTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.lost}
        onChange={(e) => handleFilterChange('lost', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button
          value="&type=LOST"
          onChange={() => setDisabledIncidentDateRange(false)}
        >
          {t(`header.menu.filteredTab.lostTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.damage}
        onChange={(e) => handleFilterChange('damage', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button
          value="&type=DAMAGE"
          onChange={() => setDisabledIncidentDateRange(false)}
        >
          {t(`header.menu.filteredTab.damageTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.delivery}
        onChange={(e) => handleFilterChange('delivery', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button
          value="&type=DELIVERY"
          onChange={() => setDisabledIncidentDateRange(true)}
        >
          {t(`header.menu.filteredTab.deliveryTickets`)}
        </Radio.Button>
      </Radio.Group>
      <br />
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.normal}
        onChange={(e) => handleFilterChange('normal', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&priority=NORMAL">
          {t(`header.menu.filteredTab.normalTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.high}
        onChange={(e) => handleFilterChange('high', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&priority=HIGH">
          {t(`header.menu.filteredTab.highTickets`)}
        </Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{ margin: 8 }}
        value={filter.urgent}
        onChange={(e) => handleFilterChange('urgent', e.target.value)}
        buttonStyle="solid"
      >
        <Radio.Button value="&priority=URGENT">
          {t(`header.menu.filteredTab.urgentTickets`)}
        </Radio.Button>
      </Radio.Group>
    </Row>
  );
};

ExtraOptions.propTypes = {
  filter: PropTypes.shape({
    collection: PropTypes.string,
    theft: PropTypes.string,
    lost: PropTypes.string,
    damage: PropTypes.string,
    delivery: PropTypes.string,
    open: PropTypes.string,
    waiting: PropTypes.string,
    progress: PropTypes.string,
    audited: PropTypes.string,
    refused: PropTypes.string,
    closed: PropTypes.string,
    deleted: PropTypes.string,
    normal: PropTypes.string,
    high: PropTypes.string,
    urgent: PropTypes.string
  }),
  setDisabledIncidentDateRange: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

ExtraOptions.defaultProps = {
  filter: null
};

export default ExtraOptions;
