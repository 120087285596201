import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty, Icon } from 'antd';
import {
  Axis,
  Chart,
  Coord,
  Geom,
  Guide,
  Label,
  Legend,
  Tooltip
} from 'bizcharts';
import DataSet from '@antv/data-set';

const PieChart = ({ chartsElements, iconType }) => {
  const { t } = useTranslation();

  const {
    data,
    type,
    field,
    fields,
    dimension,
    as,
    groupBy,
    key,
    value,
    cols,
    customisedPadding,
    xAxis,
    color,
    position,
    customTooltip,
    labelFormatter,
    radius,
    innerRadius,
    withLegend,
    itemFormatter,
    withGuideText
  } = chartsElements;

  const ds = new DataSet();
  const dv = ds.createView().source(data || []);
  dv.transform({
    type,
    field,
    dimension,
    as,
    groupBy,
    fields,
    key,
    value
  });

  return (
    <Chart
      height={450}
      data={dv}
      forceFit
      padding={customisedPadding || [20, 60, 85, 85]}
      scale={cols}
      placeholder={
        <Empty
          style={{ height: '100%' }}
          description={t('home.nodata')}
          imageStyle={{ height: '90%' }}
          image={
            <Icon style={{ fontSize: 350, color: '#E0E0E0' }} type={iconType} />
          }
        />
      }
    >
      <Coord type="theta" radius={radius || 1} innerRadius={innerRadius || 0} />
      <Axis name={xAxis} />
      {withLegend && (
        <Legend
          position={withLegend.position || 'bottom'}
          itemFormatter={itemFormatter}
          offsetX={withLegend.offsetX}
          offsetY={withLegend.offsetY}
        />
      )}
      <Tooltip
        showTitle={false}
        itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      />
      <Geom
        opacity={1}
        type="intervalStack"
        style={{ lineWidth: 1, stroke: '#fff' }}
        color={color}
        position={position}
        tooltip={customTooltip}
      >
        <Label content={position} formatter={labelFormatter} />
      </Geom>
      {withGuideText && (
        <Guide>
          <Guide.Text
            position={['50%', '50%']}
            content={withGuideText.text}
            style={{
              lineHeight: '240px',
              fontSize: '34',
              textAlign: 'center'
            }}
          />
        </Guide>
      )}
    </Chart>
  );
};

PieChart.propTypes = {
  dv: PropTypes.shape({}),
  chartsElements: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.string,
    field: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string),
    dimension: PropTypes.string,
    as: PropTypes.string,
    groupBy: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.string,
    cols: PropTypes.shape({}),
    customisedPadding: PropTypes.arrayOf(PropTypes.number),
    withArea: PropTypes.bool,
    xAxis: PropTypes.string,
    yAxis: PropTypes.string,
    color: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
    ]),
    position: PropTypes.string,
    customTooltip: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    ),
    titleOffset: PropTypes.number,
    legendPosition: PropTypes.string,
    legendOffsetX: PropTypes.number,
    legendOffsetY: PropTypes.number,
    labelFormatter: PropTypes.func,
    radius: PropTypes.number,
    innerRadius: PropTypes.number,
    itemFormatter: PropTypes.func,
    withLegend: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
    withGuideText: PropTypes.shape({
      text: PropTypes.string
    })
  }),
  iconType: PropTypes.string.isRequired
};

PieChart.defaultProps = {
  dv: null,
  chartsElements: {}
};

export default PieChart;
