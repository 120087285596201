import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Table, Typography, Spin, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { CSVLink } from 'react-csv';
import LazyLoad from 'react-lazyload';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import { headersTop50, headersPhoneContract } from '../exportHeaders';
import useLanguageContext from '../../../contexts/LanguageContext';
import useTelecomContext from '../context/DashboardContext';
import { providerColors } from '../../../utils';

const { Title } = Typography;

const TableDash = ({ title, scroll, purpose, fixedProvider }) => {
  const { dispatchAPI } = useAuthContext();
  const {
    timeScale,
    filters: { provider, device, subsidiaries }
  } = useTelecomContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { resource, endPoint } = purpose || {};
  const [tableElements, setTableElements] = useState({});

  const goToPhoneNumber = async (phoneNumber) => {
    const { data } = await dispatchAPI('GET', {
      url: `/assets/Telecom?phone_Number=${phoneNumber}`
    });
    const { _id } = data[0];
    history.push(`/assets/Telecom/show/${_id}`);
  };

  let CancelToken;
  let cancel;

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    CancelToken = axios.CancelToken;

    let providerUrl;
    if (fixedProvider) providerUrl = `&provider=${fixedProvider}`;
    else if (provider) providerUrl = `&provider=${provider}`;

    let deviceUrl;
    if (device.length) deviceUrl = `&devices=${device}`;

    let subsidiariesUrl;
    if (subsidiaries.length) subsidiariesUrl = `&subsidiaries=${subsidiaries}`;

    try {
      const results = await dispatchAPI('GET', {
        url: `/dashboards/${resource}/${endPoint}?start_date=${
          timeScale[0]
        }&end_date=${timeScale[1]}&limit=50${providerUrl ||
          ''}${subsidiariesUrl || ''}${deviceUrl || ''}`,
        cancelToken: new CancelToken((c) => {
          cancel = c;
        })
      });
      switch (endPoint) {
        case 'top_overage':
          const result = [];
          results.data &&
            results.data.map(({ date, ...data }) => {
              result.push({
                ...data,
                date: date ? moment(date).format('L') : ''
              });
            });
          setTableElements({
            data: results.data,
            columns: [
              {
                title: t('home.miniDash.table.rankOverrun.number'),
                dataIndex: 'key',
                render: (v) => `0${v}`
              },
              {
                title: t('home.miniDash.table.rankOverrun.cost'),
                dataIndex: 'overage',
                align: 'right',
                render: (amount) => numeral(amount).format('0,0.00')
              }
            ],
            onRowFunction: ({ key }) => ({
              onClick: () => goToPhoneNumber(key)
            }),
            rowClassName: 'rowStyle',
            titleExtra: (
              <CSVLink
                style={{ float: 'right', marginRight: 8 }}
                filename="Top 50.csv"
                data={result}
                headers={headersTop50.map(({ label, ...header }) => ({
                  label: t(`top50.${label}`),
                  ...header
                }))}
              >
                <p style={{ transform: 'rotate(90deg) ' }}>&#8677;</p>
              </CSVLink>
            )
          });
          break;
        case 'cost_by_contract':
          setTableElements({
            data: results.data,
            columns: [
              {
                title: t('home.miniDash.table.costByPhoneNumber.phoneContract'),
                dataIndex: 'key'
              },
              {
                title: t('home.miniDash.table.costByPhoneNumber.averageCost'),
                dataIndex: 'average',
                align: 'right',
                render: (amount) => numeral(amount).format('0,0.00')
              }
            ],
            titleExtra: (
              <CSVLink
                style={{ float: 'right', marginRight: 8 }}
                filename={`Forfaits ${provider}.csv`}
                data={results.data.map(({ average, ...d }) => ({
                  ...d,
                  average: average.toFixed(2)
                }))}
                headers={headersPhoneContract.map(({ label, ...header }) => ({
                  label: t(`phoneContracts.${label}`),
                  ...header
                }))}
              >
                <p style={{ transform: 'rotate(90deg) ' }}>&#8677;</p>
              </CSVLink>
            )
          });
          break;
        default:
          break;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      if (axios.isCancel(e)) {
        return {};
      }
    }
    setIsLoading(false);
    return {};
  }, [endPoint, timeScale, provider, device, subsidiaries, locale]);

  useEffect(() => {
    if (resource && endPoint) {
      fetchData();
    }
    return () => {
      cancel();
    };
  }, [fetchData]);

  const extraButton = () => {
    const { titleExtra } = tableElements;
    if (titleExtra) return titleExtra;
    return null;
  };

  return (
    <>
      {fixedProvider && (
        <Tag
          style={{ position: 'absolute', top: 5, left: 5 }}
          color={providerColors[fixedProvider]}
        >
          {fixedProvider}
        </Tag>
      )}
      <Title style={{ textAlign: 'center' }} level={4}>
        {`${title}`}
        {extraButton()}
      </Title>
      <LazyLoad
        debounce={500}
        height={100}
        placeholder={
          <Spin size="large" spinning>
            <div style={{ height: 300, width: '100%' }} />
          </Spin>
        }
      >
        <Table
          loading={isLoading}
          rowClassName={tableElements.rowClassName}
          style={{ margin: '0 8px' }}
          scroll={scroll}
          onRow={tableElements.onRowFunction}
          size="small"
          pagination={false}
          columns={tableElements.columns}
          dataSource={(tableElements.data || []).map(
            ({ _id, ...phoneNumber }) => ({
              ...phoneNumber,
              key: _id
            })
          )}
        />
      </LazyLoad>
    </>
  );
};

TableDash.propTypes = {
  title: PropTypes.string.isRequired,
  scroll: PropTypes.shape({
    y: PropTypes.number
  }),
  purpose: PropTypes.shape({
    resource: PropTypes.string,
    endPoint: PropTypes.string
  }),
  fixedProvider: PropTypes.string
};

TableDash.defaultProps = {
  scroll: {},
  purpose: null,
  fixedProvider: null
};

export default TableDash;
