import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Col, message, Row, Select } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { ResourceLandingLayout } from '../../layouts';
import useAuthContext from '../../contexts/AuthContext';
import { headers } from './headers';
import { extraQueryUtil, columnsUtil } from './utils';
import Filters from './Filters';

const { Option } = Select;

const ListTelecomInvoices = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const { dispatchAPI } = useAuthContext();
  const [subLoading, setSubLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [range, setRange] = useState([
    moment()
      .subtract(1, 'month')
      .startOf('month'),
    moment().endOf('month')
  ]);
  const [rate, setRate] = useState(0.1);
  const [provider, setProvider] = useState('all');
  const [selectedSub, setSelectedSub] = useState([]);
  const [absoluteOverage, setAbsoluteOverage] = useState(0);

  const extraQuery = extraQueryUtil(
    rate,
    absoluteOverage,
    provider,
    selectedSub,
    keyword
  );
  const handleSelect = (id) => {
    if (selectedKeys.includes(id)) {
      setSelectedKeys((prev) => prev.filter((el) => el !== id));
    } else {
      setSelectedKeys((prev) => [...prev, id]);
    }
  };

  useEffect(() => {
    setSelectedKeys([]);
  }, [allSelected]);

  const handleSendMail = async (download = false) => {
    setIsLoading(true);
    try {
      const response = await dispatchAPI('POST', {
        url: `/telecom-invoices/${
          download ? 'download-reports' : 'reports'
        }?filter=${JSON.stringify(extraQuery)}${
          range.length ? `&date>=${range[0]}&date<=${range[1]}` : ''
        }`,
        body: {
          keys: selectedKeys.length ? selectedKeys : 'all'
        },
        ...(download ? { responseType: 'arraybuffer' } : {})
      });
      if (download) {
        const bfArray = new Uint8Array(response.data.data.data);
        const blob = new Blob([bfArray]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'rapports.zip';
        a.click();
        message.success('Fichier(s) téléchargé(s) avec succès');
      } else {
        message.success('Rapport(s) envoyé(s)');
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsLoading(false);
  };

  const handleRangeChange = (value) => {
    if (value.length) {
      setRange([value[0].startOf('day'), value[1].endOf('day')]);
    } else setRange([]);
  };

  const getSubsidiaries = async () => {
    setSubLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: 'subsidiaries' });
      setSubsidiaries(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setSubLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getSubsidiaries();
    })();
  }, []);

  const getOverageDuration = (asset) => {
    const { telecomInvoices } = asset;
    telecomInvoices.sort((a, b) => moment(b.date).diff(moment(a.date)));
    if (!telecomInvoices || !telecomInvoices.length) return 1;
    let count = 0;
    for (let i = 0; i < telecomInvoices.length; i += 1) {
      const totalOverage =
        telecomInvoices[i].overage_voice_amount +
        telecomInvoices[i].overage_messages_amount +
        telecomInvoices[i].overage_data_amount;
      if (totalOverage > 0) count += 1;
      else break;
    }
    return count;
  };

  return (
    <ResourceLandingLayout
      customActionColumn
      resourceName="telecom-invoices"
      tradKey="telecomInvoices"
      withCreateButton={false}
      populate="asset.people telecomInvoices,subsidiary"
      headers={headers}
      customAction={{
        onDoubleClick: (record) =>
          history.push({
            pathname: `/assets/Telecom/show/${record.asset._id}`,
            state: { detailedInvoice: record.key }
          })
      }}
      exportUrl={`telecom-invoices?${
        selectedKeys.length
          ? `_id=${selectedKeys}`
          : `filter=${JSON.stringify(extraQuery)}${
              range.length ? `&date>=${range[0]}&date<=${range[1]}` : ''
            }`
      }`}
      extraQuery={`filter=${JSON.stringify(extraQuery)}${
        range.length ? `&date>=${range[0]}&date<=${range[1]}` : ''
      }`}
      columns={columnsUtil(
        allSelected,
        setAllSelected,
        selectedKeys,
        handleSelect,
        t,
        getOverageDuration
      )}
    >
      <Row
        style={{ margin: '16px 0' }}
        type="flex"
        justify="space-between"
        gutter={[8, 8]}
      >
        <Filters
          rate={rate}
          absoluteOverage={absoluteOverage}
          handleRangeChange={handleRangeChange}
          range={range}
          setRate={setRate}
          setAbsoluteOverage={setAbsoluteOverage}
        />
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              type="primary"
              onClick={() => handleSendMail()}
              disabled={!allSelected && !selectedKeys.length}
              loading={isLoading}
              style={{ marginRight: 4 }}
            >
              {t('telecomInvoices.emailReports')}
            </Button>
            <Button
              type="primary"
              onClick={() => handleSendMail(true)}
              disabled={!allSelected && !selectedKeys.length}
              loading={isLoading}
            >
              {t('telecomInvoices.emailReportsDownload')}
            </Button>
          </div>
        </Col>
        <Col span={24}>
          <Select
            style={{ width: 180, marginRight: 8 }}
            value={provider}
            onChange={(value) => setProvider(value)}
          >
            <Option value="all">{t('telecomInvoices.providers.all')}</Option>
            <Option value="BOUYGUES">
              {t('telecomInvoices.providers.BOUYGUES')}
            </Option>
            <Option value="ORANGE">
              {t('telecomInvoices.providers.ORANGE')}
            </Option>
            <Option value="SFR">{t('telecomInvoices.providers.SFR')}</Option>
          </Select>
          <Select
            placeholder={t('telecomInvoices.subsidiariesPlaceHolder')}
            loading={subLoading}
            mode="multiple"
            style={{ width: 250 }}
            value={selectedSub}
            onChange={(value) => setSelectedSub(value)}
          >
            {subsidiaries.map((s) => (
              <Option key={s._id} value={s._id}>
                {s.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </ResourceLandingLayout>
  );
};

export default ListTelecomInvoices;
